
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import React, { Component,useImperativeHandle  } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {AgreementListControl} from '../../AgreementListControl'


class AgreementPanel extends Component {
     state = {
      showPanel: true,
      selectedAgreement:''
    };
    
  
     render() {
  
        const {isSoldtoSelected}=this.props;
      return (
        <div>
          {/* <PrimaryButton
            secondaryText="Opens the Sample Panel"
            onClick={this._showPanel}
            text="Select Agreement"
            disabled={(isSoldtoSelected===undefined || isSoldtoSelected)}
            style={{ marginRight: '8px' }}
          /> */}
          <Panel
            isOpen={this.state.showPanel}
            type={PanelType.large}
            onDismiss={this._hidePanel}
            headerText="Please Select the Agreement"
            closeButtonAriaLabel="Close"
            isFooterAtBottom={true}
            onRenderFooterContent={this._onRenderFooterContent}
            
          >
         
            <AgreementListControl name={this.props.name}
                                    onSelect={(argNum)=>this.onSelected(argNum)}
                                    errorMessage={this.props.errorMessage} />
                       
          </Panel>
        </div>
      );
    }


    
  onSelected = (values) =>{
  
    if(values)
    {
    this.props.onSelect(values);
    this._hidePanel();
    }
  }

     _onRenderFooterContent = () => {
       return(
         <div>

         </div>
       )
  /*     return (
        <div>
          <PrimaryButton onClick={this._hidePanel} style={{ marginRight: '8px' }}>
            Save
          </PrimaryButton>
          <DefaultButton onClick={this._hidePanel}>Cancel</DefaultButton>
        </div>
      ); */
    };
   

     _showPanel() {
      this.setState({ showPanel: true });
    }

  
     _hidePanel = () => {
       this.props.hidepanel();
      //this.setState({ showPanel: false });
    };
  }
  const mapDispatchToProps = (dispatch) => ({
   

})

const mapStateToProps = (state) => {
    return {
      
    }
}

  export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AgreementPanel));