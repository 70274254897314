import { createSelector } from 'reselect';

export const viewProductDetailsSelector = createSelector(
    state => state.app.get("viewProductDetails"),
    viewProductDetails => viewProductDetails ? viewProductDetails.toJS() : null
);

export const viewBigCatPDSelector = createSelector(
    state => state.app.get("viewBigCatPD"),
    viewBigCatPD => viewBigCatPD ? viewBigCatPD.toJS() : null
);

export const viewBigCatChildPDSelector = createSelector(
    state => state.app.get("viewBigCatChildPD"),
    viewBigCatChildPD => viewBigCatChildPD ? viewBigCatChildPD.toJS() : null
);


export const viewLaunchReadinessPDSelector = createSelector(
    state => state.app.get("viewLaunchReadinessPD"),
    viewLaunchReadinessPD => viewLaunchReadinessPD ? viewLaunchReadinessPD.toJS() : null
);
