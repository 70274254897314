export const StoreProductWizardSteps =
    [
        {
            key: "BIG_CAT_DETAILS",
            text: "Big Catalog Details",
            order: 1,
            status: 2,
            inputData: {
            },
            isStateValid: true
        },
        {
            key: "LAUNCH_READINESS_DETAILS",
            text: "Launch Readiness Details",
            order: 2, status: 0,
            inputData: {
            },
            isStateValid: false
        },
        {
            key: "REVIEW_AND_SUBMIT",
            text: "Review And Submit",
            order: 3, status: 0,
            inputData: {
            },
            isStateValid: false
        }
    ]