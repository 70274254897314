import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility/makeActionCreator";

export const GET_STORE_PRODUCT_SEARCH_RESULT = "SPM/SearchSP/GET_STORE_PRODUCT_SEARCH_RESULT";
export const getStoreProductSearchResult = makeActionCreator(GET_STORE_PRODUCT_SEARCH_RESULT, false, "searchFilter");

export const SET_STORE_PRODUCT_SEARCH_RESULT = "SPM/SearchSP/SET_STORE_PRODUCT_SEARCH_RESULT";
export const setStoreProductSearchResult = makeActionCreator(SET_STORE_PRODUCT_SEARCH_RESULT, false, "searchResults");


export const DESTROY_SEARCH_STORE_PRODUCT = "SPM/SearchSP/DESTROY_SEARCH_STORE_PRODUCT";
export const destroySearchStoreProduct = makeActionCreator(DESTROY_SEARCH_STORE_PRODUCT, true);


export const SAVE_SEARCH_STORE_PRODUCT_CRITERIA = "SPM/SearchSP/SAVE_SEARCH_STORE_PRODUCT_CRITERIA";
export const saveSearchStoreProductCriteria = makeActionCreator(SAVE_SEARCH_STORE_PRODUCT_CRITERIA, false, "searchCriteria");


export const SET_SUBSCRIBER_STORE_PRODUCT = "SPM/SearchSP/SET_SUBSCRIBER_STORE_PRODUCT";
export const setSubscriberStoreProduct = makeActionCreator(SET_SUBSCRIBER_STORE_PRODUCT, false);


export const GET_USER_SUBSCRIBE_STATUS = "SPM/SearchSP/GET_USER_SUBSCRIBE_STATUS";
export const getUserSubscribeStatus = makeActionCreator(GET_USER_SUBSCRIBE_STATUS, false);

export const SET_USER_SUBSCRIBE_STATUS = "SPM/SearchSP/SET_USER_SUBSCRIBE_STATUS";
export const setUserSubscribeStatus = makeActionCreator(SET_USER_SUBSCRIBE_STATUS, false, "userSubscribeStatus");

export const REMOVE_USER_SUBSCRIPTION = "SPM/SearchSP/REMOVE_USER_SUBSCRIPTION";
export const removeUserSubscription = makeActionCreator(REMOVE_USER_SUBSCRIPTION, false, "subscribedUser");
