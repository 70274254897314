import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Card, CardBody } from "@ec-oem/ec.oem.oa3.ui.core/components";
import { Label, IconButton } from 'office-ui-fabric-react';
import './SubscriberCard.scss';
import {
    setAllSubscriber, setAddSubscriberList, setRemoveSubscriberList
} from './ConfigureSubscriber.actions';
import { allSubscribersSelector, removeSubscriberListSelector, addSubscriberListSelector } from './ConfigureSubscriber.selectors';
import { bindActionCreators } from 'redux';
import ReactAI from 'react-appinsights';
import { connect } from 'react-redux';
import { PageTitles } from '../../../../constants';

class SubscriberCard extends Component {

    handleRemove = () => {

        let { addSubscriberList, removeSubscriberList, allSubscribers, Name, Email } = this.props;
        const removeSubscriber = { Name: Name, Email: Email };

        const isAddedSubscriberRemoved = addSubscriberList && removeSubscriber &&
            addSubscriberList.some(subscriber => subscriber.Email === removeSubscriber.Email);

        if (isAddedSubscriberRemoved) {
            allSubscribers = allSubscribers.filter(subscriber => subscriber.Email != removeSubscriber.Email);
            this.props.actions.setAllSubscriber(allSubscribers);

            addSubscriberList = addSubscriberList.filter(subscriber => subscriber.Email != removeSubscriber.Email);
            this.props.actions.setAddSubscriberList(addSubscriberList);

        } else {
            allSubscribers = allSubscribers.filter(subscriber => subscriber.Email != removeSubscriber.Email);
            this.props.actions.setAllSubscriber(allSubscribers);

            removeSubscriberList.push(removeSubscriber);
            this.props.actions.setRemoveSubscriberList(removeSubscriberList);
        }
    }

    render() {

        return (
            <div className="ms-Grid-col ms-md4 ms-sm12 subscriber-card">
                <Card>
                    <CardBody>
                        <div className="ms-Grid">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-md12 ms-sm12">
                                    <IconButton
                                        iconProps={{ iconName: 'Cancel' }}
                                        title="Remove"
                                        aria-label="Remove"
                                        onClick={() => this.handleRemove()}
                                        className="subscriber-remove-action"
                                    />
                                    {(this.props.Name && this.props.Name.trim()) && <h5 className="card-title" >{this.props.Name}</h5>}
                                    {(this.props.Email && this.props.Email.trim()) && <h6 className="card-body">{this.props.Email}</h6>}
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        allSubscribers: allSubscribersSelector(state),
        addSubscriberList: addSubscriberListSelector(state),
        removeSubscriberList: removeSubscriberListSelector(state)
    };
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        setAllSubscriber, setAddSubscriberList, setRemoveSubscriberList
    }, dispatch)
})


export default ReactAI.withTracking(injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscriberCard)), PageTitles.CONFIGURE_SUBSCRIBER);