import React from 'react';
import { Label } from 'office-ui-fabric-react';
import { injectIntl } from 'react-intl';


const BigCatChildDetailsFields = (props) => {
    return (
        <React.Fragment>
            <div className="ms-Grid">
            { props.index == 1 &&
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                    <Label name="ProductSKULbl" id="ProductSKULbl" className="labelHeader">{props.intl.formatMessage({ id: 'Product SKUID', defaultMessage: 'Product SKUID' })}</Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">                  
                    <Label name="ProductDetailedDescriptionLbl" id="ProductDetailedDescriptionLbl" className="labelHeader">{props.intl.formatMessage({ id: 'createStoreProduct.BigCatDetails.ProductDetailDescription', defaultMessage: 'Product Description' })}</Label>
                </div>   
                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                    <Label name="ChildPartNumberlbl" id="ChildPartNumberLbl"className="labelHeader">{props.intl.formatMessage({ id: 'Part Number', defaultMessage: 'Part Number' })}</Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                    <Label name="ChildPartNumberlbl" id="ChildPartNumberLbl"className="labelHeader">{props.intl.formatMessage({ id: 'Status', defaultMessage: 'Status' })}</Label>
                </div>
            </div> 
            }
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                    <Label name="ProductSKUVal" id="ProductSKUVal" title={props.ProductDescription} className="labelValue-long-text">{ props.ChildProductSKUID }</Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">                  
                    <Label name="ProductDetailedDescriptionVal" id="ProductDetailedDescriptionVal" title={props.ProductDetailedDescription} className="labelValue-long-text">{ props.ChildProductDetailedDescription || '-'}</Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                    <Label name="ChildPartNumberVal" id="ChildPartNumberVal" title={props.ChildPartNumber} className="labelValue-long-text">{props.ChildPartNumber || '-'}</Label>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                    <Label name="ChildPartNumberVal" id="ChildPartNumberVal" title={props.ChildPartNumber} className="labelValue-long-text">{props.Status || '-'}</Label>
                </div>
            </div>
        </div>
    </React.Fragment>);
}

export default injectIntl(BigCatChildDetailsFields);













