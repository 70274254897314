import React from 'react';
import PropTypes from 'prop-types';


const propTypes = {
    stepComponentMap: PropTypes.object.isRequired,
    currentStep: PropTypes.object.isRequired
}


const WizardStepFactory = ({ stepComponentMap, currentStep, onPreviousClick, onSubmitClick }) => {
    if (currentStep && stepComponentMap) {
        var Component = stepComponentMap[currentStep.key];
    }
    if (!Component)
        return null;
    return (
        <Component initialState={currentStep} onPreviousClick={onPreviousClick} onSubmitClick={onSubmitClick} />
    );
};
WizardStepFactory.propTypes = propTypes;

export default WizardStepFactory;