import * as React from 'react';
import { PropTypes } from 'prop-types';
import { DetailsList, SelectionMode, DetailsListLayoutMode, Icon } from 'office-ui-fabric-react';
import './DetailedListWrapper.scss'; 

const propTypes = {
    items: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    totalCount: PropTypes.number.isRequired,
    handleChange: PropTypes.func,
    selected: PropTypes.object,
    onRowItemClick: PropTypes.func
}

class DetailedListWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.columnSpec = [];
    }

    componentWillMount() {
        this.columnSpec = this.generateColumnList(this.props.columns);
    }

    componentWillReceiveProps(newProps) {
        this.columnSpec = this.generateColumnList(this.props.columns);
        this.setState({selected: newProps.selected});
    }


   

    generateColumnList = (columnsData) => {
        let columnSpec = [];
        let onRowItemClick = this.props.onRowItemClick;
        columnsData.forEach(element => {
            let spec = {
                key: element.fieldName,
                name: element.name,
                fieldName: element.fieldName,
                minWidth: element.minWidth,
                maxWidth: element.maxWidth,
                isResizable: true,
            };

            if (element.render) {
                let options =  element.render.options || {};
                if (element.render.type === 'icon') {
                    spec.onRender =  (item) => {
                        return <Icon iconName={options.iconName} className={'detalied-list-icon ' + options.className} 
                        onClick={() => { onRowItemClick(element.fieldName, item) }} ariaLabel={options.iconName + ' icon'} />
                    }
                }
                if (element.render.type === 'link') {
                    spec.onRender =  (item) => {
                        return <a href="javascript:void(0);" tabIndex={0} ariaLabel={item.ariaLabel} onClick={() => { onRowItemClick(element.fieldName, item) }} className={options.className}>
                            {item[element.fieldName]}
                        </a>
                    }
                }
            }
            
            columnSpec.push(spec)
        });

        return columnSpec;
    }

    render() {
        return (
            <div className="detailed-list-wrapper">
                <div className="list">
                    <DetailsList
                        disableSelectionZone={true}
                        items={this.props.items}
                        compact={false}
                        columns={this.columnSpec}
                        selectionMode={SelectionMode.none}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                    />
                </div>
            </div>
        );
    }
}

DetailedListWrapper.propTypes = propTypes;
export default (DetailedListWrapper);