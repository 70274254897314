import React, { Component } from 'react';
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl';
import { selectedAttachmentsSelector } from '../../../selectors';
import { ImageType } from '../../../constants';
import "./DigitalAttachProductSearchResult.scss";
import { downloadDealAttachmentFile } from '../../../actions';

class StrategicLinkDownloadFile extends Component {

    getFileExt = (fileName) => {
        let defaultFileExt = "TXT";

        let fileNameExt = fileName.toString().split(".");
        return (fileNameExt) ? fileNameExt.pop().toUpperCase() : defaultFileExt;
    }

    getImgByFileType = (file) => {
        const fileExt = this.getFileExt(file);
        return ImageType[fileExt];
    }

    downloadTheFile = (event, docAttachments) => {
     
        this.props.downloadFile(docAttachments.StrategicLinksDocumentDTO);

    }

    getFileName = (fileName) => {
        var name = fileName.substr(0, fileName.lastIndexOf('.'));
        return name;
    }

    render() {
        const { docAttachments } = this.props;
        return (
            <div className="uploaded-doc-wrapper-main">
                <div className="row">
                    {docAttachments && docAttachments.DiscountsApplied.map((value, key) =>
                    value.StrategicLinksDocumentDTO && value.StrategicLinksDocumentDTO && value.StrategicLinksDocumentDTO.FileDTO.FileName &&
                        <div className=" ms-Grid-col ms-sm6 ms-md4 ms-lg4 ms-xl3" key={key}>
                            <div className="  ms-card even-level-card component-tile uploaded-doc-wrapper d-flex align-items-start">
                                <img className="file-type" src={this.getImgByFileType(value.StrategicLinksDocumentDTO.FileDTO.FileName)} alt="document" />
                                <div className="doc-file-details">
                                    <a id={`File_${key + 1}`} href='javascript:;' onClick={(event) => this.downloadTheFile(event, value)}>{value.StrategicLinksDocumentDTO.FileDTO.FileName && this.getFileName(value.StrategicLinksDocumentDTO.FileDTO.FileName)}</a>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    docAttachments: selectedAttachmentsSelector(state)
})

const mapDispatchToProps = (dispatch) => ({
    downloadFile(fileToDownload) {
        dispatch(downloadDealAttachmentFile(fileToDownload));
    }
})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(StrategicLinkDownloadFile))