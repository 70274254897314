import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Label } from 'office-ui-fabric-react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

class SubmitSuccessComponent extends Component {

    render() {
        const { productDetails } = this.props;

        return (
            <React.Fragment>
                {productDetails.PartNumber &&
                    <div className="order-confirmation">
                        <div className="status-msg-header">
                            Store Product Submitted Successfully
                     </div>
                        <Label className="status-msg">Part Number#  <Link className="order-status-text"
                            to={`/StoreProduct/View/${productDetails.ProductSKUID}`}>
                            {productDetails.PartNumber}</Link>   has been submitted successfully
                    </Label>
                        <Label className="status-msg"> You can view the Product by clicking the link </Label>
                    </div>}
            </React.Fragment>
        );
    }
}
SubmitSuccessComponent.propTypes = {
    productDetails: PropTypes.any.isRequired
};

export default injectIntl(SubmitSuccessComponent);




