import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BigCatDetailsFields, LaunchReadinessDetailsFields } from '../CreateStoreProduct/WizardSteps';
import { productDetailsSelector, launchReadinessMetaDataSelector } from "../CreateStoreProduct/CreateStoreProduct.selectors";
import ReactAI from 'react-appinsights';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
//import { setStoreProductMode } from '../CreateStoreProduct/CreateStoreProduct.actions';
import { withRouter } from 'react-router-dom';
import ViewPageheader from '../ViewStoreProduct/ViewPageheader';
import './ViewStoreProduct.scss';
import { viewBigCatPDSelector, viewLaunchReadinessPDSelector, viewProductDetailsSelector, viewBigCatChildPDSelector } from './ViewStoreProduct.selectors';
import { initializeDataForView, setEditProductDetails, destroyViewStoreProduct } from './ViewStoreProduct.actions';
import history from '../../../../utility/history';
import { PageTitles/*, SPModes*/ } from '../../../../constants';
import { setPageTitle } from '../../../../common/services/PageTitle';
import { SPStatus } from '../../constants';
import BigCatChildDetailsFields from '../CreateStoreProduct/WizardSteps/BigCatChildDetailsFields';
import {
    FeatureAlerts,
    showAlert,
    hideFeatureAlert,
  } from "@ec-oem/ec.oem.oa3.ui.core/components";
  import { AlertsTypes } from "@ec-oem/ec.oem.oa3.ui.core/constants";
  import { VIEW_STOREPRODUCT_EDIT } from '../CreateStoreProduct/WizardSteps/CreateStoreProductConstants';
import { TagItemBase } from 'office-ui-fabric-react';

class ViewStoreProduct extends Component {
    constructor(props) {
        super(props);
        this.onEdit = this.onEdit.bind(this);
        this.isDisabled = this.isDisabled.bind(this);
    }
    componentDidMount() {
        setPageTitle(this.props.intl.formatMessage({ id: 'pagetitle.viewStoreProduct' }));
    }
    componentWillMount() {
        this.props.actions.initializeDataForView(this.props.match.params.productId);
    }

    componentWillUnmount() {
        this.props.actions.destroyViewStoreProduct();
    }

    onEdit() {
        this.props.actions.hideFeatureAlert(VIEW_STOREPRODUCT_EDIT);
        if (this.isDisabled()) {
            this.props.actions.showAlert({
                type: AlertsTypes.ERROR,
                feature: VIEW_STOREPRODUCT_EDIT,
                message: [
                  this.props.intl.formatMessage({ id: "ViewSP.EditButton.Error" }),
                ],
              });
        }
        else
        {
        this.props.actions.setEditProductDetails();
        const isEditActive =(this.props.viewProductDetails.status===SPStatus.ACTIVE);
        setPageTitle(this.props.intl.formatMessage({ id: isEditActive? 'pagetitle.editStoreProduct' :'pagetitle.createStoreProduct' }));
        history.push(`/StoreProduct/${this.props.match.params.productId}`);

        }
    }

    isDisabled() {
        let child = this.props.viewProductDetails.ChildProducts;
        let result = child.filter(child => child.PartNumber != null)
        if (result.length > 0)
        return true;
        else
        return false;
    };
    
    render() {
        const { viewBigCatPD, viewProductDetails } = this.props;
        let viewLaunchReadinessPD = this.props.viewLaunchReadinessPD;
        let viewBigCatChildPD = this.props.viewBigCatChildPD;
        
        
        // if (launchReadinessMetaData && viewLaunchReadinessPD) {
        //     const { ContentTypes, ContentClassifications, OnlineContents } = launchReadinessMetaData;
        //     viewLaunchReadinessPD.ContentType = getMetaDataObject(ContentTypes, viewLaunchReadinessPD.ContentType);
        //     viewLaunchReadinessPD.ContentClassification = getMetaDataObject(ContentClassifications, viewLaunchReadinessPD.ContentClassification);
        //     viewLaunchReadinessPD.OnlineContent = getMetaDataObject(OnlineContents, viewLaunchReadinessPD.OnlineContent);
        // }
        return (
            <div className="container">
                {viewBigCatPD && viewLaunchReadinessPD && 
                    <div>
                        <FeatureAlerts timeOut={5000} feature={VIEW_STOREPRODUCT_EDIT} />
                        <ViewPageheader headerText="View Store Product" status={viewProductDetails.Status} msId={viewProductDetails.ProductSKUID} msIdFieldText="Big Cat ID" isEdit={true} onEditClick={this.onEdit} isdisabled={false} partNumber={viewProductDetails.PartNumber || null} />
                        <div className="viewdetails">
                        <div className="border-bottom">
                        <BigCatDetailsFields {...viewBigCatPD} heading={this.props.intl.formatMessage({ id: 'ViewSP.BigCat.Heading', defaultMessage: 'Big Cat Details' })} />
                        </div>
                        <div> 
                        <LaunchReadinessDetailsFields {...viewLaunchReadinessPD} heading={this.props.intl.formatMessage({ id: 'ViewSP.LaunchReadiness.Title', defaultMessage: 'Launch Readiness Details' })} />
                        </div>
                       
                        {viewBigCatChildPD && viewBigCatChildPD.length > 0 && <div>
                        <p className='header-text'> {'Child SKUs Information'}</p>                       
                        {viewBigCatChildPD.map((item,index) => (
                        <BigCatChildDetailsFields {...item} index ={ index + 1 } heading={this.props.intl.formatMessage({ id: 'ViewSP.ChildSKUsInformation.Heading', defaultMessage: 'Child SKUs Information' })} />
                        ))}
                        </div>}
              
                        </div>
                    </div>
                }
            </div>
        );
    }
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ initializeDataForView, destroyViewStoreProduct, setEditProductDetails,showAlert,
        hideFeatureAlert, }, dispatch)
})
function mapStateToProps(state) {

    return {
        viewBigCatPD: viewBigCatPDSelector(state),
        viewLaunchReadinessPD: viewLaunchReadinessPDSelector(state),
        viewProductDetails: viewProductDetailsSelector(state),
        launchReadinessMetaData: launchReadinessMetaDataSelector(state),
        viewBigCatChildPD: viewBigCatChildPDSelector(state),
    };
}
export default ReactAI.withTracking(injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ViewStoreProduct))), PageTitles.VIEW_STORE_PRODUCT);
