import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility/makeActionCreator";

export const SET_FORM_STATUS = "DIGITAL_PRODUCT_VIEWER/SET_FORM_STATUS";
export const setFormStatus = makeActionCreator(SET_FORM_STATUS, false,"status");

export const GET_SEARCH_RESULTS = "DIGITAL_PRODUCT_VIEWER/GET_SEARCH_RESULTS";
export const getSearchResults = makeActionCreator(GET_SEARCH_RESULTS, false,"criteria", "viewerType");

export const SET_SEARCH_CRITERIA = "DIGITAL_PRODUCT_VIEWER/SET_SEARCH_CRITERIA";
export const setSearchCriteria = makeActionCreator(SET_SEARCH_CRITERIA, false,"criteria");

export const SET_SELECTED_PRODUCT = "DIGITAL_PRODUCT_VIEWER/SET_SELECTED_PRODUCT";
export const setSelectedProduct = makeActionCreator(SET_SELECTED_PRODUCT, false,"product");

export const SET_SELECTED_PRICING_ADJUSTMENTS = "DIGITAL_PRODUCT_VIEWER/SET_SELECTED_PRICING_ADJUSTMENTS";
export const setSelectedPricingAdjustments = makeActionCreator(SET_SELECTED_PRICING_ADJUSTMENTS, false,"adjustment");


export const SET_SELECTED_ATTACHMENTS = "DIGITAL_PRODUCT_VIEWER/SET_SELECTED_ATTACHMENTS";
export const setSelectedAttachments = makeActionCreator(SET_SELECTED_ATTACHMENTS, false,"attachments");


export const SET_SEARCH_RESULT_VIEW = "DIGITAL_PRODUCT_VIEWER/SET_SEARCH_RESULT_VIEW";
export const setSearchResultView = makeActionCreator(SET_SEARCH_RESULT_VIEW, false,"viewType");

export const CHANGE_SEARCH_RESULT_VIEW = "DIGITAL_PRODUCT_VIEWER/CHANGE_SEARCH_RESULT_VIEW";
export const changeSearchResultView = makeActionCreator(CHANGE_SEARCH_RESULT_VIEW, false,"viewType","value");

export const DIGITAL_PRODUCT_EXPORT = "DIGITAL_PRODUCT_VIEWER/DIGITAL_PRODUCT_EXPORT";
export const exportDigitalProduct = makeActionCreator(DIGITAL_PRODUCT_EXPORT, false, "viewerType");

export const SET_TITLE_FLAG = "DIGITAL_PRODUCT_VIEWER/SET_TITLE_FLAG";
export const setTitleFlag = makeActionCreator(SET_TITLE_FLAG, false, "digitalProductViewerTitle");

export const DESTROY_DIGITAL_ATTACH = "DIGITAL_PRODUCT_VIEWER/DESTROY_DIGITAL_ATTACH";
export const destroyDigitalAttach = makeActionCreator(DESTROY_DIGITAL_ATTACH, false);

export const DOWNLOAD_DEAL_ATTACHMENT_FILE = "DIGITAL_PRODUCT_VIEWER/DOWNLOAD_DEAL_ATTACHMENT_FILE";
export const downloadDealAttachmentFile = makeActionCreator(DOWNLOAD_DEAL_ATTACHMENT_FILE, false,"fileToDownload");


