import { createSelector } from 'reselect'

export const formStatusSelector = createSelector(
    state => state.app.get("formStatus"),
    formStatus => formStatus 
);

export const searchCriteriaSelector = createSelector(
    state => state.app.get("searchCriteria"),
    criteria => criteria && criteria.toJS()
);

export const selectedProductSelector = createSelector(
    state => state.app.get("selectedProduct"),
    product => product && product.toJS() 
);

export const selectedPricingAdjustmentSelector = createSelector(
    state => state.app.get("selectedPricingAdjustment"),
    adjustment => adjustment && adjustment.toJS()
);

export const selectedAttachmentsSelector = createSelector(
    state => state.app.get("selectedAttachments"),
    selectedAttachments => selectedAttachments && selectedAttachments.toJS()
);

export const searchStateSelector = createSelector(
    state => state.app.get("searchState"),
    searchState => searchState 
);

export const digitalProductViewerTitleSelector = createSelector(
    state => state.app.get("digitalProductViewerTitle"),
    digitalProductViewerTitle => digitalProductViewerTitle && digitalProductViewerTitle.toJS()
);