import React, { Component } from 'react';
import { searchCriteriaSelector } from '../../../selectors';
import { connect } from 'react-redux';
import "./OAProductSearchResult.scss"
import OAProductSearchResult from './OAProductSearchResult';

class OAProductSearchResultContainer extends Component {
    render() {
        let { searchCriteria } = this.props;
        if (!searchCriteria) return null;
        searchCriteria = searchCriteria.reduce((result, criteria) => {
            if (criteria.displayText)
                result.push({ label: criteria.key, value: criteria.displayText });
            return result;
        }, []);
        return <OAProductSearchResult searchCriteria={searchCriteria} />
    }
}

const mapStateToProps = (state) => {
    return {
        searchCriteria: searchCriteriaSelector(state)
    }
}

export default connect(mapStateToProps)(OAProductSearchResultContainer);