import React, { Component } from 'react';
import { connect } from 'react-redux'
import { selectedPricingAdjustmentSelector } from '../../selectors';
import { Announced, Text } from 'office-ui-fabric-react';
import AdjustmentCard from './AdjustmentCard';
import { injectIntl } from 'react-intl';

class NetPriceDetails extends Component {
    render() {
        const {netPrice} = this.props;
        if(!netPrice) return null;
        return (
            <div className="ms-slideRightIn10">
             {(!netPrice.DiscountsApplied || netPrice.DiscountsApplied.length === 0) && <React.Fragment>
                <Announced id={`netpriceannounced`} message={this.props.intl.formatMessage({ id: 'digitalproductviewer.netpricedetail.noresults', defaultMessage: " No Adjustment Details" })} />
                <Text className={' noresults center-align'}variant={"mediumPlus"} nowrap block>
                    {this.props.intl.formatMessage({ id: 'digitalproductviewer.netpricedetail.noresults', defaultMessage: "No Adjustment Details" })}
                </Text>

            </React.Fragment>}
            <React.Fragment>
            <div className="ms-Grid net-price-details">
                <div className="ms-Grid-row adjustment-cards">
                    {netPrice.DiscountsApplied.map(discount=><AdjustmentCard {...discount}/>)}
                </div>
                </div>
            </React.Fragment>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    netPrice : selectedPricingAdjustmentSelector(state)
})




export default injectIntl(connect(mapStateToProps)(NetPriceDetails))
