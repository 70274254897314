import { call, put, all, apply, take, select } from 'redux-saga/effects';
import { ApplicationConfig, InvokeUrl } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import { showAlert } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { AlertsTypes, AlertsFeature } from '@ec-oem/ec.oem.oa3.ui.core/constants';
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components';
import { soldToDetailsSelector } from '../../selectors';
import { GET_SOLD_TO_DETAILS, setSoldToDetails, setFormStatus, UPDATE_SELECTED_SOLDTO, setSelectedSoldTo,setSearchCriteria,setSearchResultView,DESTROY_DIGITAL_ATTACH } from '../../actions';
import { Statuses } from '../../constants';
import {destroySearch} from '@ec-oem/ec.oem.oa3.ui.common/actions';
import {PageTitles} from '../../../../constants';

export function* getSoldToSaga() {
    while (true) {
        try {
            yield take(GET_SOLD_TO_DETAILS);
            const soldToDetails = yield select(soldToDetailsSelector);
            if (!soldToDetails) {
                const config = yield ApplicationConfig.config;
                const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/v1/da/companies`, method: 'GET' } });
                if (response.status === 200) {
                    const data = yield apply(response, response.json);
                    let soldToDetails = data.filter((company) => company.SAPAccountGroupCode === "0096");
                    soldToDetails.map((company) => {
                        company.CompanyNameAndId = `${company.CompanyId} - ${company.CompanyName}`;
                        company.displayText = company.CompanyNameAndId
                    });
                    yield put(setSoldToDetails(soldToDetails));
                    if (soldToDetails && soldToDetails.length > 0)
                        yield put(setFormStatus(Statuses.SUCCESS));
                    else {
                        yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.STORE_PRODUCT_ALERTS, message: ["Cannot use this page as no Sold to company is associated with this account"] }));
                        yield put(setFormStatus(Statuses.ERROR));
                    }
                }
                else {
                    yield put(setFormStatus(Statuses.FAILURE));
                }
            }
        }
        catch (error) {
            yield put(throwException("getSoldToSaga" + error));
        }
    }
}

export function* updateSelectedSoldTo() {
    while (true) {
        try {
            const { selectedSoldTo } = yield take(UPDATE_SELECTED_SOLDTO);
            yield put(setSelectedSoldTo(selectedSoldTo));            
        }
        catch (error) {
            yield put(throwException("updateSelectedSoldTo" + error));
        }
    }
}
export function* destroyDigitalAttach(){
    while (true) {
        yield take(DESTROY_DIGITAL_ATTACH);
        yield put(destroySearch(PageTitles.DIGITAL_PRODUCT_VIEWER));
        yield put(setFormStatus(null));
        yield put(setSoldToDetails(null));
        yield put(setSelectedSoldTo(null));
        yield put(setSearchCriteria(null));
        yield put(setSearchResultView(null));

    }
}