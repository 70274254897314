export const LaunchReadinessFieldsLabel = {
  ITEMDESCRIPTION: "ItemDescription",
  ProgramDifferentiator: "ProgramDifferentiator",
  MAXCIMDESCRIPTION: "MaxcimDescription",
  PRODUCTFAMILY: "ProductFamily",
  REDEMPTIONEXPIRYDAYS: "RedemptionExpiryDaysPostWindowsActivation",
  EXPIRATIONDAYSPOSTPBR: "ExpirationDaysPostPBR",
  OEMORDERABILITYSTARTDATE: "OEMOrderabilityStartDate",
  OEMORDERABILITYENDDATE: "OEMOrderabilityEndDate",
  ENDOFLIFE: "EndOfLife",
  CONTENTTYPE: "ContentType",
  CONTENTCLASSIFICATION: "ContentClassification",
  ONLINECONTENT: "OnlineContent",
  REDEMPTIONAVAILABILITY: "RedemptionAvailability",
};

export const CreateSPModes = {
  EDIT_DRAFT: "EDIT_DRAFT",
  EDIT_ACTIVE: "EDIT_ACTIVE",
  INVALID: "INVALID",
};

export const LAUNCH_READINESS_ALERTS = "LAUNCH_READINESS_ALERTS";
export const VIEW_STOREPRODUCT_EDIT = "VIEW_STOREPRODUCT_EDIT";