import React, { Component } from 'react';
import { Label } from 'office-ui-fabric-react';
import { ItemListWithFilter } from '../../../../common/components';
import { PropTypes } from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { agreementDetailsSelector } from './AgreementListControl.selectors';
import { setSelectedAgreement } from './AgreementListControl.actions';
import { isNullOrUndefined } from 'util';

const propTypes = {
    onSelect: PropTypes.func
}


const agrCols = [
    { key: 'column1', name: 'Agreement Number', fieldName: 'AgreementNumber', minWidth: 20, maxWidth: 150, isResizable: true },
    { key: 'column2', name: 'Description', fieldName: 'Description', minWidth: 50, maxWidth: 300, isResizable: true },
    { key: 'column3', name: 'StartDate', fieldName: 'StartDate', minWidth: 30, maxWidth: 150, isResizable: true },
    { key: 'column4', name: 'EndDate', fieldName: 'EndDate', minWidth: 30, maxWidth: 150, isResizable: true }
];

class AgreementListControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null
        }
    }

    OnChange = (selectedItem) => {
        this.props.actions.setSelectedAgreement(selectedItem);
        this.props.onSelect(selectedItem.AgreementNumber);
        this.setState({ selectedItem: selectedItem });
    }

    render = () => {
        const { agreements } = this.props;
        if (!agreements) {
            return null;
        }

        let items = [];
        agreements.forEach(agr => {
            items.push({
                AgreementNumber: agr.AgreementNumber,
                Description: agr.Description,
                StartDate: this.props.intl.formatDate(new Date(agr.StartDate)),
                EndDate: this.props.intl.formatDate(new Date(agr.EndDate)),
            });
        });

        const  text = isNullOrUndefined(this.state.selectedItem) ? '' : `(${this.state.selectedItem.AgreementNumber}) ` + this.state.selectedItem.Description;
        return (
            <React.Fragment>
                <ItemListWithFilter
                    required={true}
                    selectedText={text}
                    items={items}
                    label={this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCriteria.agreementLabel', defaultMessage: "Agreements" })}
                    ariaLabel={this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCriteria.agreementLabel', defaultMessage: "Agreements" })}
                    columns={agrCols}
                    selectedLabel={this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCriteria.agreementSelectedLabel', defaultMessage: "Selected Agreement" })}
                    FilterLabel={this.props.intl.formatMessage({ id: 'digitalProductViewer.agreementlistcontrol.filterlabel', defaultMessage: "Filter by Agreement Number or Description" })}
                    FilterFields={["AgreementNumber", "Description"]}
                    showFilter={true}
                    onSelectionChanged={(item) => this.OnChange(item)}
                    
                />
            </React.Fragment>
        );

    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ setSelectedAgreement }, dispatch)

})

const mapStateToProps = (state) => {
    return {
        agreements: agreementDetailsSelector(state)
    }
}

AgreementListControl.propTypes = propTypes;
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AgreementListControl));