import React from 'react';
import { injectIntl } from 'react-intl';
import { Card, CardHeader, CardBody } from "@ec-oem/ec.oem.oa3.ui.core/components";
import {  Link } from 'office-ui-fabric-react';
import './SearchStoreProductCard.scss';
import history from '../../../../utility/history';


const handleClick = (props) => {
    history.push(`/StoreProduct/View/${props.ProductSKUID}`);
}
const handleKeyPress = (e, props) => {
    e.preventDefault();
    handleClick(props);
}


const SearchStoreProductCard = (props) => {
    const { index } = props;
    return (
        <div className="search-storeProduct-card ms-Grid-col ms-md6 ms-sm12 ms-xl4">
            <Card>
                <CardHeader>
                    <div className="ms-Grid">
                        <span className="card-title product-desc block-with-text-1" title={props.ProductSKUID}>
                            <Link onKeyPress={(event) => handleKeyPress(event, props)} onClick={() => handleClick(props)} className="cardTitleButton">
                                {props.ProductSKUID}
                            </Link> 
                        </span>
                        <span className="herarchy">
                            {props.IsDAPrimary === 1 ? 'Parent' : props.IsDAPrimary == 2 ? 'Child' : '' }
                        </span>

                        <span className="card-secondary-information">{props.PartNumber}</span>
                        <div className="card-secondary-information">
                            <span>
                                {props.OEMOrderabilityStartDate && props.intl.formatDate(new Date(props.OEMOrderabilityStartDate))}
                            </span>
                            <span>
                                {props.OEMOrderabilityEndDate && ` - ${props.intl.formatDate(new Date(props.OEMOrderabilityEndDate))}`}
                            </span>
                        </div>
                        <span className="card-secondary-information">{props.ProductDescription}</span>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="ms-Grid">
                        <span className="product-title block-with-text-3" title={props.ProductDetailedDescription}>{props.ProductDetailedDescription}</span>
                        <div>
                            <span className="status">
                                {props.Status}
                            </span>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div >
    );
}

export default injectIntl(SearchStoreProductCard);