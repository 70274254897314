import { InvokeUrl } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import ApplicationConfig from '../../../utility/applicationConfig';
import { call, put, apply, take } from 'redux-saga/effects';
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components/Logger';

export function* getMetaDataSaga() {
    try {
        const config = yield ApplicationConfig.config;
        const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/v1/da/metadata`, method: 'GET' } });
        if (response.status === 200) {
            const data = yield apply(response, response.json);
            return data;
        }
    } catch (error) {
        yield put(throwException("getProductDetailsSagaNew error: " + error));
    }
}
