import { Card, CardHeader, CardBody } from "@ec-oem/ec.oem.oa3.ui.core/components";
import React from 'react';
import { injectIntl } from "react-intl";
import './AdjustmentCard.scss';

const AdjustmentCard = (props) => {
    const { DiscountName,VolumeTiers } = props;
    return (
        <div className="ms-Grid-col ms-sm12 ms-md6 ms-xl4 adjustment-card-details">
            <Card>
                <CardHeader>
                    <div className="card-title">{DiscountName}</div>
                </CardHeader>
                <CardBody>
                    <div className="card-details">
                        <table>
                            <thead>
                                <tr>
                                    <td>
                                        {props.intl.formatMessage({ id: 'digitalProductViewer.netpricedetails.tier', defaultMessage: "Tier" })}
                                    </td>
                                    <td>
                                        {props.intl.formatMessage({ id: 'digitalProductViewer.moqdetails.adjustment', defaultMessage: "Adjustment" })}
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {VolumeTiers.map((bp) => <VolumeTierDiscount {...bp} />)}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};


const AdjustmentTypes = {
    DISCOUNT:"DISCOUNT",
    UPLIFT:"UPLIFT"
};


const VolumeTierDiscount = ({ Min, Max,DiscountType, Adjustment }) => {
    return (
        <tr>
            <td>
                {`${Min} - ${Max}`}
            </td>
            <td>
                {Adjustment >0 ? `$${Adjustment}`:`$(${-Adjustment})`}
            </td>
        </tr>
    );
};


export default injectIntl(AdjustmentCard)