export const PageTitles = {
    HOME:"HOME",
    CREATE_STORE_PRODUCT: "Create Store Product",
    EDIT_STORE_PRODUCT: "Edit Store Product",
    SEARCH_STORE_PRODUCT: "Search Store Product",
    VIEW_STORE_PRODUCT: "View Store Product",
    DIGITAL_PRODUCT_VIEWER:"Digital Product Viewer",
    CONFIGURE_SUBSCRIBER: "Configure Subscriber"
}

export const RedirectPath = "/redirectURI";

export const WizardStatuses = {
    DISABLED: 0,
    NOT_STARTED: 1,
    IN_PROGRESS: 2,
    COMPLETED: 3,
    VALIDATION_ERRROR: 4,
};


export const Titles = {
    OA_PRODUCT_VIEWER:"OA Product Viewer",
    DIGITAL_PRODUCT_VIEWER:"Digital Product Viewer"
}

export const Strategic = {
    NON_HARDWARE :"G51",
    HARDWARE:"G52",
    MANUAL:"G53",
    NON_HARDWARE_UPLIFT :"G55",
    HARDWARE_UPLIFT:"G56",
    MANUAL_UPLIFT:"G57"
}

export const RequestHeaderConstants = {
	SubscriptionKey: "Ocp-Apim-Subscription-Key"
};