import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { Callout, FocusTrapZone } from "office-ui-fabric-react";
import PropTypes from "prop-types";
import { TabIndexes } from "../../constants/TabIndexConstants";
import "./PageHeader.scss";

const propTypes = {
	HeaderTitle: PropTypes.string,
	HeaderTooltipContent: PropTypes.object,
	isTooltipRequired: PropTypes.bool,
	pageHeaderClassName: PropTypes.string,
	tooltipClassName: PropTypes.string
};

class PageHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCalloutVisible: false
		};
		this.tooltipControl = React.createRef();
	}
	_onHoverFocusClicked = () => {
		this.setState({
			isCalloutVisible: !this.state.isCalloutVisible
		});
	};
	_onCalloutDismiss = () => {
		this.setState({
			isCalloutVisible: false
		});
	};

	render() {
		return (
			<div
				className={
					(this.props.pageHeaderClassName ? this.props.pageHeaderClassName : " ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl6") +
					" page-header-text-section page-header page-header-deflex remove-page-header-margin"
				}>
				<h4 className="Page-Title-Text">{this.props.HeaderTitle}</h4>
				{this.props.isTooltipRequired && (
					<span className="section-Information-Icon">
						<i
							ref={this.tooltipControl}
							role={"button"}
							id="headersectiontooltip"
							tabIndex={TabIndexes.InOrder}
							className="ms-Icon ms-Icon--Info"
                            aria-label={`${this.props.ariaHeaderTitle || this.props.HeaderTitle} info icon`}
							onClick={() => this._onHoverFocusClicked()}
							onKeyPress={() => this._onHoverFocusClicked()}></i>
					</span>
				)}
				{this.props.isTooltipRequired && this.state.isCalloutVisible && (
					<Callout
						className={this.props.tooltipClassName ? this.props.tooltipClassName : "tooltip-header-title"}
						gapSpace={0}
						target={this.tooltipControl.current}
						onDismiss={this._onCalloutDismiss}
						setInitialFocus={false}
						calloutMaxWidth={520}>
						<FocusTrapZone isClickableOutsideFocusTrap={true} forceFocusInsideTrap={false}>
							<div role={"presentation"} className=" tooltip-main-section-content " tabIndex="0">
								<span className="hide-action-statusmessage">
									{this.props.tooltipAriaLabel
                                        ? (this.props.ariaHeaderTitle || this.props.HeaderTitle) +
										  " " +
										  this.props.intl.formatMessage({
												id: "app.tooltip",
												defaultMessage: "Tooltip."
										  }) +
										  " " +
										  this.props.tooltipAriaLabel
										: this.props.intl.formatMessage({
												id: "app.tooltip",
												defaultMessage: "Tooltip."
										  })}
								</span>
								{this.props.HeaderTooltipContent}
							</div>
						</FocusTrapZone>
					</Callout>
				)}
			</div>
		);
	}
}
PageHeader.propTypes = propTypes;
export default injectIntl(PageHeader);
