import { createReducer } from "@ec-oem/ec.oem.oa3.ui.core/utility/createReducer";
import { fromJS } from 'immutable'
import { SET_MODAL_DATA, MODAL_CLOSE, MODAL_OPEN } from './modal.actions';

export const modal = createReducer(null, {
    [SET_MODAL_DATA](state, { data }) {
        const { name } = data;
        var temp = { ...data, isOpen: false };
        if (!state)
            return fromJS({ [name]: fromJS(temp) })
        else {
            state = state.set(name, fromJS(temp))
            return fromJS(state)
        }
    },
    [MODAL_CLOSE](state, { name }) {
        if (!name) return state;
        var modal = state.get(name);
        if (!modal) return fromJS(state);
        modal = modal.set("isOpen",false);
        state = state.set(name,fromJS(modal));
        return fromJS(state);
    },
    [MODAL_OPEN](state, { name }) {
        if (!name) return state;
        var modal = state.get(name);
        if (!modal) return fromJS(state);
        modal = modal.set("isOpen",true);
        state = state.set(name,fromJS(modal));
        return fromJS(state);
    }
});