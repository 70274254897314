import { fromJS } from 'immutable';
import { createReducer } from '@ec-oem/ec.oem.oa3.ui.core/utility/createReducer';
import { SET_FORM_STATUS, SET_SEARCH_CRITERIA,
         SET_SELECTED_PRODUCT, SET_SELECTED_PRICING_ADJUSTMENTS,
         SET_SEARCH_RESULT_VIEW, SET_TITLE_FLAG, SET_SELECTED_ATTACHMENTS } from '../actions';

export const formStatus = createReducer(null, {
    [SET_FORM_STATUS](state, { status }) {
        return fromJS(status);
    },
});

export const searchCriteria = createReducer(null, {
    [SET_SEARCH_CRITERIA](state, { criteria }) {
        return fromJS(criteria);
    },
});


export const selectedProduct = createReducer(null, {
    [SET_SELECTED_PRODUCT](state, { product }) {
      return fromJS(product);
    },
  });
  
  export const selectedPricingAdjustment = createReducer(null, {
    [SET_SELECTED_PRICING_ADJUSTMENTS](state, { adjustment }) {
      return fromJS(adjustment);
    },
  });

  export const selectedAttachments = createReducer(null, {
    [SET_SELECTED_ATTACHMENTS](state, { attachments }) {
      return fromJS(attachments);
    },
  });
  
  export const searchState = createReducer(null, {
    [SET_SEARCH_RESULT_VIEW](state, { viewType }) {
      return fromJS(viewType);
    },
  });

  export const digitalProductViewerTitle = createReducer(null,{
    [SET_TITLE_FLAG](state, { digitalProductViewerTitle }) {
      return fromJS(digitalProductViewerTitle);
    },
  })