import { fromJS } from 'immutable';
import { createReducer } from '@ec-oem/ec.oem.oa3.ui.core/utility/createReducer';
import {
    SET_STORE_PRODUCT_SEARCH_RESULT,
    SAVE_SEARCH_STORE_PRODUCT_CRITERIA,
    SET_USER_SUBSCRIBE_STATUS
} from './SearchStoreProduct.actions';

export const searchResults = createReducer(null, {
    [SET_STORE_PRODUCT_SEARCH_RESULT](state, { searchResults }) {
        return fromJS(searchResults);
    },
});


export const searchShipmentCriteria = createReducer(null, {
    [SAVE_SEARCH_STORE_PRODUCT_CRITERIA](state, { searchCriteria }) {
        return fromJS(searchCriteria)
    }
})


export const userSubscribeStatus = createReducer(null, {
    [SET_USER_SUBSCRIBE_STATUS](state, { userSubscribeStatus }) {
        return fromJS(userSubscribeStatus)
    }
})
