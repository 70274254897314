import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import BreadCrumbWrapper from '../../BreadCrumbWrapper';
import { InMemorySearchResult, SearchDefaultSelectionTypes } from '@ec-oem/ec.oem.oa3.ui.common/components/Search';
import DitigitalAttachProductCard from './DigitalAttachProductCard';
import { PageTitles } from '../../../../../constants';
import { digitalSearchMappers } from './DigitalAttachProductSearchMapper';
import { SearchResultStates, DealViewerType } from '../../../constants'; 
import { setSearchResultView, changeSearchResultView } from '../../../actions';

import { searchStateSelector } from '../../../selectors';
import { exportDigitalProduct } from '../../../actions';
import ProductDetails from './ProductDetails/ProductDetails';
import '@ec-oem/ec.oem.oa3.ui.common/resources/styles/equalHeightSearchCards.scss';
import NetPriceDetails from '../../AdjustmentDetails/NetPriceDetails';
import StrategicLinkDownloadFile from './StrategicLinkDownloadFile';

const defaultSections = {
    [SearchDefaultSelectionTypes.PAGING_OPTION]: {
        page: 1,
        pageSize: 12
    },
    [SearchDefaultSelectionTypes.SELECTED_FILTERS]: null,
    [SearchDefaultSelectionTypes.SELECTED_ITEMS]: null,
    [SearchDefaultSelectionTypes.SORT_OPTION]: null
};
class DigitalAttachProductSearchResult extends Component {

    componentWillMount(){
        this.props.changeResultView(SearchResultStates.PRODUCT_SEARCH);
    }

    handleExport = () => {
        this.props.actions.exportDigitalProduct(DealViewerType.DSBDealViewer);
    }
    
    render() {
        const {searchCriteria,searchState} = this.props;
        return (
            <React.Fragment>
                <div className="digital-attach-search-result">
                    <BreadCrumbWrapper Breadscrumbkey="PartNumber" />
                    {(!searchState ||searchState === SearchResultStates.PRODUCT_SEARCH) &&
                     <InMemorySearchResult searchName={PageTitles.DIGITAL_PRODUCT_VIEWER}
                        searchCriteria={searchCriteria}
                        defaultSelections={defaultSections}
                        defaultFilterCollapse={true}
                        ResultIteratorComponent={DitigitalAttachProductCard}
                        mappers={digitalSearchMappers}
                        handleExport={this.handleExport}
                    />}
                    
                    {searchState === SearchResultStates.PRODUCT_DETAILS && <ProductDetails/>}
                    {searchState === SearchResultStates.PRICING_ADJUSTMENTS && <NetPriceDetails/>}
                    {searchState === SearchResultStates.STRATEGIC_LINK && <StrategicLinkDownloadFile/>}
                   
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        searchState : searchStateSelector(state)
    }
}

const mapDispatchToProps = (dispatch) => ({
    changeResultView(type){
        dispatch(changeSearchResultView(type));
    },
    actions: bindActionCreators({exportDigitalProduct},dispatch)
})



export default connect(mapStateToProps, mapDispatchToProps)(DigitalAttachProductSearchResult)