import { fromJS } from 'immutable';
import { createReducer } from '@ec-oem/ec.oem.oa3.ui.core/utility/createReducer';
import {
    SET_VIEW_PRODUCT_DETAILS, SET_VIEW_BIG_CAT_PD,
    SET_VIEW_LAUNCH_READINESS_PD,
    SET_STORE_PRODUCT_MODE,SET_VIEW_BIG_CAT_CHILD_PD
} from './ViewStoreProduct.actions';

export const viewProductDetails = createReducer(null, {
    [SET_VIEW_PRODUCT_DETAILS](state, { viewProductDetails }) {
        return fromJS(viewProductDetails);
    },
});

export const viewBigCatPD = createReducer(null, {
    [SET_VIEW_BIG_CAT_PD](state, { viewBigCatPD }) {
        return fromJS(viewBigCatPD);
    },
});

export const viewBigCatChildPD = createReducer(null, {
    [SET_VIEW_BIG_CAT_CHILD_PD](state, { viewBigCatChildPD }) {
        return fromJS(viewBigCatChildPD);
    },
});


export const viewLaunchReadinessPD = createReducer(null, {
    [SET_VIEW_LAUNCH_READINESS_PD](state, { viewLaunchReadinessPD }) {
        return fromJS(viewLaunchReadinessPD);
    },
});


export const storeProductMode = createReducer(null, {
    [SET_STORE_PRODUCT_MODE](state, { storeProductMode }) {
        return fromJS(storeProductMode);
    },
});