import { call, put, all, apply, take, select } from "redux-saga/effects";
import {
  GET_PRODUCT_DETAILS,
  setProductDetails,
  GET_LAUNCH_READINESS_META_DATA,
  setLaunchReadinessMetaData,
  SAVE_STORE_PRODUCT_DRAFT,
  SUBMIT_STORE_PRODUCT,
  setBigCatProductDetails,
  setLaunchReadinessPD,
  setStoreProducteWizardCompletion,
  DESTROY_CREATE_STORE_PRODUCT,
  INITIALIZE_DATA_FOR_CREATE,
  setPFAMValidation,
  resetPFAMs,
  ignorePFAM,
  resetIgnorePFAMs,
  setPartnumber,
  setBigCatChildProductDetails,
} from "./CreateStoreProduct.actions";
import {
  productDetailsSelector,
  bigCatPDSelector,
  launchReadinessPDSelector,
  bigCatChildPDSelector,
  launchReadinessMetaDataSelector,
  PFAMValiditySelector,
  ignorePFAMSelector,
  storeProductModeSelector,
  partNumberSelector,
} from "./CreateStoreProduct.selectors";
import {
  ApplicationConfig,
  InvokeUrl,
} from "@ec-oem/ec.oem.oa3.ui.core/utility";
import { getProductDetailsSaga } from "../../sagas/productDetailSaga";
import { throwException } from "@ec-oem/ec.oem.oa3.ui.common/components/Logger";
import { showAlert } from "@ec-oem/ec.oem.oa3.ui.core/components/Alert";
import {
  AlertsTypes,
  AlertsFeature,
} from "@ec-oem/ec.oem.oa3.ui.core/constants";
import { getMetaDataSaga } from "../../sagas/metaDataSaga";
import { productDetailsToBigCatAndLaunchReadinessMapper } from "../../services/ProductDetailsToBigCatAndLaunchReadinessMapper";
import { getLaunchReadinessMetaDataForUI } from "../../services/metaDataMapper";
import { SPStatus, SPModes, PD, ModalNames } from "../../constants";
import { setStoreProductMode } from "../ViewStoreProduct";
import {
  modalOpen,
  TAKE_MODAL_ACTION,
  ModalActions,
  modalClose,
} from "../Common/Modal/modal.actions";
import { bigCatChildPD } from "./CreateStoreProduct.reducers";

export function* initiliazeCreatePageSaga() {
  while (true) {
    var { productId } = yield take(INITIALIZE_DATA_FOR_CREATE);
    const createProductDetails = yield select(productDetailsSelector);
    const currentMetaDataDetails = yield select(
      launchReadinessMetaDataSelector
    );
    let productDetails = createProductDetails || null;
    let metaData = currentMetaDataDetails || null;

    if (!createProductDetails && !currentMetaDataDetails) {
      const { productCallData, metaDataCallData } = yield all({
        productCallData: call(getProductDetailsSaga, productId),
        metaDataCallData: call(getMetaDataSaga),
      });
      productDetails = productCallData;
      metaData = getLaunchReadinessMetaDataForUI(metaDataCallData);
      yield put(setLaunchReadinessMetaData(metaData));
    } else if (!createProductDetails) {
      productDetails = yield call(getProductDetailsSaga, productId);
    } else {
      const metaDataCallData = yield call(getMetaDataSaga);
      metaData = getLaunchReadinessMetaDataForUI(metaDataCallData);
      yield put(setLaunchReadinessMetaData(metaData));
    }

    yield put(setProductDetails(productDetails));
    if (productDetails.Status === SPStatus.ACTIVE) {
      yield put(setStoreProductMode(SPModes.EDIT_ACTIVE));
    } else {
      yield put(setStoreProductMode(SPModes.EDIT_DRAFT));
    }

    const {
      bigCatPD,
      launchReadinessPD,
      PartNumber,
      bigCatChildPD,
    } = productDetailsToBigCatAndLaunchReadinessMapper(
      metaData,
      productDetails
    );
    yield put(setBigCatProductDetails(bigCatPD));
    yield put(setLaunchReadinessPD(launchReadinessPD));
    yield put(setPartnumber(PartNumber));
    yield put(setBigCatChildProductDetails(bigCatChildPD));
  }
}

const getProductDetails = (bigCatPD, launchReadinessPD, PartNumber) => {
  let productDetails = {};
  if (!bigCatPD || !launchReadinessPD) {
    return productDetails;
  }
  productDetails = {
    ProductSKUID: bigCatPD.ProductSKUID,
    ProductDetailedDescription: bigCatPD.ProductDetailedDescription,
    ProductDescription: bigCatPD.ProductDescription,
    RedemptionAvailability: new Date(
      launchReadinessPD.RedemptionAvailability
    ).toDateString(),
    // RedemptionExpiryDaysPostWindowsActivation:
    //   launchReadinessPD.RedemptionExpiryDaysPostWindowsActivation,
    ExpirationDaysPostPBR: launchReadinessPD.ExpirationDaysPostPBR,
    GeoAvailability: bigCatPD.GeoAvailability,
    IsDAPrimary : bigCatPD.IsDAPrimary,
    Language: bigCatPD.Language,

    ItemDescription: launchReadinessPD.ItemDescription,
    ProgramDifferentiator:
      launchReadinessPD.ProgramDifferentiator &&
      launchReadinessPD.ProgramDifferentiator.key
        ? "PTK"
        : "NON",
    MaxcimDescription: launchReadinessPD.MaxcimDescription,
    ProductFamily: launchReadinessPD.ProductFamily.trim(),
    OEMOrderabilityStartDate: new Date(
      launchReadinessPD.OEMOrderabilityStartDate
    ).toDateString(),
    OEMOrderabilityEndDate: new Date(
      launchReadinessPD.OEMOrderabilityEndDate
    ).toDateString(),
    EndOfLife: new Date(launchReadinessPD.EndOfLife).toDateString(),
    ContentType:
      launchReadinessPD.ContentType && launchReadinessPD.ContentType.key,
    ContentClassification:
      launchReadinessPD.ContentClassification &&
      launchReadinessPD.ContentClassification.key,
    OnlineContent:
      launchReadinessPD.OnlineContent && launchReadinessPD.OnlineContent.key,
    PartNumber: PartNumber,
  };
  return productDetails;
};

const getSaveProductDetails = (bigCatPD, launchReadinessPD, PartNumber, bigCatChildPD) => {
  let productDetails = {};
  if (!bigCatPD || !launchReadinessPD) {
    return productDetails;
  }
  productDetails = {
    ProductSKUID: bigCatPD.ProductSKUID,
    ProductDetailedDescription: bigCatPD.ProductDetailedDescription,
    ProductDescription: bigCatPD.ProductDescription,
    RedemptionAvailability: new Date(
      launchReadinessPD.RedemptionAvailability
    ).toDateString(),
    // RedemptionExpiryDaysPostWindowsActivation:
    //   launchReadinessPD.RedemptionExpiryDaysPostWindowsActivation,
    ExpirationDaysPostPBR: launchReadinessPD.ExpirationDaysPostPBR,
    GeoAvailability: bigCatPD.GeoAvailability,
    IsDAPrimary : bigCatPD.IsDAPrimary,
    Language: bigCatPD.Language,

    ItemDescription: launchReadinessPD.ItemDescription,
    ProgramDifferentiator:
      launchReadinessPD.ProgramDifferentiator &&
      launchReadinessPD.ProgramDifferentiator.key
        ? "PTK"
        : "NON",
    MaxcimDescription: launchReadinessPD.MaxcimDescription,
    ProductFamily: launchReadinessPD.ProductFamily.trim(),
    OEMOrderabilityStartDate: new Date(
      launchReadinessPD.OEMOrderabilityStartDate
    ).toDateString(),
    OEMOrderabilityEndDate: new Date(
      launchReadinessPD.OEMOrderabilityEndDate
    ).toDateString(),
    EndOfLife: new Date(launchReadinessPD.EndOfLife).toDateString(),
    ContentType:
      launchReadinessPD.ContentType && launchReadinessPD.ContentType.key,
    ContentClassification:
      launchReadinessPD.ContentClassification &&
      launchReadinessPD.ContentClassification.key,
    OnlineContent:
      launchReadinessPD.OnlineContent && launchReadinessPD.OnlineContent.key,
    PartNumber: PartNumber,
    ChildProducts: bigCatChildPD,
  };
  return productDetails;
};

function* PFAMValidation(productFamily) {
  let ignorance = yield select((state) =>
    ignorePFAMSelector(state, productFamily)
  );
  if (!ignorance) {
    yield put(modalOpen(ModalNames.PFAM));
    while (true) {
      const { name, action } = yield take(TAKE_MODAL_ACTION);
      if (name === ModalNames.PFAM) {
        if (action === ModalActions.PROCEED) {
          yield put(modalClose(ModalNames.PFAM));
          yield put(ignorePFAM(productFamily, true));
          return true;
        }
        yield put(modalClose(ModalNames.PFAM));
        return false;
      }
    }
  }
  return ignorance;
}

function* checkPFAMSaga(productFamily) {
  try {
    var validity = yield select((state) =>
      PFAMValiditySelector(state, productFamily)
    );
    if (validity == null) {
      const config = yield ApplicationConfig.config;
      const response = yield call(InvokeUrl, {
        Config: {
          url: `${config.web_api_path}/api/v1/da/product/IsPFAMExists/${productFamily}`,
          method: "GET",
        },
      });
      if (response.status === 200) {
        const data = yield apply(response, response.json);
        yield put(setPFAMValidation(productFamily, data));
        return data;
      }
      return null;
    }
    return validity;
  } catch (error) {
    yield put(throwException("PFAM Saga error: " + error));
  }
  return null;
}

export function* saveDAProductsSaga() {
  while (true) {
    try {
      let { bigCatPD, launchReadinessPD } = yield take(
        SAVE_STORE_PRODUCT_DRAFT
      );
      let mode = yield select(storeProductModeSelector);
      let PartNumber = yield select(partNumberSelector);
      if (mode === SPModes.EDIT_ACTIVE) {
        yield put(
          throwException("saving as draft in edit active mode is not allowed")
        );
        continue;
      }
      let isValidPFAM = null;
      isValidPFAM = launchReadinessPD.ProductFamily
        ? yield* checkPFAMSaga(launchReadinessPD.ProductFamily)
        : true;

      if (isValidPFAM == null) continue;
      else if (isValidPFAM)
        yield* saveAsDraft(bigCatPD, launchReadinessPD, PartNumber);
      else {
        let test = yield* PFAMValidation(launchReadinessPD.ProductFamily);
        if (test) yield* saveAsDraft(bigCatPD, launchReadinessPD, PartNumber);
      }
    } catch (error) {
      yield put(throwException("saveDAProductsSaga error: " + error));
    }
  }
}

function* saveAsDraft(bigCatPD, launchReadinessPD, PartNumber) {
  try {
    let productDetails = getProductDetails(
      bigCatPD,
      launchReadinessPD,
      PartNumber
    );

    productDetails = JSON.stringify(productDetails);
    const config = yield ApplicationConfig.config;

    const response = yield call(InvokeUrl, {
      Config: {
        url: `${config.web_api_path}/api/v1/da/product/draft`,
        method: "PUT",
        data: productDetails,
      },
    });
    if (response.status === 200) {
      yield put(
        showAlert({
          type: AlertsTypes.SUCCESS,
          feature: AlertsFeature.STORE_PRODUCT_ALERTS,
          message: ["Draft Store Product Details saved successfully"],
        })
      );
      const data = yield apply(response, response.json);
      yield put(setProductDetails(data));
    }
  } catch (error) {
    yield put(throwException("saveDAProductsSaga error: " + error));
  }
}

function* submitProductdetails(bigCatPD, launchReadinessPD, PartNumber, bigCatChildPD) {
  try {
    let productDetails = getSaveProductDetails(
      bigCatPD,
      launchReadinessPD,
      PartNumber,
      bigCatChildPD
    );

    productDetails = JSON.stringify(productDetails);
    const config = yield ApplicationConfig.config;

    const response = yield call(InvokeUrl, {
      Config: {
        url: `${config.web_api_path}/api/v1/da/product/submit`,
        method: "PUT",
        data: productDetails,
      },
    });
    if (response.status === 200) {
      yield put(
        showAlert({
          type: AlertsTypes.SUCCESS,
          feature: AlertsFeature.STORE_PRODUCT_ALERTS,
          message: ["Store Product Details submitted successfully"],
        })
      );
      const data = yield apply(response, response.json);
      yield put(setProductDetails(data));
      yield put(setStoreProducteWizardCompletion(true));
    }
  } catch (error) {
    yield put(throwException("submit product details error: " + error));
  }
}

export function* submitDAProductsSaga() {
  while (true) {
    try {
      let { bigCatPD, launchReadinessPD, bigCatChildPD} = yield take(SUBMIT_STORE_PRODUCT);
      let mode = yield select(storeProductModeSelector);
      let PartNumber = yield select(partNumberSelector);
      if (mode === SPModes.EDIT_DRAFT) {
        let isValidPFAM = null;
        isValidPFAM = yield* checkPFAMSaga(launchReadinessPD.ProductFamily);

        if (isValidPFAM == null) continue;
        else if (isValidPFAM)
          yield* submitProductdetails(bigCatPD, launchReadinessPD, PartNumber,bigCatChildPD);
        else {
          let test = yield* PFAMValidation(launchReadinessPD.ProductFamily);
          if (test)
            yield* submitProductdetails(
              bigCatPD,
              launchReadinessPD,
              PartNumber,
              bigCatChildPD
            );
        }
      } else if (mode === SPModes.EDIT_ACTIVE)
        yield* submitProductdetails(bigCatPD, launchReadinessPD, PartNumber, bigCatChildPD);
    } catch (error) {
      yield put(throwException("submitDAProductsSaga error: " + error));
    }
  }
}

export function* destroyCreateStoreProductSaga() {
  while (true) {
    try {
      yield take(DESTROY_CREATE_STORE_PRODUCT);
      yield put(setProductDetails(null));
      yield put(setBigCatProductDetails(null));
      yield put(setLaunchReadinessPD(null));
      yield put(setLaunchReadinessMetaData(null));
      yield put(setStoreProducteWizardCompletion(null));
      yield put(resetPFAMs());
      yield put(resetIgnorePFAMs());
    } catch (error) {
      yield put(
        throwException("destroyCreateStoreProductSaga error: " + error)
      );
    }
  }
}
