import * as React from 'react';
import * as tslib_1 from "tslib";
import { injectIntl } from 'react-intl';
import {
    DetailsList, SelectionMode, DetailsListLayoutMode,
    Selection, TextField, MarqueeSelection, Label, ScrollablePane,
    ScrollbarVisibility, Sticky, StickyPositionType,
    ConstrainMode, TooltipHost, DetailsRow
} from 'office-ui-fabric-react';
import { Accordion, AccordionBody, AccordionHeader } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { PropTypes } from 'prop-types';
import './ItemListWithFilter.scss';

const propTypes = {
    items: PropTypes.array,
    columns: PropTypes.array.isRequired,
    FilterLabel: PropTypes.string,
    label: PropTypes.string,
    selectedLabel: PropTypes.string,
    ariaLabel: PropTypes.string,
    FilterFields: PropTypes.array,
    onSelectionChanged: PropTypes.func,
    onItemInvoked: PropTypes.func,
    showFilter: PropTypes.bool,
    required: PropTypes.bool,
    selectedText: PropTypes.string,
    errorMessage: PropTypes.string
}

const classNames = mergeStyleSets({
    wrapper: {
        height: '50vh',
        position: 'relative'
    },
    filter: {
        paddingBottom: 20,
        maxWidth: 300
    },
    header: {
        margin: 0
    },
    row: {
        display: 'inline-block'
    }
});


class ItemListWithFilter extends React.Component {
    constructor(props) {
        super(props);
        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.onItemInvoked = this.onItemInvoked.bind(this);
        this.selection = new Selection({
            onSelectionChanged: () => this.getSelectionDetails()
        });

        this.state = {
            selectedItem: {},
            items: props.items
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.items !== this.props.items) {
            this.setState({ items: nextProps.items });
        }
    }

    onItemInvoked = (item, index, ev) => {
     
    }

    getSelectionDetails = () => {
        const selectedindex = this.selection.getSelectedIndices();
        if (selectedindex.length > 0) {
            let item = this.state.items[selectedindex[0]];
            if (this.props.onSelectionChanged) {
                this.props.onSelectionChanged(item);
            }
        }
    }

    onFilterChanged = (event, text) => {
        const { items, FilterFields, columns } = this.props;
        let columFields = columns.map(i => i.fieldName);
        let temp = [];
        if (text) {
            FilterFields.forEach(fld => {
                if (columFields.includes(fld)) {
                    temp.push(items.filter(item => item[fld].toLowerCase().indexOf(text.toLowerCase()) >= 0))
                }
            });
        }

        let filteredItems = [].concat.apply([], temp);
        this.setState({ items: text ? filteredItems : items });
    }

    onRenderDetailsHeader = (props, defaultRender) => {
        return (React.createElement(Sticky, { stickyPosition: StickyPositionType.Header, isScrollSynced: true }, defaultRender(tslib_1.__assign({}, props, { onRenderColumnHeaderTooltip: function (tooltipHostProps) { return React.createElement(TooltipHost, tslib_1.__assign({}, tooltipHostProps)); } }))));
    }

    onRenderDetailsFooter = (props, defaultRender) => {
        let footer = { key: "footer" };
        props.columns.forEach(col => {
            footer[col.fieldName] = col.name;
        });
        // return (
        //     // <Sticky stickyPosition={StickyPositionType.Footer} isScrollSynced={true}>
        //     //     <div className={classNames.row}>
        //     //         <DetailsRow
        //     //             columns={props.columns}
        //     //             item={footer}
        //     //             itemIndex={-1}
        //     //             selection={props.selection}
        //     //             selectionMode={(props.selection && props.selection.mode) || SelectionMode.none}
        //     //             viewport={props.viewport}
        //     //         />
        //     //     </div>
        //     // </Sticky>
        // );
        return(null);
    }

    render() {
        if (!this.props.items) {
            return null;
        }

        const { items: originalItems } = this.props;
        const { items } = this.state;
        const resultCountText = items.length === originalItems.length ? '' : ` (${items.length} of ${originalItems.length} shown)`;
        const { showFilter, FilterLabel, columns, ariaLabel,
            label, selectedLabel, required, selectedText,
            errorMessage } = this.props;

        return (
          <React.Fragment>
          
                    <div id='header'>
                        <Sticky stickyPosition={StickyPositionType.Header}>
                            <div className="ms-Grid" dir="ltr">                                
                                {showFilter &&
                                    <div className="ms-Grid-row row-space">
                                        <div className="ms-Grid-col ms-md12 ms-sm12 ms-lg6 ms-fontSize-12">
                                            <TextField label={`${FilterLabel}` + resultCountText} onChange={this.onFilterChanged} />
                                        </div>
                                    </div>}
                            </div>
                        </Sticky>
                    </div>
                      <div className={classNames.wrapper}>
                    <MarqueeSelection selection={this.selection} data-is-scrollable={true}>
                        <DetailsList
                            ariaLabel={ariaLabel}
                            items={items}
                            columns={columns}
                            setKey="set"
                            selectionMode={SelectionMode.single}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            selection={this.selection}
                            constrainMode={ConstrainMode.unconstrained}
                            onRenderDetailsHeader={this.onRenderDetailsHeader}
                            onRenderDetailsFooter={this.onRenderDetailsFooter}
                            selectionPreservedOnEmptyClick={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            onItemInvoked={this.onItemInvoked}
                        />
                    </MarqueeSelection>

            </div>
            </React.Fragment>
        );
    }
}

ItemListWithFilter.propTypes = propTypes;
export default injectIntl(ItemListWithFilter);
