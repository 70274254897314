import { createSelector } from 'reselect';

export const searchResultsSelector = createSelector(
    state => state.app.get("searchResults"),
    searchResults => searchResults ? searchResults.toJS() : null
);

export const searchCriteriaSelector = createSelector(
    state => state.app.get("searchCriteria"),
    searchCriteria => searchCriteria ? searchCriteria.toJS() : searchCriteria
);



export const userSubscribeStatusSelector = createSelector(
    state => state.app.get("userSubscribeStatus"),
    userSubscribeStatus => userSubscribeStatus
);
