import { fromJS } from 'immutable';
import { createReducer } from '@ec-oem/ec.oem.oa3.ui.core/utility/createReducer';
import {
    SET_ALL_SUBSCRIBERS, SET_ADD_SUBSCRIBER_LIST, SET_REMOVE_SUBSCRIBER_LIST,SET_MASTER_SUBSCRIBERS_LIST
} from './ConfigureSubscriber.actions';


export const allSubscribersData = createReducer(null, {
    [SET_ALL_SUBSCRIBERS](state, { allSubscribers }) {
        return fromJS(allSubscribers);
    }
});

export const masterSubscriberData = createReducer(null, {
    [SET_MASTER_SUBSCRIBERS_LIST](state, { masterSubscribersList }) {
        return fromJS(masterSubscribersList);
    }
});



export const addSubscriberList = createReducer(null, {
    [SET_ADD_SUBSCRIBER_LIST](state, { addSubscriberList }) {
        return fromJS(addSubscriberList);
    }
});



export const removeSubscriberList = createReducer(null, {
    [SET_REMOVE_SUBSCRIBER_LIST](state, { removeSubscriberList }) {
        return fromJS(removeSubscriberList);
    }
});


