import React from 'react';
import { injectIntl } from 'react-intl';
import { Label, ActionButton } from 'office-ui-fabric-react';
import './ViewStoreProduct.scss';
const ViewPageheader = (props) => {
    const idvalues=`${props.msIdFieldText} : ${props.msId} ` + (props.partNumber ? `| ${props.intl.formatMessage({ id: 'ViewSP.PartNumber', defaultMessage: "Part Number" })} : ${props.partNumber} | ` : "| ");
    console.log("VIEW PAGE HEADER props.isdisabled)")
    console.log(props.isdisabled)
    return (
        <div className="spview-details-header">
            {props.headerText}
            <div className="ms-Grid-row spview-details-sub-header">
                <div className="ms-Grid-col ms-md10">
                    <Label name="MSOrderDetails" id="MSOrderDetailsLabel" className="labelValue">{idvalues}
                    <span className="spview-status">{props.status}</span>
                    </Label>
                </div>
                <div className="ms-Grid-col ms-md2">
                    <div className="actions">
                        {props.isEdit === true && 
                            <ActionButton
                                data-automation-id="icon-edit"
                                iconProps={{ iconName: 'Edit' }}
                                text={props.intl.formatMessage({
                                    id: 'app.edit',
                                    defaultMessage: 'Edit',
                                })}
                                className="details-action-button"
                                onClick={props.onEditClick}
                                disabled={props.isdisabled}
                            />
                        }
                    </div>
                </div>
            </div>
        </div>


    );
}

export default injectIntl(ViewPageheader);