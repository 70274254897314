import React, { Component } from 'react';
import ReactAI from 'react-appinsights';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PageTitles } from '../../../../constants';
import { bindActionCreators } from 'redux';
import {
    destroyConfigureSubscriber, getAllSubscriber, setAllSubscriber,
    updateSubscribers, setAddSubscriberList
} from './ConfigureSubscriber.actions';
import { allSubscribersSelector, addSubscriberListSelector, removeSubscriberListSelector } from './ConfigureSubscriber.selectors';
import { AlertsFeature } from "@ec-oem/ec.oem.oa3.ui.core/constants";
import { setPageTitle } from '../../../../common/services/PageTitle';
import { FeatureAlerts } from "@ec-oem/ec.oem.oa3.ui.core/components";
import './ConfigureSubscriber.scss';
import { } from 'office-ui-fabric-react';
import { UserProfiles } from './UserProfiles';
import SubscriberResult from './SubscriberResult';


class ConfigureSubscriber extends Component {

    componentWillMount() {
        this.props.actions.getAllSubscriber();
    }

    componentWillUnmount() {
        this.props.actions.destroyConfigureSubscriber();
    }

    componentDidMount() {
        setPageTitle(this.props.intl.formatMessage({ id: 'pagetitle.ConfigureSubscriber' }));
    }

    selectAutoComp = (searchText) => {
        if (searchText && searchText.key != "") {
            let addSubscriber = searchText;
            let addSubscriberList = this.props.addSubscriberList;
            addSubscriberList.push(addSubscriber);
            this.props.actions.setAddSubscriberList(addSubscriberList);

            let allSubscribers = this.props.allSubscribers;
            if (!allSubscribers.some(subscriber => subscriber.Email === searchText)) {
                allSubscribers.push(searchText);
            }

            this.props.actions.setAllSubscriber(allSubscribers);
        }
    }


    render() {
        const { allSubscribers } = this.props;

        return (
            <div className="container configure-subscriber">
                <div className="page-header">
                    {this.props.intl.formatMessage({ id: 'configureSubscriber.title', defaultMessage: 'Manage Alert Subscribers' })}
                </div>
                <FeatureAlerts timeOut={5000} feature={AlertsFeature.STORE_PRODUCT_ALERTS} />

                <div className="configure-subscriber-textBox">
                    <UserProfiles id="userProfiles" name="userProfiles" onSelect={(searchText) => { this.selectAutoComp(searchText) }}
                        placeholder={this.props.intl.formatMessage({ id: 'configureSubscriber.UserProfiles.placeholder', defaultMessage: 'Enter atleast 3 characters to see the results' })}
                    />
                </div>
                {
                    allSubscribers &&
                    <div className="configure-subscriber-result">
                        <SubscriberResult {...this.props} />
                    </div>
                }
            </div >
        );
    }
}

ConfigureSubscriber.propTypes = {

};



const mapStateToProps = (state) => {
    return {
        allSubscribers: allSubscribersSelector(state),
        addSubscriberList: addSubscriberListSelector(state)
    };
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        destroyConfigureSubscriber, getAllSubscriber, setAllSubscriber, updateSubscribers, setAddSubscriberList
    }, dispatch)
})


export default ReactAI.withTracking(injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfigureSubscriber)), PageTitles.CONFIGURE_SUBSCRIBER);