import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Formik } from 'formik';
import * as Yup from "yup";
import OACriteriaForm from './OACriteriaForm';
import { isSoldToControlRequired,selectedSoldToSelector,selectedAgreementSelector } from '../../../selectors';
import { FieldConstants, DealViewerType } from '../../../constants';
import { bindActionCreators } from 'redux';
import { getSearchResults } from '../../../actions';
import './OAProductSearchCriteria.scss';

const getCriteriaValidation = () => {
    const validationSchema = Yup.object({
        [FieldConstants.SOLD_TO]: Yup.string("Select Sold To")
            .nullable()
            .when(['IsSoldToRequired'], {
                is: true,
                then: Yup.string().required("Please Select a SoldTo")
            }),
        [FieldConstants.AGREEMENT_NUMBER]: Yup.string("Select Agreement")
            .required("Please select an Agreement").nullable(),
        [FieldConstants.ORDERABILITY_STARTDATE]: Yup.string("Select Orderability Start Date").trim()
            .required("Please provide Orderability Start date").nullable(),
        [FieldConstants.ORDERABILITY_ENDDATE]: Yup.string("Select Orderability End Date").trim()
            .required("Please provide Orderability End date").test("End Date Validation", "End date should be greater than Start Date", function (OrderabilityEndDate) {
                let d1 = new Date(this.parent.OrderabilityStartDate).valueOf();
                let d2 = new Date(OrderabilityEndDate).valueOf();
                return d1 <= d2;
            }).nullable().nullable(),
    });
    return validationSchema;
}

class OAProductSearchCriteria extends Component {

    onSubmit = (values) => {
        this.props.actions.getSearchResults(values, DealViewerType.OADealViewer);
    }

    render() {
        const {selectedAgreement,selectedSoldTo}=this.props;
        return (
            <Formik
                initialValues={{ OrderabilityEndDate: new Date(), OrderabilityStartDate: new Date(), SoldTo: selectedSoldTo || "", AgreementNumber: selectedAgreement || "" }}
                validationSchema={getCriteriaValidation(this.props.isSoldToRequired)}
                render={props => <OACriteriaForm {...props} {...this.props} />}
                onSubmit={(values) => this.onSubmit(values)}
            />
        );
    }
}

OAProductSearchCriteria.propTypes = {

};

const mapStateToProps = (state) => {
    return {
        isSoldToRequired: isSoldToControlRequired(state),
        selectedSoldTo : selectedSoldToSelector(state),
        selectedAgreement : selectedAgreementSelector(state)

    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ getSearchResults }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(OAProductSearchCriteria)