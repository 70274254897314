import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Formik } from 'formik';
import * as Yup from "yup";
import CriteriaForm from './CriteriaForm';
import {  isSoldToControlRequired } from '../../../selectors';
import { FieldConstants, DealViewerType } from '../../../constants';
import { bindActionCreators } from 'redux';
import { getSearchResults } from '../../../actions';
import './DigitalAttachProductSearchCriteria.scss';

const getCriteriaValidation = () => {
    const validationSchema = Yup.object({
        [FieldConstants.SOLD_TO]:Yup.string("Select Sold To")
                    .nullable()
                    .when(['IsSoldToRequired'],{
                            is:true,
                            then:Yup.string().required("Please Select a SoldTo")
                     }),
        [FieldConstants.ORDERABILITY_STARTDATE]: Yup.string("Select Orderability Start Date").trim()
            .required("Please provide Orderability Start date").nullable(),
        [FieldConstants.ORDERABILITY_ENDDATE]: Yup.string("Select Orderability End Date").trim()
            .required("Please provide Orderability End date").test("End Date Validation", "End date should be greater than Start Date", function (OrderabilityEndDate) {
                let d1 = new Date(this.parent.OrderabilityStartDate).valueOf();
                let d2 = new Date(OrderabilityEndDate).valueOf();
                return d1 <= d2;
            }).nullable().nullable(),
    });
    return validationSchema;
}

class DigitalAttachProductSearchCriteria extends Component {

    onSubmit=(values)=>{
        this.props.actions.getSearchResults(values, DealViewerType.DSBDealViewer);
    }

    render() {
        return (
            <Formik
                initialValues={{OrderabilityEndDate: new Date(), OrderabilityStartDate: new Date(),SoldTo:"",ProductInfo:""}}
                validationSchema={getCriteriaValidation(this.props.isSoldToRequired)}
                render={props => <CriteriaForm {...props} {...this.props} />}
                onSubmit={(values) => this.onSubmit(values)}
            />
        );
    }
}

DigitalAttachProductSearchCriteria.propTypes = {

};

const mapStateToProps = (state) => {
    return {
        isSoldToRequired : isSoldToControlRequired(state)

    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({getSearchResults},dispatch)
})



export default connect(mapStateToProps, mapDispatchToProps)(DigitalAttachProductSearchCriteria)