import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility/makeActionCreator";

export const GET_PRODUCT_DETAILS = "SPM/CreateSP/GET_PRODUCT_DETAILS";
export const getProductDetails = makeActionCreator(GET_PRODUCT_DETAILS, false, "productId");

export const SET_PRODUCT_DETAILS = "SPM/CreateSP/SET_PRODUCT_DETAILS";
export const setProductDetails = makeActionCreator(SET_PRODUCT_DETAILS, false, "productDetails");

export const SET_BIG_CAT_PRODUCT_DETAILS = "SPM/CreateSP/SET_BIG_CAT_PRODUCT_DETAILS";
export const setBigCatProductDetails = makeActionCreator(SET_BIG_CAT_PRODUCT_DETAILS, false, "bigCatPD");

export const SET_BIG_CAT_CHILD_PRODUCT_DETAILS = "SPM/CreateSP/SET_BIG_CAT_CHILD_PRODUCT_DETAILS";
export const setBigCatChildProductDetails = makeActionCreator(SET_BIG_CAT_CHILD_PRODUCT_DETAILS, false, "bigCatChildPD");

export const SET_PARTNUMBER = "SPM/CreateSP/SET_PARTNUMBER";
export const setPartnumber = makeActionCreator(SET_PARTNUMBER, false, "partNumber");

export const SET_LAUNCH_READINESS_PD = "SPM/CreateSP/SET_LAUNCH_READINESS_PD";
export const setLaunchReadinessPD = makeActionCreator(SET_LAUNCH_READINESS_PD, false, "launchReadinessPD");

export const GET_STORE_PRODUCT_WIZARD_DATA = "SPM/CreateSP/GET_STORE_PRODUCT_WIZARD_DATA";
export const getStoreProductWizardData = makeActionCreator(GET_STORE_PRODUCT_WIZARD_DATA, false);

export const SET_STORE_PRODUCT_WIZARD_DATA = "SPM/CreateSP/SET_STORE_PRODUCT_WIZARD_DATA";
export const setStoreProductWizardData = makeActionCreator(SET_STORE_PRODUCT_WIZARD_DATA, false, "wizardData");

export const SET_SP_WIZARD_NEXT = "SPM/CreateSP/SET_SP_WIZARD_NEXT";
export const setSPWizardNext = makeActionCreator(SET_SP_WIZARD_NEXT, true, "stepData");

export const SET_SP_WIZARD_STEP = "SPM/CreateSP/SET_SP_WIZARD_STEP";
export const setSPWizardStep = makeActionCreator(SET_SP_WIZARD_STEP, true, "stepData");

export const SET_SP_WIZARD_CURRENT_STEP = "SPM/CreateSP/SET_SP_WIZARD_CURRENT_STEP";
export const setSPWizardCurrentStep = makeActionCreator(SET_SP_WIZARD_CURRENT_STEP, true, "stepData");

export const SET_SP_WIZARD_PREVIOUS = "SPM/CreateSP/SET_SP_WIZARD_PREVIOUS";
export const setSPWizardPrevious = makeActionCreator(SET_SP_WIZARD_PREVIOUS, true, "stepData");

export const SAVE_STORE_PRODUCT_DRAFT = "SPM/CreateSP/SAVE_STORE_PRODUCT_DRAFT";
export const saveStoreProductDraft = makeActionCreator(SAVE_STORE_PRODUCT_DRAFT, true, "bigCatPD", "launchReadinessPD");

export const SUBMIT_STORE_PRODUCT = "SPM/CreateSP/SUBMIT_STORE_PRODUCT";
export const submitStoreProduct = makeActionCreator(SUBMIT_STORE_PRODUCT, true, "bigCatPD", "launchReadinessPD", "bigCatChildPD");

export const GET_LAUNCH_READINESS_META_DATA = "SPM/CreateSP/GET_LAUNCH_READINESS_META_DATA";
export const getLaunchReadinessMetaData = makeActionCreator(GET_LAUNCH_READINESS_META_DATA, false);

export const SET_LAUNCH_READINESS_META_DATA = "SPM/CreateSP/SET_LAUNCH_READINESS_META_DATA";
export const setLaunchReadinessMetaData = makeActionCreator(SET_LAUNCH_READINESS_META_DATA, false, "launchReadinessMetaData");

export const SET_STORE_PRODUCT_WIZARD_COMPLETION = "SPM/CreateSP/SET_STORE_PRODUCT_WIZARD_COMPLETION";
export const setStoreProducteWizardCompletion = makeActionCreator(SET_STORE_PRODUCT_WIZARD_COMPLETION, false, "spWizardCompletion");

export const DESTROY_CREATE_STORE_PRODUCT = "SPM/CreateSP/DESTROY_CREATE_STORE_PRODUCT";
export const destroyCreateStoreProduct = makeActionCreator(DESTROY_CREATE_STORE_PRODUCT, false);


export const INITIALIZE_DATA_FOR_CREATE = "SPM/CreateSP/INITIALIZE_DATA_FOR_CREATE";
export const initializeDataForCreate = makeActionCreator(INITIALIZE_DATA_FOR_CREATE, false,"productId");

export const SET_PFAM_VALIDATION = "SPM/CreateSP/SET_PFAM_VALIDATION";
export const setPFAMValidation = makeActionCreator(SET_PFAM_VALIDATION, false,"productFamily","isValid");

export const RESET_PFAMS = "SPM/CreateSP/RESET_PFAMS";
export const resetPFAMs = makeActionCreator(RESET_PFAMS, false);

export const RESET_IGNORE_PFAMS = "SPM/CreateSP/RESET_IGNORE_PFAMS";
export const resetIgnorePFAMs = makeActionCreator(RESET_IGNORE_PFAMS, false);

export const IGNORE_PFAM = "SPM/CreateSP/IGNORE_PFAM";
export const ignorePFAM = makeActionCreator(IGNORE_PFAM, false,"productFamily","ignore");