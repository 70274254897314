import React, { Component } from 'react';
import { Card, CardHeader, CardBody, EqualHeightCardWrapper } from "@ec-oem/ec.oem.oa3.ui.core/components";
import { connect } from 'react-redux'
import { PlatformServerMapSelector } from '@ec-oem/ec.oem.oa3.ui.common/selectors';
import { Link } from 'office-ui-fabric-react';
import history from '../../../../utility/history';
import './DigitalProductViewerHome.scss'
import { Container, PageHeader } from '../../../../common/components';
import { injectIntl } from 'react-intl';
import { TabIndexConstants } from '@ec-oem/ec.oem.oa3.ui.core/constants';


class DigitalProductViewerHome extends Component {
    render() {

        const { platform } = this.props;
        if (!platform)
            return null;

        return (
            <div className="search-pr-section container">
            <div className="ms-Grid-row mrg-top10 page-title-section">
                <PageHeader 
                pageHeaderClassName={"ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 "}
                HeaderTitle={this.props.intl.formatMessage({ id: 'digitalStoreProduct.title', defaultMessage: 'Digital Product Viewer' })}
                /></div>
                <div className="ms-Grid digital-product-home">
                    <EqualHeightCardWrapper className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                            <div className="card" tabIndex={TabIndexConstants.FOCUS} role="link " onClick={() => { history.push("/DigitalProductViewer/OAProductViewer") }}>
                                <CardHeader>
                                    <div >{this.props.intl.formatMessage({ id: 'digitalproductviewer.home.OAproducts', defaultMessage: 'OA Products' })}</div>
                                </CardHeader>
                                <CardBody>{this.props.intl.formatMessage({ id: 'digitalproductviewer.home.OAproducts.description', defaultMessage: 'Please use the above link to access OA product details' })}</CardBody>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                            <div className="card" tabIndex={TabIndexConstants.FOCUS} role="link " onClick={() => { history.push("/DigitalProductViewer/DigitalAttachProductViewer") }}>
                                <CardHeader>
                                    <div>{this.props.intl.formatMessage({ id: 'digitalproductviewer.home.DAproducts', defaultMessage: 'DA Products' })}</div>
                                </CardHeader>
                                <CardBody>{this.props.intl.formatMessage({ id: 'digitalproductviewer.home.DAproducts.description', defaultMessage: 'Please use the above link to access DA product details' })}</CardBody>
                            </div>
                        </div>
                    </EqualHeightCardWrapper>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        platform: PlatformServerMapSelector(state)
    }
}

export default injectIntl(connect(mapStateToProps)(DigitalProductViewerHome))






