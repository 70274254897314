import { connect } from 'react-redux';
import React from 'react';
import PricingInformation from '../../../PricingInformation';
import { selectedProductSelector } from '../../../../selectors';
import OAProductAttributes from './OAProductAttributes';

class OAProductDetails extends React.PureComponent {
    render() {
        const { product } = this.props;
        if (!product) return null;
        const { NetPriceSlabs, ...attributes } = product;
        return (
            <div className="product-details ms-slideRightIn10">
                <OAProductAttributes attributes={attributes} />
                <PricingInformation slabs={NetPriceSlabs} />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        product: selectedProductSelector(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(OAProductDetails)