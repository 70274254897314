import { call, put, all, apply, take, select } from 'redux-saga/effects';
import fileDownload from 'react-file-download';
import {
    GET_SEARCH_RESULTS, setSearchCriteria,
    SET_SEARCH_RESULT_VIEW, CHANGE_SEARCH_RESULT_VIEW,
    setSearchResultView, setSelectedProduct,
    setSelectedPricingAdjustments, DIGITAL_PRODUCT_EXPORT, DOWNLOAD_DEAL_ATTACHMENT_FILE,setSelectedAttachments
} from '../actions';
import { soldToDetailsSelector, isSoldToControlRequired, searchCriteriaSelector } from '../selectors';
import { ApplicationConfig, InvokeUrl } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import { updateSearchResults, searchResultInfoItemSelector, SearchResultConstants } from '@ec-oem/ec.oem.oa3.ui.common/components/Search';
import { PageTitles } from '../../../constants'
import { SearchResultStates, Statuses, criteriaConstants, DealViewerType } from '../constants';
import { random6Digit } from '../../../utility/randomGenerator';
import { showAlert } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { AlertsTypes, AlertsFeature } from '@ec-oem/ec.oem.oa3.ui.core/constants';
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components';
import { getMetaDataSaga } from '../../StoreProductManagement/sagas/metaDataSaga';
import { getDealViewerQueryString, prepareSearchRequest, getFormattedDate } from '../utility';

export function* getDSBSearchResultsSaga() {
    try {
        while (true) {
            const { criteria, viewerType } = yield take(GET_SEARCH_RESULTS);
            if (viewerType === DealViewerType.DSBDealViewer) {
                const { OrderabilityEndDate, OrderabilityStartDate, ProductInformation } = criteria;
                const SoldTo = yield* getSoldToIfRequired(criteria);
                const allCriteria = getAllCriteria({ SoldTo, OrderabilityEndDate, OrderabilityStartDate, ProductInformation, AgreementNumber: '' });
                yield put(setSearchCriteria(allCriteria));
                let searchRequest = prepareSearchRequest(allCriteria, viewerType);

                const { searchCallData, metaDataCallData } = yield all({
                    searchCallData: call(searchSaga, searchRequest),
                    metaDataCallData: call(getMetaDataSaga)
                });
                let searchResult = getDSBDataByOrderabilityDates(searchCallData);//updateMetaDataInfoInSearchData(getDSBDataByOrderabilityDates(searchCallData),metaDataCallData);
                yield put(updateSearchResults(PageTitles.DIGITAL_PRODUCT_VIEWER, searchResult));
            }
        }
    } catch (error) {
        yield put(throwException("getDSBSearchResultsSaga " + error));

    }
}

export function* getOASearchResultsSaga() {
    try {
        while (true) {
            const { criteria, viewerType } = yield take(GET_SEARCH_RESULTS);
            if (viewerType === DealViewerType.OADealViewer) {
                const { OrderabilityEndDate, OrderabilityStartDate, AgreementNumber } = criteria;
                const SoldTo = yield* getSoldToIfRequired(criteria);
                const allCriteria = getAllCriteria({ SoldTo, OrderabilityEndDate, OrderabilityStartDate, ProductInformation: '', AgreementNumber });
                yield put(setSearchCriteria(allCriteria));
                let searchRequest = prepareSearchRequest(allCriteria, viewerType);

                const { searchCallData, metaDataCallData } = yield all({
                    searchCallData: call(searchSaga, searchRequest),
                    metaDataCallData: call(getMetaDataSaga)
                });

                //const searchCallData = yield* searchSaga(searchRequest);                
                let searchResult = modelOAsearchResult(searchCallData);
                yield put(updateSearchResults(PageTitles.DIGITAL_PRODUCT_VIEWER, searchResult));
            }
        }
    } catch (error) {
        yield put(throwException("getOASearchResultsSaga " + error));

    }
}

export function* exportDigitalProductSaga() {
    while (true) {
        try {
            const { viewerType } = yield take(DIGITAL_PRODUCT_EXPORT);
            const searchCriteria = yield select(searchCriteriaSelector);
            let searchRequest = prepareSearchRequest(searchCriteria, viewerType);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/v1/da/deals/downloaddigitalproducts${getDealViewerQueryString(searchRequest)}`, method: 'GET' } });
            if (response.status === 200) {
                const data = yield apply(response, response.blob);
                if (viewerType == DealViewerType.OADealViewer)
                    fileDownload(data, 'OAProductViewer_Export.xlsx');
                else if (viewerType == DealViewerType.DSBDealViewer)
                    fileDownload(data, 'DAProductViewer_Export.xlsx');

                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Report generated successfully"] }));
            }
            else {
                yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to generate report"] }));
            }
        }
        catch (error) {
            yield put(throwException("export digitalproduct error: " + error));
        }
    }
}

export function* setSearchResultViewSaga() {
    try {
        while (true) {
            const { viewType, value } = yield take(CHANGE_SEARCH_RESULT_VIEW);
            yield put(setSearchResultView(viewType));
            yield* setSelectablesBasedonView(viewType, value);

        }
    } catch (error) {
        yield put(throwException("setSearchResultViewSaga" + error));
    }
}

function* setSelectablesBasedonView(viewType, value) {
    if (viewType === SearchResultStates.PRODUCT_SEARCH) { //clear both selected product and selected net price
        yield put(setSelectedProduct(null));
        yield put(setSelectedAttachments(null));
        yield put(setSelectedPricingAdjustments(null));
    }
    if (viewType === SearchResultStates.PRODUCT_DETAILS) {
        //clear  selected net price and set 
        yield put(setSelectedAttachments(null));
        yield put(setSelectedPricingAdjustments(null));
        if (value) {
            const results = yield select((state) => searchResultInfoItemSelector(state, PageTitles.DIGITAL_PRODUCT_VIEWER, SearchResultConstants.RESULTS));
            let [product] = results.filter((item) => item.ItemKey === value);
            yield put(setSelectedProduct(product));
        }
    }
    if (viewType === SearchResultStates.PRICING_ADJUSTMENTS && value)
        yield put(setSelectedPricingAdjustments(value));
    if (viewType === SearchResultStates.STRATEGIC_LINK && value)
        yield put(setSelectedAttachments(value));
}

function* searchSaga(searchRequest) {
    try {
        const config = yield ApplicationConfig.config;

        const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/v1/da/deals/getdeals${getDealViewerQueryString(searchRequest)}`, method: 'GET' } });
        
        if (response.status === 200) {
            const data = yield apply(response, response.json);
            return data;
        }
    } catch (error) {
        yield put(throwException("get Deals Saga error: " + error));
    }
}

function* getSoldToIfRequired(criteria) {
    let isSoldToReqd = yield select(isSoldToControlRequired);
    return isSoldToReqd ? criteria.SoldTo : (yield select(soldToDetailsSelector))[0];
}

const getAllCriteria = ({ SoldTo, OrderabilityEndDate, OrderabilityStartDate, ProductInformation, AgreementNumber }) => {
    return [].concat(
        criteriaMapper[criteriaConstants.SOLD_TO](SoldTo),
        criteriaMapper[criteriaConstants.ORDERABILITY_END_DATE](OrderabilityEndDate),
        criteriaMapper[criteriaConstants.ORDERABILITY_START_DATE](OrderabilityStartDate),
        criteriaMapper[criteriaConstants.PRODUCT_INFO](ProductInformation),
        criteriaMapper[criteriaConstants.AGREEMENT_NUMBER](AgreementNumber)
    );

}

const criteriaMapper = {
    [criteriaConstants.SOLD_TO]: (SoldTo) => ({ key: "SoldTo", value: SoldTo, displayText: SoldTo.CompanyNameAndId, displayKey: "Sold To" }),
    [criteriaConstants.ORDERABILITY_END_DATE]: (date) => ({ key: "OrderabilityEndDate", value: date, displayText: date.toLocaleDateString(), displayKey: "Orderability End Date" }),
    [criteriaConstants.ORDERABILITY_START_DATE]: (date) => ({ key: "OrderabilityStartDate", value: date, displayText: date.toLocaleDateString(), displayKey: "Orderability Start Date" }),
    [criteriaConstants.PRODUCT_INFO]: (info) => ({ key: "ProductInformation", value: info, displayText: info, displayKey: "Product Information" }),
    [criteriaConstants.AGREEMENT_NUMBER]: (agreement) => ({ key: "AgreementNumber", value: agreement, displayText: agreement, displayKey: "Agreement Details" })
}

const modelOAsearchResult = (searchResult) => {
    if (!searchResult) return [];
    var oaDataModel = [];
    searchResult.map((product) => {
        var netPriceDictionary = {};
        product.NetPriceSlabs.map((netPrice) => {
            let startDate = new Date(netPrice.OrderabilityStartDate);
            let endDate = new Date(netPrice.OrderabilityEndDate);
            const key = `${startDate.toLocaleDateString()}-${endDate.toLocaleDateString()}`;
            if (!netPriceDictionary[key])
                netPriceDictionary[key] = [netPrice];
            else
                netPriceDictionary[key].push(netPrice);
        });
        Object.entries(netPriceDictionary).map(([key, value]) => {
            oaDataModel.push({
                ItemKey: random6Digit(),
                OrderabilityStartDate: key.split('-')[0],
                OrderabilityEndDate: key.split('-')[1],
                ...product.ProductAttributes,
                NetPriceSlabs: value
            });
        });
    });

    return oaDataModel;
}


const updateMetaDataInfoInSearchData = (data, metaDataCallData) => {
    data.map((item) => {
        const [tempContentClassification] = metaDataCallData.ContentClassifications.filter((conclas) => conclas.ID === item.ContentClassification);
        item.ContentClassification = tempContentClassification ? `${item.ContentClassification} - ${tempContentClassification.Value}` : item.ContentClassification;
        const [tempContentType] = metaDataCallData.ContentTypes.filter((conclas) => conclas.ID === item.ContentType);
        item.ContentType = tempContentType ? `${item.ContentType} - ${tempContentType.Value}` : item.ContentType;
        const [tempOnlineContent] = metaDataCallData.OnlineContents.filter((conclas) => conclas.ID === item.OnlineContent);
        item.OnlineContent = tempOnlineContent ? `${item.OnlineContent} - ${tempOnlineContent.Value}` : item.OnlineContent;
    });
    return data;
}



const getDSBDataByOrderabilityDates = (searchResult) => {
    if (!searchResult) return [];
    var filteredData = [];
    searchResult.map((product) => {
        var orderabilityDatesDictionary = {};
        product.ProductAttributes.EndOfLife = product.ProductAttributes.EndOfLife ? (new Date(product.ProductAttributes.EndOfLife)).toLocaleDateString() : product.ProductAttributes.EndOfLife;
        product.NetPriceSlabs.map((netPrice) => {
            
            let startDate =  netPrice.OrderabilityStartDate.substring(0,10).split('-');
            let endDate = netPrice.OrderabilityEndDate.substring(0,10).split('-');
            let formatedStartDate = startDate[1] + '/' + startDate[2] + '/' + startDate[0];
            let formatedEndDate =  endDate[1] + '/' + endDate[2] + '/' + endDate[0];
            const key = `${formatedStartDate}*${formatedEndDate}`;
            if (!orderabilityDatesDictionary[key])
                orderabilityDatesDictionary[key] = [netPrice];
            else
                orderabilityDatesDictionary[key].push(netPrice);
        });
        Object.entries(orderabilityDatesDictionary).map(([key, value]) => {
            filteredData.push({
                ItemKey: random6Digit(),
                OrderabilityStartDate: key.split('*')[0],
                OrderabilityEndDate: key.split('*')[1],
                ...product.ProductAttributes,
                MOQDetails: product.MOQDetails,
                NetPriceSlabs: value                
            })
        })
    });
    return filteredData;
}

export function* downloadDealAttachmentFileSaga() {
    while (true) {
        try {

            let { fileToDownload } = yield take(DOWNLOAD_DEAL_ATTACHMENT_FILE);
            const requestData = JSON.stringify(fileToDownload.ID);
            const config = yield ApplicationConfig.config;

            const response  = yield call(InvokeUrl, {
                Config: {
                    url: `${config.web_api_path}/api/v1/da/deals/DownloadStrategicLinkDocument`,
                    method: 'POST',
                    data: requestData,
                    RequestHeaders: [
                        {
                            'Content-Type': 'application/json',
                            'Accept': 'application/octet-stream'
                        }
                    ],
                    SagaHandleResponse: { HandleResponse: false }
                }
            });
            if (response.status == 200) {

                const data = yield apply(response, response.blob);

                var blob = new Blob([data]);
                //IE workaround
                if (window.navigator.msSaveOrOpenBlob)
                    window.navigator.msSaveOrOpenBlob(blob, fileToDownload.FileDTO.FileName);
                else {
                    var link = document.createElement('a');
                    link.id = fileToDownload.FileDTO.FileName;
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileToDownload.FileDTO.FileName;
                    link.click();
                }
                yield put(showAlert({ type: AlertsTypes.SUCCESS, message: ["Document is downloaded successfully."] }));


            } else if (response.status == 500) {
                const data = yield apply(response, response.json);
                yield put(showAlert({ type: AlertsTypes.ERROR, message: [data] }));
            } else {
                yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Failed to download documents."] }));
            }
            // yield put(decrementPendingAPICalls()); 

        } catch (error) {
            console.log("downloadDocumentSaga error:" + error);
            yield put(showAlert({ type: AlertsTypes.ERROR, message: ["Internal Error Occurred, Failed to download documents."] }));
        }
    }
}
