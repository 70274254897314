import { createSelector } from 'reselect';

export const allSubscribersSelector = createSelector(
    state => state.app.get("allSubscribersData"),
    allSubscribersData => allSubscribersData ? allSubscribersData.toJS() : []
);

export const addSubscriberListSelector = createSelector(
    state => state.app.get("addSubscriberList"),
    addSubscriberList => addSubscriberList ? addSubscriberList.toJS() : []
);

export const removeSubscriberListSelector = createSelector(
    state => state.app.get("removeSubscriberList"),
    removeSubscriberList => removeSubscriberList ? removeSubscriberList.toJS() : []
);


export const masterSubscriberSelector = createSelector(
    state => state.app.get("masterSubscriberData"),
    masterSubscriberData => masterSubscriberData ? masterSubscriberData.toJS() : []
);