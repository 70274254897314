
import {SortType,sortValues, SearchResultMappers} from '@ec-oem/ec.oem.oa3.ui.common/components/Search';

const FilterAndSortFields = {
    CONTENT_TYPE: {
        Key: "ContentType",
        Title: "Content Type"
    },
    MIN_USER_AGE: {
        Key: "MinUserAge",
        Title: "Minimum User Age"
    }

}

const FilterCreationMapper = {
    [FilterAndSortFields.CONTENT_TYPE.Key]: (searchData) => {
        var contentTypeValues = {};
        searchData.map((product) => {
            if (!contentTypeValues[product.ContentType]) {
                contentTypeValues[product.ContentType] = {
                    value: product.ContentType,
                    text: product.ContentType,
                    count:1
                }
            }
            else{
                contentTypeValues[product.ContentType].count++;
            }
        });
        return {
            field: FilterAndSortFields.CONTENT_TYPE.Key,
            title: FilterAndSortFields.CONTENT_TYPE.Title,
            items: Object.values(contentTypeValues)
        };
    },
    [FilterAndSortFields.MIN_USER_AGE.Key]: (searchData) => {
        var minUserAgeValues = {};
        searchData.map((product) => {
            if (!minUserAgeValues[product.MinimumUserAge]) {
                minUserAgeValues[product.MinimumUserAge] = {
                    value: product.MinimumUserAge,
                    text: product.MinimumUserAge,
                    count:1
                }
            }
            else{
                minUserAgeValues[product.MinimumUserAge].count++;
            }
        });
        return {
            field: FilterAndSortFields.MIN_USER_AGE.Key,
            title: FilterAndSortFields.MIN_USER_AGE.Title,
            items: Object.values(minUserAgeValues)
        };
    }
}

const FilterationMapper = {
    [FilterAndSortFields.CONTENT_TYPE.Key]:(data,values)=> data.filter((item) => values.includes(item.ContentType)),
    [FilterAndSortFields.MIN_USER_AGE.Key]:(data,values)=>data.filter((item) => values.includes(item.MinimumUserAge))
}



const SortCreationMapper = {
    [FilterAndSortFields.CONTENT_TYPE.Key]: (data)=>{
        return{
                key: FilterAndSortFields.CONTENT_TYPE.Key,
                text: FilterAndSortFields.CONTENT_TYPE.Title,
                default: true,
                defaultSort: SortType.ASC
        }
    }

}

const SortingMapper = {
    [FilterAndSortFields.CONTENT_TYPE.Key]: (data, sortType) => data.sort((a, b) => sortValues(a.ContentType,b.ContentType,sortType)),
    [FilterAndSortFields.MIN_USER_AGE.Key]: (data, sortType) => data.sort((a, b) => sortValues(a.MinimumUserAge,b.MinimumUserAge,sortType)),
};

export const digitalSearchMappers =  {
    [SearchResultMappers.FILTER_MAPPER]: FilterCreationMapper,
    [SearchResultMappers.RESULT_FILTER_MAPPER]:FilterationMapper,
    [SearchResultMappers.SORT_MAPPER]: SortCreationMapper,
    [SearchResultMappers.RESULT_SORT_MAPPER]:SortingMapper
}


