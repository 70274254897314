import { connect } from 'react-redux';
import React from 'react';
import PricingInformation from '../../../PricingInformation';
import MOQInformation from './MOQDetails/MOQInformation';
import { selectedProductSelector } from '../../../../selectors';
import ProductAttributes from './ProductAttributes';

class ProductDetails extends React.PureComponent {
    render() {
        const { product } = this.props;
        if (!product) return null;
        const { MOQDetails, NetPriceSlabs, ...attributes} = product;
        return (
            <div className="product-details ms-slideRightIn10">
                <ProductAttributes attributes={attributes} />
                <PricingInformation slabs={NetPriceSlabs} />
                <MOQInformation moqDetails={MOQDetails} />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        product: selectedProductSelector(state)
    }
}

const mapDispatchToProps = (dispatch, ownProps) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails)