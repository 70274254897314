import { fromJS } from 'immutable';
import { createReducer } from '@ec-oem/ec.oem.oa3.ui.core/utility/createReducer';
import {
  SET_STORE_PRODUCT_WIZARD_DATA, SET_PRODUCT_DETAILS
  , SET_BIG_CAT_PRODUCT_DETAILS, SET_LAUNCH_READINESS_META_DATA
  , SET_LAUNCH_READINESS_PD, SET_BIG_CAT_CHILD_PRODUCT_DETAILS,
  SET_STORE_PRODUCT_WIZARD_COMPLETION,
  SET_PFAM_VALIDATION,
  RESET_PFAMS,
  IGNORE_PFAM,
  RESET_IGNORE_PFAMS,SET_PARTNUMBER
} from './CreateStoreProduct.actions';

export const wizardData = createReducer(null, {
  [SET_STORE_PRODUCT_WIZARD_DATA](state, { wizardData }) {
    return fromJS(wizardData);
  },
});

export const productDetails = createReducer(null, {
  [SET_PRODUCT_DETAILS](state, { productDetails }) {
    return fromJS(productDetails);
  },
});


export const bigCatPD = createReducer(null, {
  [SET_BIG_CAT_PRODUCT_DETAILS](state, { bigCatPD }) {
    return fromJS(bigCatPD);
  },
});

export const bigCatChildPD = createReducer(null, {
  [SET_BIG_CAT_CHILD_PRODUCT_DETAILS](state, { bigCatChildPD }) {
    return fromJS(bigCatChildPD);
  },
});

export const launchReadinessPD = createReducer(null, {
  [SET_LAUNCH_READINESS_PD](state, { launchReadinessPD }) {
    return fromJS(launchReadinessPD);
  },
});

export const launchReadinessMetaData = createReducer(null, {
  [SET_LAUNCH_READINESS_META_DATA](state, { launchReadinessMetaData }) {
    return fromJS(launchReadinessMetaData);
  },
});



export const spWizardCompletion = createReducer(null, {
  [SET_STORE_PRODUCT_WIZARD_COMPLETION](state, { spWizardCompletion }) {
    return fromJS(spWizardCompletion);
  }
});


export const PFAMValidations = createReducer(null, {
  [SET_PFAM_VALIDATION](state, { productFamily, isValid }) {
    if (!state) return fromJS({ [productFamily]: isValid });
    state = state.set(productFamily, isValid);
    return fromJS(state);
  },
  [RESET_PFAMS](state) {
    return fromJS(null);
  }
});

export const ignorePFAM = createReducer(null, {
  [IGNORE_PFAM](state, { productFamily, ignore }) {
    return fromJS({ [productFamily]: ignore });

  },
  [RESET_IGNORE_PFAMS](state) {
    return fromJS(null);
  }
});

export const partNumber = createReducer(null, {
  [SET_PARTNUMBER](state, { partNumber }) {
    return fromJS(partNumber);
  },
});