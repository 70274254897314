import React from 'react';
import { injectIntl } from 'react-intl';
import { Announced, Text } from 'office-ui-fabric-react';
import MOQCard from './MOQCard';
import { CommonAccordion } from '../../../../../../../common/components';

const MOQInformation = (props) => {
    return (
        <CommonAccordion isOpen={false} headertext={props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.moqinformation', defaultMessage: "Min. Order Quantity Information" })}>
            {(!props.moqDetails || props.moqDetails.length === 0) && <React.Fragment>
                <Announced id={`moqannounced`} message={props.intl.formatMessage({ id: 'digitalproductviewer.moqdetail.noresults', defaultMessage: " No Min. Order Quantity Information" })} />
                <Text className={' noresults center-align'}variant={"mediumPlus"} nowrap block>
                    {props.intl.formatMessage({ id: 'digitalproductviewer.moqdetail.noresults', defaultMessage: " No Min. Order Quantity Information" })}
                </Text>

            </React.Fragment>}
            <React.Fragment>
            <div className="ms-Grid moqdetails">
                <div className="ms-Grid-row moqcards">
                    {props.moqDetails.map(moq=><MOQCard {...moq}/>)}
                </div>
                </div>
            </React.Fragment>

        </CommonAccordion>
    );
};




export default injectIntl(MOQInformation);