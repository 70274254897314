import { call, put, all, apply, take, select } from 'redux-saga/effects';
import { ApplicationConfig, InvokeUrl } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import { showAlert } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { AlertsTypes, AlertsFeature } from '@ec-oem/ec.oem.oa3.ui.core/constants';
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components';
import { selectedSoldToSelector, isSoldToControlRequired, soldToDetailsSelector } from '../../selectors';
import { GET_AGREEMENT_DETAILS, setAgreementDetails, setFormStatus } from '../../actions';
import { Statuses } from '../../constants';

export function* getAgreementsSaga() {
    while (true) {
        try {
            yield take(GET_AGREEMENT_DETAILS);
            const isSoldToRequired = yield select(isSoldToControlRequired);
            let soldToDetail = null;
            if (isSoldToRequired) {
                soldToDetail = yield select(selectedSoldToSelector);
            } else {
                const soldtoList = yield select(soldToDetailsSelector);
                if (soldtoList && soldtoList.length === 1) {
                    soldToDetail = soldtoList[0];
                }
            }

            if (soldToDetail) {
                const config = yield ApplicationConfig.config;
            //   //  const agreements = Mockagreements;
            //     yield put(setAgreementDetails(agreements));
            //     if (agreements && agreements.length > 0)
            //         yield put(setFormStatus(Statuses.SUCCESS));
            //     else {
            //         yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.STORE_PRODUCT_ALERTS, message: ["Cannot use this page as no Sold to company is associated with this account"] }));
            //         yield put(setFormStatus(Statuses.ERROR));
            //     }

                 const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/v1/da/deals/getagreements?SoldTo=${soldToDetail.CompanyId}`, method: 'GET' } });
         
                  if (response.status === 200) {
                     const agreements = yield apply(response, response.json);
                    yield put(setAgreementDetails(agreements));
                    if (agreements && agreements.length > 0)
                        yield put(setFormStatus(Statuses.SUCCESS));
                    else {
                        yield put(showAlert({ type: AlertsTypes.ERROR, feature: AlertsFeature.STORE_PRODUCT_ALERTS, message: ["Cannot use this page as no Sold to company is associated with this account"] }));
                        yield put(setFormStatus(Statuses.ERROR));
                    }
                }
                else {
                    yield put(setFormStatus(Statuses.FAILURE));
                }
            }
        }
        catch (error) {
            yield put(throwException("getAgreementsSaga" + error));
        }
    }
 }

// const Mockagreements = [
//     {
//         "AgreementNumber": "5000045762",
//         "Description": "",
//         "EndDate": "2020-03-31T00:00:00",
//         "StartDate": "2019-01-25T00:00:00"
//     },
//     {
//         "AgreementNumber": "5000045762",
//         "Description": "",
//         "EndDate": "2020-03-31T00:00:00",
//         "StartDate": "2019-01-25T00:00:00"
//     },
//     {
//         "AgreementNumber": "5000045762",
//         "Description": "",
//         "EndDate": "2020-03-31T00:00:00",
//         "StartDate": "2019-01-25T00:00:00"
//     },
//     {
//         "AgreementNumber": "5000045762",
//         "Description": "",
//         "EndDate": "2020-03-31T00:00:00",
//         "StartDate": "2019-01-25T00:00:00"
//     },
//     {
//         "AgreementNumber": "5000045762",
//         "Description": "",
//         "EndDate": "2020-03-31T00:00:00",
//         "StartDate": "2019-01-25T00:00:00"
//     },
//     {
//         "AgreementNumber": "5000045763",
//         "Description": "",
//         "EndDate": "2020-03-31T00:00:00",
//         "StartDate": "2019-01-25T00:00:00"
//     },
//     {
//         "AgreementNumber": "5000045766",
//         "Description": "",
//         "EndDate": "2019-05-30T00:00:00",
//         "StartDate": "2019-05-20T00:00:00"
//     },
//     {
//         "AgreementNumber": "5000045768",
//         "Description": "",
//         "EndDate": "2020-05-19T00:00:00",
//         "StartDate": "2019-05-19T00:00:00"
//     },
//     {
//         "AgreementNumber": "5000045792",
//         "Description": "",
//         "EndDate": "2019-05-31T00:00:00",
//         "StartDate": "2019-05-22T00:00:00"
//     },
//     {
//         "AgreementNumber": "5200002733",
//         "Description": "Windows POAN FY19",
//         "EndDate": "9999-12-31T00:00:00",
//         "StartDate": "2018-08-01T00:00:00"
//     },
//     {
//         "AgreementNumber": "5200002735",
//         "Description": "Office Apps DSB POAN FY19",
//         "EndDate": "9999-12-31T00:00:00",
//         "StartDate": "2018-08-01T00:00:00"
//     }
// ]