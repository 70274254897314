/**
*
* DigitalAttachProductViewer
*
*/

import React from 'react';
import { injectIntl } from 'react-intl';
import ReactAI from 'react-appinsights';
import { Container, PageHeader } from '../../../../common/components';
import { AlertsFeature } from '@ec-oem/ec.oem.oa3.ui.core/constants';
import { FeatureAlerts } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { PageTitles,Titles } from '../../../../constants';
import DigitalAttachProductSearchCriteria from './Criteria/DigitalAttachProductSearchCriteria';
import DigitalAttachProductSearchResultContainer from './DigitalAttachProductSearchResult/DigitalAttachProductSearchResultContainer';
import { connect } from 'react-redux';
import { destroyDigitalAttach, setTitleFlag } from '../../actions';
import { docLinkSelector } from '../../../../selectors/navigationMenuSelector';


export class DigitalAttachProductViewer extends React.PureComponent {

  componentWillMount() {
    document.title = Titles.DIGITAL_PRODUCT_VIEWER;
    this.props.setTitleFlag(document.title);
  }
  componentWillUnmount() {
    this.props.destroy();
  }
  render() {
    return (
      <div className="search-pr-section container">
            <div className="ms-Grid-row mrg-top10 page-title-section">
                <PageHeader 
                pageHeaderClassName={"ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 "}
                HeaderTitle={this.props.intl.formatMessage({ id: 'digitalStoreProduct.title', defaultMessage: 'Digital Product Viewer' })}
                isTooltipRequired={true}
                HeaderTooltipContent={this.props.intl.formatMessage({
                    id: "pageTitles.DAPTooltip",
                    defaultMessage:                    
                    <ul>
                        <li>Use this page to search for Digital Attach products information and pricing attribution.</li>
                        <li>Search by multiple criteria: Organization name/number, product details, orderability start date/end date.</li>
                        <li>To learn more about expiration days for each DA product, please refer to Product Terms Documents</li>
                        <li>For more information, please see <a target="_blank" href={this.props.docLink}>Digital Product Viewer (DA) Help Documentation</a> </li>
                    </ul>
                     
                  })} /></div>
        <FeatureAlerts timeOut={5000} feature={AlertsFeature.STORE_PRODUCT_ALERTS} />
        <DigitalAttachProductSearchCriteria />
        <DigitalAttachProductSearchResultContainer />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setTitleFlag(digitalProductViewerTitle) {
    dispatch(setTitleFlag(digitalProductViewerTitle))
  },
  destroy() {
    dispatch(destroyDigitalAttach())
  }
})

const mapStateToProps = (state) => {
	return {
		docLink : docLinkSelector(state),
	};
};

export default ReactAI.withTracking(injectIntl(connect(mapStateToProps, mapDispatchToProps)(DigitalAttachProductViewer)), PageTitles.DIGITAL_PRODUCT_VIEWER);

