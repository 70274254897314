import { criteriaConstants, DealViewerType } from "../constants";
import {getFormattedDate} from './helperFunction';

export const getDealViewerQueryString = (searchRequest) => {
    let querystring = "?";
    if (searchRequest.SoldTo) querystring = querystring.concat(`CustomerID=${searchRequest.SoldTo}`);
    if (searchRequest.DealViewerType) querystring = querystring.concat(`&DealViewerType=${searchRequest.DealViewerType}`);
    if (searchRequest.StartDate) querystring = querystring.concat(`&OrderabilityStartDate=${getFormattedDate(searchRequest.StartDate)}`);
    if (searchRequest.EndDate) querystring = querystring.concat(`&OrderabilityEndDate=${getFormattedDate(searchRequest.EndDate)}`);
    if (searchRequest.AgreementNumber) querystring = querystring.concat(`&AgreementNumber=${searchRequest.AgreementNumber}`);
    if (searchRequest.SearchFilter) querystring = querystring.concat(`&SearchFilter=${searchRequest.SearchFilter}`);
    return querystring;
};

export const prepareSearchRequest = (allCriteria, dealViewerType) => {
    let request = allCriteria.reduce((searchRequest, { key, value }) => {
        return criteriaToApiObjectMapper[key](searchRequest, value);
    }, {});
    
    if (dealViewerType === DealViewerType.DSBDealViewer)
    {
        request.DealViewerType = DealViewerType.DSBDealViewer;
    }
    else{
        request.DealViewerType = DealViewerType.OADealViewer;
    }
    
    return request;
};
const criteriaToApiObjectMapper = {

    [criteriaConstants.SOLD_TO]: (searchRequest, value) => { searchRequest.SoldTo = value.CompanyId; return searchRequest },
   
    [criteriaConstants.ORDERABILITY_START_DATE]: (searchRequest, value) => { searchRequest.StartDate = value; return searchRequest; },
   
    [criteriaConstants.ORDERABILITY_END_DATE]: (searchRequest, value) => { searchRequest.EndDate = value; return searchRequest; },
   
    [criteriaConstants.PRODUCT_INFO]: (searchRequest, value) => { searchRequest.SearchFilter = value; return searchRequest },

    [criteriaConstants.AGREEMENT_NUMBER]: (searchRequest, value) => { searchRequest.AgreementNumber = value; return searchRequest }   
   }