import React, { Component } from 'react';
import { PrimaryButton, Label, DefaultButton } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { bigCatPDSelector, launchReadinessPDSelector } from '../CreateStoreProduct.selectors';
import '../../CreateStoreProduct/CreateStoreProduct.scss';
import { injectIntl } from 'react-intl';
import {
    WizardStepHeader, WizardStep, WizardStepBody, WizardStepFooter
} from "@ec-oem/ec.oem.oa3.ui.core/components";
import '../CreateStoreProduct.scss';
import BigCatDetailsFields from './BigCatDetailsFields';

class BigCatDetails extends Component {
    render() {
        const { bigcatdetails } = this.props;
        return (
            <React.Fragment>
                {bigcatdetails &&
                    <WizardStep>
                        <WizardStepHeader title={this.props.intl.formatMessage({ id: 'createStoreProduct.BigCatDetails.Title', defaultMessage: 'Product Details from Big Cat (Read Only)' })} />
                        <WizardStepBody>
                            <BigCatDetailsFields {...bigcatdetails} />
                        </WizardStepBody>
                        <WizardStepFooter>
                            <DefaultButton
                                className="iconRight"
                                primary={true}
                                data-automation-id="test"
                                disabled={false}
                                text={this.props.intl.formatMessage({ id: 'app.next', defaultMessage: "Next" })}
                                iconProps={{ iconName: 'ChevronRightSmall' }}
                                onClick={() => { this.props.onSubmitClick() }}
                                allowDisabledFocus={true} />
                        </WizardStepFooter>
                    </WizardStep>
                }
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        bigcatdetails: bigCatPDSelector(state),
        launchReadinessPD: launchReadinessPDSelector(state)
    }
}
export default injectIntl(connect(mapStateToProps, null)(BigCatDetails));