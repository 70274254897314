/**
*
* OAProductViewer
*
*/

import React from 'react';
import { injectIntl } from 'react-intl';
import ReactAI from 'react-appinsights';
import { Container, PageHeader } from '../../../../common/components';
import { AlertsFeature } from '@ec-oem/ec.oem.oa3.ui.core/constants';
import { FeatureAlerts } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { PageTitles ,Titles} from '../../../../constants';
import { OAProductSearchCriteria } from './Criteria';
import { OAProductSearchResultContainer } from './OAProductSearchResult';
import { destroyDigitalAttach, setTitleFlag } from '../../actions';
import {connect} from 'react-redux';



export class OAProductViewer extends React.PureComponent {

    componentWillMount() {
        document.title = Titles.OA_PRODUCT_VIEWER;
        this.props.setTitleFlag(document.title);
    }
    componentWillUnmount(){
        this.props.destroy();

    }
    render() {
        return (
            <div className="search-pr-section container">
            <div className="ms-Grid-row mrg-top10 page-title-section">
                <PageHeader 
                pageHeaderClassName={"ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 "}
                HeaderTitle={this.props.intl.formatMessage({ id: 'OAProductViewer.title', defaultMessage: 'OA Product Viewer' })}
                /></div>
                <FeatureAlerts timeOut={5000} feature={AlertsFeature.STORE_PRODUCT_ALERTS} />
                <OAProductSearchCriteria />
                <OAProductSearchResultContainer/>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setTitleFlag(digitalProductViewerTitle){
        dispatch(setTitleFlag(digitalProductViewerTitle))
    },
    destroy(){
      dispatch(destroyDigitalAttach())
    }
  })

export default ReactAI.withTracking(injectIntl(connect(null,mapDispatchToProps)(OAProductViewer)), PageTitles.DIGITAL_PRODUCT_VIEWER);

