import React from "react";
import './AutoSuggestionCard.scss';
import { Card, CardBody } from "@ec-oem/ec.oem.oa3.ui.core/components";

const AutoSuggestionCardOld = (props) => {
    return (
        < div className="auto-suggestion-card" >
            < Card >
                <CardBody>
                    <h5 className="auto-card-title"> {props.Name}</h5>
                    <h6 className="auto-card-body">
                        <span>{props.Email}</span>
                    </h6>
                </CardBody>
            </Card >
        </div >
    );
}

export default AutoSuggestionCardOld;
