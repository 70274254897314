import { put, take, select } from 'redux-saga/effects';
import { StoreProductWizardSteps } from './StoreProductWizardSteps';
import {
    GET_STORE_PRODUCT_WIZARD_DATA, setStoreProductWizardData,
    SET_SP_WIZARD_NEXT, setSPWizardCurrentStep, SET_SP_WIZARD_PREVIOUS, SET_SP_WIZARD_STEP
} from './CreateStoreProduct.actions';
import { wizardDataSelector } from './CreateStoreProduct.selectors';
import { WizardStatuses } from '../../../../constants';
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components/Logger';

export function* getStoreProductWizardSteps() {
    while (true) {
        try {
            yield take(GET_STORE_PRODUCT_WIZARD_DATA);
            if (!StoreProductWizardSteps || !StoreProductWizardSteps.length)
                break;
            StoreProductWizardSteps.sort((a, b) => a.order - b.order);
            let wizardData = { steps: StoreProductWizardSteps, currentStep: StoreProductWizardSteps[0] };
            yield put(setStoreProductWizardData(wizardData));
        } catch (error) {
            yield put(throwException("getStoreProductWizardSteps error: " + error));
        }
    }
}

export function* WizardNextSaga() {
    while (true) {
        try {
            yield take(SET_SP_WIZARD_NEXT);
            const { steps, currentStep } = yield select(wizardDataSelector);
            let nextStep = null;
            steps.map(step => {
                if (step.order <= currentStep.order)
                    step.status = WizardStatuses.COMPLETED;
                else if (step.order === currentStep.order + 1) {
                    step.status = WizardStatuses.IN_PROGRESS;
                    nextStep = step;
                }
                return null;
            })

            yield put(setStoreProductWizardData({ steps: steps, currentStep: nextStep }));
        } catch (error) {
            yield put(throwException("WizardNextSaga error: " + error));
        }
    }
}

export function* setWizardStepSaga() {
    while (true) {
        try {
            let nextstep = yield take(SET_SP_WIZARD_STEP);
            let wizardSteps = yield select(wizardDataSelector);
            let lastStep = wizardSteps.currentStep;
            let canprogress = true;
            if (nextstep && nextstep.status !== WizardStatuses.IN_PROGRESS && nextstep.status !== WizardStatuses.DISABLED) {

                wizardSteps.steps.map(steps => {
                  
                    if (steps.order < nextstep.stepData.order && !steps.isStateValid) {
                        canprogress = false;
                    }
                    return steps;
                });
                if (canprogress) {
                wizardSteps.steps.map(steps => {
                  
                if (steps.order === nextstep.stepData.order) {
                    steps.status = WizardStatuses.IN_PROGRESS;
                }
                else if (steps.order === lastStep.order) {
                    steps.status = lastStep.isStateValid ? WizardStatuses.COMPLETED : WizardStatuses.NOT_STARTED;
                }
            });
                  
                    yield put(setStoreProductWizardData({ steps: wizardSteps.steps, currentStep: { ...nextstep.stepData, status: WizardStatuses.IN_PROGRESS } }));
                }

            }


        } catch (error) {
            yield put(throwException("setWizardStepSaga error: " + error));
        }
    }
}

export function* WizardPreviousSaga() {
    while (true) {
        try {
            yield take(SET_SP_WIZARD_PREVIOUS);
            const { steps, currentStep } = yield select(wizardDataSelector);
            let prevStep = null;
            steps.map(step => {
                if (step.order == currentStep.order && !currentStep.isStateValid)
                    step.status = WizardStatuses.NOT_STARTED;
                else if (step.order == currentStep.order && currentStep.isStateValid)
                    step.status = WizardStatuses.COMPLETED;
                else if (step.order === currentStep.order - 1) {
                    step.status = WizardStatuses.IN_PROGRESS;
                    prevStep = step;
                }
                return null;
            })

            yield put(setStoreProductWizardData({ steps: steps, currentStep: prevStep }));
        } catch (error) {
            yield put(throwException("WizardPreviousSaga error: " + error));
        }
    }
}


