import React, { Component, Suspense } from 'react';
import { connect } from "react-redux";
import './App.scss';
import { initializeApp } from '../actions';
import Container from '@ec-oem/ec.oem.oa3.ui.core/components/Shared/Container';
import NotAuthorized from '@ec-oem/ec.oem.oa3.ui.core/components/NotAuthorized/NotAuthorized';
import {
  Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import history from '../utility/history';
import { createTheme, ProgressIndicator } from 'office-ui-fabric-react';
import headerThemes from '../../resources/styles/themes/themes.json';

import { Loader, FeatureAlerts, NotFound, SignOut } from '@ec-oem/ec.oem.oa3.ui.core/components'
import { AlertsFeature } from "@ec-oem/ec.oem.oa3.ui.core/constants";
import { isSpinnerOn } from "@ec-oem/ec.oem.oa3.ui.core/selectors";
import { Header } from "@ec-oem/ec.oem.oa3.ui.common/components/Header";
import { Footer } from "@ec-oem/ec.oem.oa3.ui.common/components/Footer";
import { RedirectRoute, AuthorizedRoute } from '@ec-oem/ec.oem.oa3.ui.common/components';
import { RedirectPath } from '../constants';
import ManageStoreProduct from './StoreProductManagement/components/ManageStoreProduct';
import Home from './Home';
import { rolesMappingSelector } from '@ec-oem/ec.oem.oa3.ui.common/selectors';
import DigitalProductViewer from './DigitalProductViewer/components/DigitalProductViewer';
import ReactDOM from 'react-dom';
import {TabIndexes} from '../constants/TabIndexConstants';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: "default"
    }
  }
  componentWillMount() {
    this.props.initializeApp();

  }

  getTheme = (themeName) => {
    let theme = {};
    theme.palette = themeName && headerThemes && headerThemes[themeName];
    return createTheme(theme);
  }
  setTheme = (theme) => {
    this.setState({ theme: theme });
  }
  getDisabledClass = () => {
    return this.props.isLoading ? "divoverlay" : "";
  }
  skipMain = (e) => {
    e.preventDefault();
    let containerDiv = ReactDOM.findDOMNode(this.refs.containerDiv);

    if (containerDiv != null) {
      let [containerLst] = containerDiv.getElementsByClassName('container');
      containerLst.tabIndex = TabIndexes.InOrder;
      containerLst.focus();
    }
  }
  render() {
    const {authorizedRoles} = this.props;
    if(!authorizedRoles)
      return null;
    return (
      <Router history={history}>

        <React.Fragment>
          <div>
            <a href="#mainContent" className="skip" onClick={this.skipMain}>Skip to content</a>
            <Header navigationTheme="black" history={history} />
            <Container><FeatureAlerts timeOut={5000} feature={AlertsFeature.GLOBAL_ALERTS} /></Container>
            <Loader />
            <Suspense fallback={<ProgressIndicator />}>
              <main id="mainContent" className={"main-content " + this.getDisabledClass()} ref="containerDiv">
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/StoreProduct" component={ManageStoreProduct} />
                  <AuthorizedRoute path="/DigitalProductViewer" component={DigitalProductViewer} authorizedRoles={authorizedRoles.PageRoleMapping.DigitalProductList}/>
                  <Route exact path="/NotFound" component={NotFound} />
                  <Route exact path="/SignOut" component={SignOut} />
                  <Route path={RedirectPath} component={RedirectRoute} />
                  <Route exact path='/NotAuthorized' component={NotAuthorized} />
                  <Redirect to="/NotFound" />
                </Switch>
              </main>
            </Suspense>
            <Footer theme="black" />
          </div>
        </React.Fragment>
      </Router>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  initializeApp() {
    dispatch(initializeApp());
  }

});



const mapStateToProps = (state) => {
  return {
    isLoading: isSpinnerOn(state),
    authorizedRoles: rolesMappingSelector(state)
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(App);
