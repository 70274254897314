
export const SPModes = {
    EDIT_DRAFT: "EDIT_DRAFT",
    EDIT_ACTIVE: "EDIT_ACTIVE",
    INVALID: "INVALID"
};


export const SPStatus = {
    DRAFT: "Draft",
    ACTIVE: "Active",
    NEW: "NEW"
}

export const PD = {
    PTK: "PTK",
    NON: "NON"
}

export const Options = [
    { key: true, text: 'Yes', value: true },
    { key: false, text: 'No', value: false }
]

export const ModalNames = {
    PFAM: "PFAM"
}

export const CREATEDDATE = "CreatedDateUTC"


export const SearchRequestParams = {
    FILTERS: "Filters",
    SEARCH_CRITERIA: "SearchCriteria",
    PAGE_NO: "PageNo",
    PAGE_SIZE: "PageSize",
    SORT_ORDER: "SortOrder"
}
