import React, { Component } from 'react';
import ReactAI from 'react-appinsights';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PageTitles } from '../../../../constants';
import { bindActionCreators } from 'redux';
import {
    getStoreProductSearchResult, destroySearchStoreProduct,
    saveSearchStoreProductCriteria, setSubscriberStoreProduct,
    removeUserSubscription, getUserSubscribeStatus
} from './SearchStoreProduct.actions';
import {
    searchResultsSelector, searchCriteriaSelector, userSubscribeStatusSelector
} from './SearchStoreProduct.selectors';
import { AlertsFeature } from "@ec-oem/ec.oem.oa3.ui.core/constants";
import { setPageTitle } from '../../../../common/services/PageTitle';
import { FeatureAlerts } from "@ec-oem/ec.oem.oa3.ui.core/components";
import { SearchResult, SearchDefaultSelectionTypes, SearchResultMappers } from '@ec-oem/ec.oem.oa3.ui.common/components/Search';
import SearchStoreProductCard from './SearchStoreProductCard';
import SearchStoreProductCriteria from './SearchStoreProductCriteria';
import './SearchStoreProduct.scss';
import { TooltipHost, Toggle } from 'office-ui-fabric-react';
import { resetSearchSelection } from '@ec-oem/ec.oem.oa3.ui.common/components';
import { SearchStoreProductSortMapper } from './SearchStoreProductMapper';
import {setSearchSelectedFilters} from '@ec-oem/ec.oem.oa3.ui.common/actions';


const searchStoreMappers = {
    [SearchResultMappers.SORT_MAPPER]: SearchStoreProductSortMapper
}

const filerFields = {
    "Status": "Status"
}

const initialPageSize = 12;

class SearchStoreProduct extends Component {

    constructor(props) {
        super(props);
        if (this.props.initialState) {
            this.state = this.props.initialState;
        }

        let searchCriteria = null;
        let selected = this.getDefaultSelectionState(null);

        this.state = {
            searchCriteria: searchCriteria,
            selected: selected
        }
    }

    componentWillMount() {
        let selected = this.getDefaultSelectionState();
        this.setState({
            selected: selected
        });

        this.props.actions.getStoreProductSearchResult(this.getRequestParams(this.state.searchCriteria, selected));
        this.props.actions.getUserSubscribeStatus();
    }

    componentWillUnmount() {
        this.props.actions.setSearchSelectedFilters("SearchStoreProduct",null);
        this.props.actions.destroySearchStoreProduct();
    }

    componentDidMount() {
        setPageTitle(this.props.intl.formatMessage({ id: 'pagetitle.searchStoreProduct' }));
    }

    getSearchInputs = (searchCriteria) => {
        let searchInputs = [];

        if (searchCriteria) {
            let input = {
                label: this.props.intl.formatMessage({ id: 'searchStoreProduct.searchTxt.name', defaultMessage: 'Digital Attach Products Search' })
            }
            input.value = searchCriteria;
            searchInputs.push(input);
        }

        return searchInputs;
    }

    getDefaultSelectionState = (filters) => {
        let SearchResultDefaultSelection = this.getDefaultSelections();
        return {
            page: SearchResultDefaultSelection[SearchDefaultSelectionTypes.PAGING_OPTION].page,
            pageSize: SearchResultDefaultSelection[SearchDefaultSelectionTypes.PAGING_OPTION].pageSize,
            sort: "desc",
            sortBy: "crtDate",
            filters: filters ? filters : []
        };
    }

    handleSearch = (searchCriteria) => {
        let selected = this.getDefaultSelectionState();
        this.setState({
            searchCriteria: searchCriteria,
            selected: selected
        });
        this.props.actions.resetSearchSelection("SearchStoreProduct", null);
        this.props.actions.getStoreProductSearchResult(this.getRequestParams(searchCriteria, selected));
    }

    handleChange = (selected) => {
        this.setState({
            selected: selected
        })
        this.props.actions.getStoreProductSearchResult(this.getRequestParams(this.state.searchCriteria, selected));
    }

    getFilterData = (filterData) => {
        var filters = [];
        Object.keys(filterData).forEach(filterKey => {
            let filterInfo = {
                field: filterKey,
                title: filerFields[filterKey],
                items: []
            };

            filterData[filterKey] && filterData[filterKey].forEach(item => {
                if (item.Text != "PendingStorePartNumber") {
                    let filterItem = {
                        value: item.Value,
                        text: item.Text,
                        count: item.Count,
                        isSelected: false
                    }
                    filterInfo.items.push(filterItem);
                }
            });
            filters.push(filterInfo)
        });
        return filters;
    }

    getRequestParams = (searchCriteria, selected) => {
        let request = {}

        if (searchCriteria) {
            request.SearchCriteria = searchCriteria;
        }
        if (selected) {
            request.PageNo = selected.page;
            request.PageSize = selected.pageSize;
            request.SortOrder = selected.sort ? selected.sort == "asc" ? 1 : 2 : 1;

            request.Filters = [];
            if (selected.filters.length > 0) {
                selected.filters.forEach(filter => {
                    let values = [];
                    filter.items.forEach(item => {
                        values.push(item.value);
                    });
                    request.Filters[filter.field] = values;
                });
            }
        }
        return request;
    }
    onItemClick = (item) => {
        this.props.actions.saveSearchStoreProductCriteria(this.state);
    }
    subscribeClick = (event, checked) => {
        if (checked) {
            this.props.actions.setSubscriberStoreProduct();
        } else {
            this.props.actions.removeUserSubscription();
        }
    }

    getDefaultSelections = () => {
        return {
            [SearchDefaultSelectionTypes.PAGING_OPTION]: {
                page: 1,
                pageSize: initialPageSize,
                maxPageSize: 24
            },
            [SearchDefaultSelectionTypes.SELECTED_FILTERS]: null,
            [SearchDefaultSelectionTypes.SELECTED_ITEMS]: null,
            [SearchDefaultSelectionTypes.SORT_OPTION]: null
        }
    }



    render() {
        const { searchResults, userSubscribeStatus } = this.props;
        return (
            <div className="container search-storeProduct">
                <div className="ms-Grid-row">
                    <div className="page-header ms-Grid-col">
                        {this.props.intl.formatMessage({ id: 'searchStoreProduct.title', defaultMessage: 'Manage Digital Attach Products' })}
                    </div>
                    <div className="ms-Grid-col icon-Alert">
                        <TooltipHost content="Alert me on new product launches" >
                            <Toggle
                                onText={this.props.intl.formatMessage({ id: 'searchStoreProduct.toggle.subscribed', defaultMessage: 'Subscribed' })}
                                offText={this.props.intl.formatMessage({ id: 'searchStoreProduct.toggle.unsubscribed', defaultMessage: 'Not Subscribed' })}
                                onChange={this.subscribeClick}
                                checked={userSubscribeStatus}

                            />
                        </TooltipHost>
                    </div>
                </div>
                <FeatureAlerts timeOut={5000} feature={AlertsFeature.STORE_PRODUCT_ALERTS} />

                <div className="ms-Grid-row search-criteria">
                    <SearchStoreProductCriteria handleSearch={this.handleSearch} />
                </div>
                <div className="search-storeProduct-result">
                    {
                        this.props.searchResults && this.props.searchResults.Items &&
                        <SearchResult
                            searchName="SearchStoreProduct"
                            searchInputs={this.getSearchInputs(this.state.searchCriteria)}
                            items={this.props.searchResults.Items}
                            onItemClick={this.onItemClick}
                            totalItemsCount={this.props.searchResults.TotalItems}
                            filters={this.getFilterData(this.props.searchResults.FilterDetail)}
                            selected={this.state.selected}
                            ResultIteratorComponent={SearchStoreProductCard}
                            handleChange={(selected) => this.handleChange(selected)}
                            defaultSelections={this.getDefaultSelections()}
                            mappers={searchStoreMappers}

                        >
                        </SearchResult>
                    }
                </div>

            </div>
        );
    }
}

SearchStoreProduct.propTypes = {

};


const mapStateToProps = (state) => {
    return {
        searchResults: searchResultsSelector(state),
        searchCriteria: searchCriteriaSelector(state),
        userSubscribeStatus: userSubscribeStatusSelector(state)
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        getStoreProductSearchResult, destroySearchStoreProduct,
        saveSearchStoreProductCriteria, setSubscriberStoreProduct,
        removeUserSubscription, getUserSubscribeStatus,
        resetSearchSelection,setSearchSelectedFilters
    }, dispatch)
})


export default ReactAI.withTracking(injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchStoreProduct)), PageTitles.SEARCH_STORE_PRODUCT);