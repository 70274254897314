import React from 'react';
import { Container, PageHeader } from '../common/components';
import { injectIntl } from 'react-intl';
import { PageTitles } from '../constants';
import ReactAI from 'react-appinsights';
import { isAuthorizedUserSelector } from '@ec-oem/ec.oem.oa3.ui.common/selectors';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';



const Home = (props) => {
    return (
       props.isAuthorizedUser==false ?  <Redirect to="/NotAuthorized" /> :
       <Container>
       <PageHeader className="home-title">
       {props.intl.formatMessage({ id: 'home.title', defaultMessage: 'Please use the navigation menu to use the portal' })}
       </PageHeader>
   </Container> 
    );
};



const mapStateToProps = (state) => {
    return {
        isAuthorizedUser:isAuthorizedUserSelector(state)
    }
  }

export default  ReactAI.withTracking(injectIntl(connect(mapStateToProps)(Home)),PageTitles.HOME);