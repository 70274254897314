/**
* ManageStoreProduct
**/

import React, { Fragment } from 'react';
import { Switch } from "react-router-dom";

import { AuthorizedRoute } from '@ec-oem/ec.oem.oa3.ui.common/components';
import { SearchStoreProduct } from './SearchStoreProduct';
import CreateStoreProduct from './CreateStoreProduct/CreateStoreProduct';
import { ViewStoreProduct } from './ViewStoreProduct';
import { rolesMappingSelector } from '@ec-oem/ec.oem.oa3.ui.common/selectors';
import { connect } from 'react-redux';
import ConfigureSubscriber from './ConfigureSubscriber/ConfigureSubscriber';


const ManageStoreProduct = ({ authorizedRoles, match }) => {

  if (!authorizedRoles)
    return null;

  const { StoreProduct: StoreProductRoles } = authorizedRoles.PageRoleMapping;

  return (
    <Fragment>
      <Switch>
        <AuthorizedRoute exact path={`${match.url}`} component={SearchStoreProduct} authorizedRoles={StoreProductRoles.SearchStoreProduct} />
        <AuthorizedRoute exact path={`${match.url}/Subscribe`} component={ConfigureSubscriber} authorizedRoles={StoreProductRoles.ConfigureSubscriber} />
        <AuthorizedRoute exact path={`${match.url}/SearchStoreProduct`} component={SearchStoreProduct} authorizedRoles={StoreProductRoles.SearchStoreProduct} />
        <AuthorizedRoute exact path={`${match.url}/Search`} component={SearchStoreProduct} authorizedRoles={StoreProductRoles.SearchStoreProduct} />
        <AuthorizedRoute exact path={`${match.url}/:productId`} component={CreateStoreProduct} authorizedRoles={StoreProductRoles.CreateStoreProduct} />
        <AuthorizedRoute exact path={`${match.url}/View/:productId`} component={ViewStoreProduct} authorizedRoles={StoreProductRoles.ViewStoreProduct} />
      </Switch>
    </Fragment>
  )
}

ManageStoreProduct.propTypes = {
};



const mapStateToProps = (state) => {
  return {
    authorizedRoles: rolesMappingSelector(state)
  }
}


export default connect(mapStateToProps, null)(ManageStoreProduct);
