import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility/makeActionCreator";

export const SET_MODAL_DATA = "common/SET_MODAL_DATA";
export const setModalData = makeActionCreator(SET_MODAL_DATA, false, "data");


export const MODAL_CANCEL = "common/MODAL_CANCEL";
export const modalCancel = makeActionCreator(MODAL_CANCEL, false, "name");


export const MODAL_PROCEED = "common/MODAL_PROCEED";
export const modalProceed = makeActionCreator(MODAL_PROCEED, false, "name","comment");

export const ModalActions = { PROCEED: "PROCEED", CANCEL: "CANCEL" }
export const TAKE_MODAL_ACTION = "common/TAKE_MODAL_ACTION";
export const takeModalAction = makeActionCreator(TAKE_MODAL_ACTION, false, "name", "action","comment");



export const MODAL_OPEN = "common/MODAL_OPEN";
export const modalOpen = makeActionCreator(MODAL_OPEN, false, "name");

export const MODAL_CLOSE = "common/MODAL_CLOSE";
export const modalClose = makeActionCreator(MODAL_CLOSE, false, "name");