import React, { Component } from 'react';
import { DefaultButton, TextField } from 'office-ui-fabric-react';
import { DatePickerWithBoundary, ButtonGroupWrapper } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { injectIntl } from 'react-intl';
import { SoldToDetails } from '../../SoldToDetails';
import { bindActionCreators } from 'redux';
import { getSoldToDetails } from '../../../actions'
import { connect } from 'react-redux'
import { searchStateSelector, formStatusSelector, isSoldToControlRequired, selectedSoldToSelector } from '../../../selectors';
import { FieldConstants, Statuses, SearchResultStates } from '../../../constants';

class CriteriaForm extends Component {

    componentWillMount() {
        this.props.actions.getSoldToDetails();
    }
    componentWillReceiveProps(newProps) {
        if (newProps.isSoldToSelectionRequired !== this.props.isSoldToSelectionRequired) {
            this.props.setFieldTouched(FieldConstants.IS_SOLD_TO_REQUIRED, true);
            this.props.setFieldValue(FieldConstants.IS_SOLD_TO_REQUIRED, newProps.isSoldToSelectionRequired);
        }

    }
    changeField = (name, date) => {
        this.props.setFieldTouched(name, true);
        this.props.setFieldValue(name, date);
    };

    render() {
        const {
            values,
            errors,
            touched,
            isSoldToSelectionRequired,
            handleChange,
            handleSubmit,
            searchState,
            formState,
            selectedSoldTo
        } = this.props;

        const {
            ProductInformation,
            OrderabilityStartDate,
            OrderabilityEndDate,
        } = values;        
        
        return (
            <React.Fragment>
                {(!searchState || searchState === SearchResultStates.PRODUCT_SEARCH) && <React.Fragment>
                    {formState == Statuses.SUCCESS && <form autoComplete="off" className='search-criteria'>
                        <div className="ms-Grid">
                            <div className="ms-Grid-row">
                                {isSoldToSelectionRequired && <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <SoldToDetails name={FieldConstants.SOLD_TO} selectedText={selectedSoldTo && selectedSoldTo.displayText}
                                        onSelect={(selectedSoldTo) => this.changeField(FieldConstants.SOLD_TO, selectedSoldTo)}
                                        errorMessage={touched[FieldConstants.SOLD_TO] ? errors[FieldConstants.SOLD_TO] : ""} />
                                </div>}
                                {/* </div> */}
                                {/* <div className="ms-Grid-row"> */}
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">

                                    <TextField name={FieldConstants.PRODUCT_INFO}
                                        id={FieldConstants.PRODUCT_INFO}
                                        required={false}
                                        label={this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCriteria.ProductInfo', defaultMessage: "Product Info" })}
                                        value={ProductInformation}
                                        placeholder={this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCriteria.ProductInfo.PlaceHolder', defaultMessage: "Enter Part Number, Product Title or Description" })}
                                        errorMessage={touched[FieldConstants.PRODUCT_INFO] ? errors[FieldConstants.PRODUCT_INFO] : ""}
                                        onChange={handleChange(FieldConstants.PRODUCT_INFO)}
                                    />
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                                    <DatePickerWithBoundary name={FieldConstants.ORDERABILITY_STARTDATE}
                                        id={FieldConstants.ORDERABILITY_STARTDATE}
                                        label={this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCriteria.orderabilityStartDate', defaultMessage: "Orderability Start Date" })}
                                        isRequired={true}
                                        allowTextInput={true}
                                        onSelectDate={(selectedDate) => { this.changeField(FieldConstants.ORDERABILITY_STARTDATE, selectedDate) }}
                                        selectedDate={OrderabilityStartDate ? new Date(OrderabilityStartDate) : null}
                                        isOutOfBoundsErrorMessage={touched[FieldConstants.ORDERABILITY_STARTDATE] && errors[FieldConstants.ORDERABILITY_STARTDATE]}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                                    <DatePickerWithBoundary name={FieldConstants.ORDERABILITY_ENDDATE}
                                        id={FieldConstants.ORDERABILITY_ENDDATE}
                                        label={this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCriteria.orderabilityEndDate', defaultMessage: "Orderability End Date" })}
                                        isRequired={true}
                                        allowTextInput={true}
                                        onSelectDate={(selectedDate) => { this.changeField(FieldConstants.ORDERABILITY_ENDDATE, selectedDate) }}
                                        selectedDate={OrderabilityEndDate ? new Date(OrderabilityEndDate) : null}
                                        minDate={OrderabilityStartDate ? new Date(OrderabilityStartDate) : null}
                                        isOutOfBoundsErrorMessage={this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCriteria.orderabilityEndDate.greaterThanValidation', defaultMessage: "End date should be greater than Start Date" })}
                                    />
                                </div>
                            </div>
                        </div>
                        <ButtonGroupWrapper>
                            <DefaultButton
                                primary={true}
                                className="iconRight full-width"
                                text={this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCriteria.search', defaultMessage: "Search" })}
                                iconProps={{ iconName: 'Search' }}
                                onClick={handleSubmit}
                                allowDisabledFocus={true} />                           
                        </ButtonGroupWrapper>
                    </form>}
                </React.Fragment>}
            </React.Fragment>
        );
    }
}

CriteriaForm.propTypes = {

};


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ getSoldToDetails }, dispatch)

})

const mapStateToProps = (state) => {
    return {
        isSoldToSelectionRequired: isSoldToControlRequired(state),
        searchState: searchStateSelector(state),
        formState: formStatusSelector(state),
        selectedSoldTo : selectedSoldToSelector(state)
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CriteriaForm));