import { call, put, all, apply, take, select } from 'redux-saga/effects';
import { ApplicationConfig, InvokeUrl } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components/Logger';
import { showAlert } from "@ec-oem/ec.oem.oa3.ui.core/components/Alert";
import { AlertsTypes, AlertsFeature } from "@ec-oem/ec.oem.oa3.ui.core/constants";
import {
    GET_STORE_PRODUCT_SEARCH_RESULT, setStoreProductSearchResult,
    DESTROY_SEARCH_STORE_PRODUCT, saveSearchStoreProductCriteria,
    SET_SUBSCRIBER_STORE_PRODUCT,
    GET_USER_SUBSCRIBE_STATUS,
    setUserSubscribeStatus,
    REMOVE_USER_SUBSCRIPTION,
    getUserSubscribeStatus
} from './SearchStoreProduct.actions';
import { userEmailSelector, contactEmailSelector, userInfoSelector } from '@ec-oem/ec.oem.oa3.ui.common/selectors'
import { resetSearchSelection } from '@ec-oem/ec.oem.oa3.ui.common/components';
import { SearchRequestParams } from '../../constants';


const getQueryParamsString = (searchFilter) => {
    let queryString = Object.entries(searchFilter).reduce((queryString, [key, value]) => queryString.concat(searchParamsMapper[key](searchFilter)), "");
    return queryString.startsWith("&") ? queryString.substr(1) : queryString;
};



const searchParamsMapper = {
    [SearchRequestParams.FILTERS]: (searchFilter) => {
        let queryString = ""
        searchFilter && searchFilter.Filters && Object.keys(searchFilter.Filters).forEach((item) => {
            queryString = (searchFilter.Filters[item].reduce((queryString, fiterItem) => queryString.concat(`&${item}=${fiterItem}`), ""));
        });
        return queryString;
    },
    [SearchRequestParams.SEARCH_CRITERIA]: (searchFilter) => (searchFilter.SearchCriteria ? `&${SearchRequestParams.SEARCH_CRITERIA}=` + searchFilter.SearchCriteria : ""),
    [SearchRequestParams.PAGE_NO]: (searchFilter) => (searchFilter.PageNo ? `&${SearchRequestParams.PAGE_NO}=` + searchFilter.PageNo : ""),
    [SearchRequestParams.PAGE_SIZE]: (searchFilter) => (searchFilter.PageSize ? `&${SearchRequestParams.PAGE_SIZE}=` + searchFilter.PageSize : ""),
    [SearchRequestParams.SORT_ORDER]: (searchFilter) => (searchFilter.SortOrder ? `&${SearchRequestParams.SORT_ORDER}=` + searchFilter.SortOrder : "")
}


export function* searchDAProductsSaga() {
    while (true) {
        try {
            let { searchFilter } = yield take(GET_STORE_PRODUCT_SEARCH_RESULT);
            const config = yield ApplicationConfig.config;
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/v1/da/search?` + getQueryParamsString(searchFilter), method: 'GET' } });
            if (response.status === 200) {

                const data = yield apply(response, response.json);
                yield put(setStoreProductSearchResult(data));

            }
        } catch (error) {
            yield put(throwException("orderLineItemOrderTypeSaga error: " + error));
        }
    }
}



export function* destroySearchStoreProductSaga() {
    while (true) {
        try {
            yield take(DESTROY_SEARCH_STORE_PRODUCT);
            yield put(saveSearchStoreProductCriteria(null));
            yield put(setStoreProductSearchResult(null));
            yield put(setUserSubscribeStatus(null));

        } catch (error) {
            yield put(throwException("destroySearchStoreProductSaga error: " + error));
        }
    }
}


export function* notificationSearchDASaga() {
    while (true) {
        try {
            yield take(SET_SUBSCRIBER_STORE_PRODUCT);
            const config = yield ApplicationConfig.config;
            const userInfo = yield select(userInfoSelector);
            const userEmail = yield select(contactEmailSelector);

            let subscriberDetail = {
                Name: userInfo.name,
                Email: userEmail,
                ID: userInfo.userIdentifier

            }
            subscriberDetail = JSON.stringify(subscriberDetail);
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/v1/da/subscriber`, method: 'PUT', data: subscriberDetail } });
            if (response.status === 200) {
                yield put(setUserSubscribeStatus(true));
                yield put(showAlert({ type: AlertsTypes.SUCCESS, feature: AlertsFeature.STORE_PRODUCT_ALERTS, message: ["You are now subscribed to new product launches. Please click on the toggle button to unsubscribe."] }));
            }
        } catch (error) {
            yield put(throwException("notificationSearchDASaga error: " + error));
        }
    }
}




export function* removeUserSubscriptionSaga() {
    while (true) {
        try {
            yield take(REMOVE_USER_SUBSCRIPTION);
            const config = yield ApplicationConfig.config;
            let userEmail = yield select(contactEmailSelector);
            userEmail = JSON.stringify(userEmail);
            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/v1/da/subscriber/delete`, method: 'PUT', data: userEmail } });
            if (response.status === 200) {
                yield put(setUserSubscribeStatus(false));
                yield put(showAlert({ type: AlertsTypes.SUCCESS, feature: AlertsFeature.STORE_PRODUCT_ALERTS, message: ["You are now unsubscribed from new product launches notification. Please click on the toggle button to subscribe again."] }));
            }
        } catch (error) {
            yield put(throwException("removeUserSubscriptionSaga error: " + error));
        }
    }
}




export function* getNotificationStatusSaga() {
    while (true) {
        try {
            yield take(GET_USER_SUBSCRIBE_STATUS);
            const config = yield ApplicationConfig.config;
            const userEmail = yield select(contactEmailSelector);

            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/v1/da/subscriber/subscriber?EmailID=` + userEmail, method: 'GET' } });
            if (response.status === 200) {
                yield put(setUserSubscribeStatus(true));
            } else {
                yield put(setUserSubscribeStatus(false));
            }
        } catch (error) {
            yield put(throwException("getNotificationStatusSaga error: " + error));
        }
    }
}




