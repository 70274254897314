import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility/makeActionCreator";

export const GET_SOLD_TO_DETAILS = "DIGITAL_PRODUCT_VIEWER/GET_SOLD_TO_DETAILS";
export const getSoldToDetails = makeActionCreator(GET_SOLD_TO_DETAILS, false);

export const SET_SOLD_TO_DETAILS = "DIGITAL_PRODUCT_VIEWER/SET_SOLD_TO_DETAILS";
export const setSoldToDetails = makeActionCreator(SET_SOLD_TO_DETAILS, false,"soldTo");

export const UPDATE_SELECTED_SOLDTO = "DIGITAL_PRODUCT_VIEWER/UPDATE_SELECTED_SOLDTO";
export const updateSelectedSoldTo = makeActionCreator(UPDATE_SELECTED_SOLDTO, false, "selectedSoldTo");

export const SET_SELECTED_SOLDTO = "DIGITAL_PRODUCT_VIEWER/SET_SELECTED_SOLDTO";
export const setSelectedSoldTo = makeActionCreator(SET_SELECTED_SOLDTO, false,"selectedSoldTo");
