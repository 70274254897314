import React from "react";
import { Label } from "office-ui-fabric-react";
import { injectIntl } from "react-intl";

const LaunchReadinessDetailsFields = (props) => {
  return (
    <React.Fragment>
      {props.heading && <p className="header-text">{props.heading}</p>}
      <div className="ms-Grid">
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
            <Label
              name="ItemDescriptionHeader"
              id="ItemDescriptionHeader"
              className="labelHeader"
            >
              {props.intl.formatMessage({
                id: "createStoreProduct.LaunchReadinessDetails.ItemDescription",
              })}
            </Label>
            <Label
              name="ItemDescriptionValue"
              id="ItemDescriptionValue"
              className="labelValue"
            >
              {props.ItemDescription || "-"}
            </Label>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
            <Label
              name="ProgramDifferentiatorHeader"
              id="ProgramDifferentiatorHeader"
              className="labelHeader"
            >
              {props.intl.formatMessage({
                id:
                  "createStoreProduct.LaunchReadinessDetails.ProgramDifferentiator",
              })}
            </Label>
            <Label
              name="ProgramDifferentiatorValue"
              id="ProgramDifferentiatorValue"
              className="labelValue"
            >
              {props.ProgramDifferentiator.text || "-"}
            </Label>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg8">
            <Label
              name="MaxcimDescriptionHeader"
              id="MaxcimDescriptionHeader"
              className="labelHeader"
            >
              {props.intl.formatMessage({
                id:
                  "createStoreProduct.LaunchReadinessDetails.MaxcimDescription",
              })}
            </Label>
            <Label
              name="MaxcimDescriptionValue"
              id="MaxcimDescriptionValue"
              className="labelValue"
            >
              {props.MaxcimDescription || "-"}
            </Label>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
            <Label
              name="ProductFamilyHeader"
              id="ProductFamilyHeader"
              className="labelHeader"
            >
              {props.intl.formatMessage({
                id: "createStoreProduct.LaunchReadinessDetails.ProductFamily",
              })}
            </Label>
            <Label
              name="ProductFamilyValue"
              id="ProductFamilyValue"
              className="labelValue"
            >
              {props.ProductFamily || "-"}
            </Label>
          </div>
          {/* <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
            <Label
              name="RedemptionExpiryDaysHeader"
              id="RedemptionExpiryDaysHeader"
              className="labelHeader"
            >
              {props.intl.formatMessage({
                id:
                  "createStoreProduct.LaunchReadinessDetails.RedemptionExpiryDays",
              })}
            </Label>
            <Label
              name="RedemptionExpiryDaysValue"
              id="RedemptionExpiryDaysValue"
              className="labelValue"
            >
              {props.RedemptionExpiryDaysPostWindowsActivation || "-"}
            </Label>
          </div> */}
          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
            <Label
              name="ExpirationDaysPostPBRHeader"
              id="ExpirationDaysPostPBRHeader"
              className="labelHeader"
            >
              {props.intl.formatMessage({
                id:
                  "createStoreProduct.LaunchReadinessDetails.ExpirationDaysPostPBR",
              })}
            </Label>
            <Label
              name="ExpirationDaysPostPBRValue"
              id="ExpirationDaysPostPBRValue"
              className="labelValue"
            >
              {props.ExpirationDaysPostPBR || "-"}
            </Label>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
            <Label
              name="RedemptionAvailabilityHeader"
              id="RedemptionAvailabilityHeader"
              className="labelHeader"
            >
              {props.intl.formatMessage({
                id:
                  "createStoreProduct.BigCatDetails.RedemptionAvailabilityStartDate",
              })}
            </Label>
            <Label
              name="RedemptionAvailabilityValue"
              id="RedemptionAvailabilityValue"
              className="labelValue"
            >
              {props.OEMOrderabilityStartDate
                ? props.intl.formatDate(new Date(props.RedemptionAvailability))
                : "-"}
            </Label>
          </div>
          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
            <Label
              name="OEMOrderabilityStartDateHeader"
              id="OEMOrderabilityStartDateHeader"
              className="labelHeader"
            >
              {props.intl.formatMessage({
                id:
                  "createStoreProduct.LaunchReadinessDetails.OEMOrderabilityStartDate",
              })}
            </Label>
            <Label
              name="OEMOrderabilityStartDateValue"
              id="OEMOrderabilityStartDateValue"
              className="labelValue"
            >
              {props.OEMOrderabilityStartDate
                ? props.intl.formatDate(
                    new Date(props.OEMOrderabilityStartDate)
                  )
                : "-"}
            </Label>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
            <Label
              name="OEMOrderabilityEndDateHeader"
              id="OEMOrderabilityEndDateHeader"
              className="labelHeader"
            >
              {props.intl.formatMessage({
                id:
                  "createStoreProduct.LaunchReadinessDetails.OEMOrderabilityEndDate",
              })}
            </Label>
            <Label
              name="OEMOrderabilityEndDateValue"
              id="OEMOrderabilityEndDateValue"
              className="labelValue"
            >
              {props.OEMOrderabilityEndDate
                ? props.intl.formatDate(new Date(props.OEMOrderabilityEndDate))
                : "-"}
            </Label>
          </div>

          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
            <Label
              name="EndOfLifeHeader"
              id="EndOfLifeHeader"
              className="labelHeader"
            >
              {props.intl.formatMessage({
                id: "createStoreProduct.LaunchReadinessDetails.EndOfLife",
              })}
            </Label>
            <Label
              name="EndOfLifeValue"
              id="EndOfLifeValue"
              className="labelValue"
            >
              {props.EndOfLife
                ? props.intl.formatDate(new Date(props.EndOfLife))
                : "-"}
            </Label>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
            <Label
              name="ContentTypeHeader"
              id="ContentTypeHeader"
              className="labelHeader"
            >
              {props.intl.formatMessage({
                id: "createStoreProduct.LaunchReadinessDetails.ContentType",
              })}
            </Label>
            <Label
              name="ContentTypeValue"
              id="ContentTypeValue"
              className="labelValue"
            >
              {props.ContentType ? props.ContentType.text : "-"}
            </Label>
          </div>

          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
            <Label
              name="ContentClassificationHeader"
              id="ContentClassificationHeader"
              className="labelHeader"
            >
              {props.intl.formatMessage({
                id:
                  "createStoreProduct.LaunchReadinessDetails.ContentClassification",
              })}
            </Label>
            <Label
              name="ContentClassificationValue"
              id="ContentClassificationValue"
              className="labelValue"
            >
              {props.ContentClassification
                ? props.ContentClassification.text
                : "-"}
            </Label>
          </div>
        </div>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
            <Label
              name="OnlineContentHeader"
              id="OnlineContentHeader"
              className="labelHeader"
            >
              {props.intl.formatMessage({
                id: "createStoreProduct.LaunchReadinessDetails.OnlineContent",
              })}
            </Label>
            <Label
              name="OnlineContentValue"
              id="OnlineContentValue"
              className="labelValue"
            >
              {props.OnlineContent ? props.OnlineContent.text : "-"}
            </Label>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default injectIntl(LaunchReadinessDetailsFields);
