import { Card, CardHeader, CardBody } from "@ec-oem/ec.oem.oa3.ui.core/components";
import React from 'react';
import { injectIntl } from "react-intl";
import './MOQCard.scss';

const MOQCard = (props) => {
    const { BasePrices, MOQ, StartDate, EndDate } = props;
    return (
        <div className="ms-Grid-col ms-sm12 ms-md6 ms-xl4 moq-card-details">
            <Card>
                <CardHeader>
                    <div>
                        <table>
                            <thead>
                                <tr>

                                    <td>
                                        {props.intl.formatMessage({ id: 'digitalProductViewer.moqdetails.moqvalue', defaultMessage: "Min. Order Quantity" })}
                                    </td>
                                    <td>
                                        {props.intl.formatMessage({ id: 'digitalProductViewer.moqdetails.moqduration', defaultMessage: "Duration" })}
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>

                                    <td>
                                        {MOQ}
                                    </td>
                                    <td>
                                        {`${(new Date(StartDate)).toLocaleDateString()} - ${(new Date(EndDate)).toLocaleDateString()}`}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="card-details">
                        <table>
                            <thead>
                                <tr>
                                    <td>
                                        {props.intl.formatMessage({ id: 'digitalProductViewer.moqdetails.baseprieduration', defaultMessage: "Base Price Duration" })}
                                    </td>
                                    <td>
                                        {props.intl.formatMessage({ id: 'digitalProductViewer.moqdetails.baseprice', defaultMessage: "Base Prices" })}
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {BasePrices.map((bp) => <BasePriceContent {...bp} />)}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

const getPriceString = (amount)=>  {return parseFloat(amount) >= 0 ? `$${amount}` : `$(${Math.abs(parseFloat(amount))})`};


const BasePriceContent = ({ StartDate, EndDate, BasePrice }) => {
    return (
        <tr>
            <td>
                {`${(new Date(StartDate)).toLocaleDateString()} - ${(new Date(EndDate)).toLocaleDateString()}`}
            </td>
            <td>
                {getPriceString(BasePrice)}
            </td>
        </tr>
    );
};


export default injectIntl(MOQCard)