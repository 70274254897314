import { SortType, sortValues } from '@ec-oem/ec.oem.oa3.ui.common/components/Search';
import { CREATEDDATE } from '../../constants';

export const SearchStoreProductSortMapper = {
    [CREATEDDATE]: (data) => {
        return {
            key: "crtDate",
            text: "Created Date",
            default: true,
            defaultSort: SortType.ASC
        }
    }
};






