import React, { Component } from 'react';
import ReactAI from 'react-appinsights';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { PageTitles } from '../../../../constants';
import { bindActionCreators } from 'redux';
import {
    getProductDetails, getStoreProductWizardData,
    setSPWizardNext, setSPWizardPrevious
    , getLaunchReadinessMetaData,
    destroyCreateStoreProduct,initializeDataForCreate,setSPWizardStep
} from './CreateStoreProduct.actions';
import { BigCatDetails, ReviewAndSubmit } from './WizardSteps';
import { LaunchReadinessFormikForm } from './WizardSteps';
import WizardStepFactory from './WizardStepFactory';
import {
    currentWizardStepSelector, productDetailsSelector,
    wizardDataSelector, stepsWizardStepSelector, spWizardCompletionSelector,storeProductModeSelector
} from './CreateStoreProduct.selectors';
import { WizardNav, FeatureAlerts } from "@ec-oem/ec.oem.oa3.ui.core/components";
import { AlertsFeature } from "@ec-oem/ec.oem.oa3.ui.core/constants";
import { Label } from 'office-ui-fabric-react';
import SubmitSuccessComponent from '../Common/SubmitSuccessComponent';
import { setPageTitle } from '../../../../common/services/PageTitle';
import { SPModes, ModalNames } from '../../constants';
import ConfirmationModal from '../Common/Modal/ConfirmationModal';

const getSPWizardStepMapper = () => ({
    BIG_CAT_DETAILS: BigCatDetails,
    LAUNCH_READINESS_DETAILS: LaunchReadinessFormikForm,
    REVIEW_AND_SUBMIT: ReviewAndSubmit
})

class CreateStoreProduct extends Component {

    componentWillMount() {

        this.props.actions.initializeDataForCreate(this.props.match.params.productId);
        this.props.actions.getStoreProductWizardData();
    }

    componentWillUnmount() {
        this.props.actions.destroyCreateStoreProduct();
    }

   
    componentWillReceiveProps(nextProps)
    {
        if(nextProps.mode !== this.props.mode)
        setPageTitle(this.props.intl.formatMessage({ id: nextProps.mode===SPModes.EDIT_ACTIVE? 'pagetitle.editStoreProduct' :'pagetitle.createStoreProduct' }));

    }
    onPreviousClick = () => {
        this.props.actions.setSPWizardPrevious();
    }
    onSubmitClick = () => {
        this.props.actions.setSPWizardNext();
    }

    setWizardStep = (nextstep) => {
        this.props.actions.setSPWizardStep(nextstep);
    }


    render() {
        const { currentStep, productDetails, spWizardComplete } = this.props;

        return (
            <div className="container">
                <div className="page-header">
                    {this.props.intl.formatMessage({ id: 'createStoreProduct.title', defaultMessage: 'Manage Digital Attach Products' })}
                    {
                        this.props.productDetails &&
                        <div className="order-status-header">
                            {productDetails.ProductSKUID && <Label className="order-status">Big Cat ID: <span className="order-status-text"> {productDetails.ProductSKUID} </span></Label>}
                            {productDetails.PartNumber && <Label className="order-status">Part Number: <span className="order-status-text"> {productDetails.PartNumber} </span></Label>}
                        </div>
                    }
                </div>

                {this.props.steps &&
                    <div className="create-order-wizard">
                        <div className="create-order-nav-items">
                            <WizardNav items={this.props.steps} onItemClick={this.setWizardStep}></WizardNav>
                        </div>
                        <FeatureAlerts timeOut={5000} feature={AlertsFeature.STORE_PRODUCT_ALERTS} />
                        <div className="create-order-steps">
                            {spWizardComplete
                                ? <SubmitSuccessComponent
                                    productDetails={productDetails}

                                />
                                : <WizardStepFactory stepComponentMap={getSPWizardStepMapper()} currentStep={currentStep}
                                    onPreviousClick={this.onPreviousClick}
                                    onSubmitClick={this.onSubmitClick} />


                            }
                        </div>
                    </div>
                }
            
            <React.Fragment>
                <ConfirmationModal name={ModalNames.PFAM} 
                    confirmationText={this.props.intl.formatMessage({ id: 'createStoreProduct.pfamModal.text', defaultMessage: "PFAM provided may not exist" })} 
                    title={this.props.intl.formatMessage({ id: 'app.modal.type.Warning', defaultMessage: "Warning" })} 
                    primaryButtonText={this.props.intl.formatMessage({ id: 'app.modal.type.Primary', defaultMessage: "Proceed" })} 
                    secondaryButtonText={this.props.intl.formatMessage({ id: 'app.modal.type.Secondary', defaultMessage: "Cancel" })} />
            </React.Fragment>
            </div>
        );
    }
}

CreateStoreProduct.propTypes = {

};


const mapStateToProps = (state) => {
    
    return {
        currentStep: currentWizardStepSelector(state),
        productDetails: productDetailsSelector(state),
        wizardData: wizardDataSelector(state),
        steps: stepsWizardStepSelector(state),
        spWizardComplete: spWizardCompletionSelector(state),
        mode: storeProductModeSelector(state)
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        getProductDetails, getStoreProductWizardData,
        getLaunchReadinessMetaData, setSPWizardNext, setSPWizardPrevious,
        destroyCreateStoreProduct,initializeDataForCreate,setSPWizardStep
    }, dispatch)
})


export default ReactAI.withTracking(injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateStoreProduct)), PageTitles.CREATE_STORE_PRODUCT);