import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility/makeActionCreator";

export const INITIALIZE_APP ="common/actions/INITIALIZE_APP";
export const initializeApp = makeActionCreator(INITIALIZE_APP, false);

export const SET_TITLE ="common/actions/INITIALIZE_APP";
export const setTitle = makeActionCreator(SET_TITLE, false,"title");

export const INIT_APPINSIGHTS = "common/actions/INIT_APPINSIGHTS";
export const initAppInsights = makeActionCreator(INIT_APPINSIGHTS, false);

export const GET_NAVIGATION_MENU ="common/actions/GET_NAVIGATION_MENU";
export const getNavigationMenu = makeActionCreator(GET_NAVIGATION_MENU, false);

export const SET_NAVIGATION_MENU ="common/actions/SET_NAVIGATION_MENU";
export const setNavigationMenu = makeActionCreator(SET_NAVIGATION_MENU,false,"navigationMenu");

export const SET_DOC_LINK = "actions/SET_DOC_LINK";
export const setDocLink = makeActionCreator(SET_DOC_LINK, false, "doclink");
