import 'babel-polyfill'; 
import 'react-app-polyfill/ie11';
import React,{Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './app/utility/serviceWorker';
import { Provider } from 'react-redux';
import { initializeIcons } from '@uifabric/icons';
import { IntlProvider } from "react-intl";
import { localizationData } from './resources/localization/localizationData';
import { getStore } from './app/utility/store';
import App from './app/components/App';
import ApplicationConfig from './app/utility/applicationConfig';
import { ProgressIndicator } from 'office-ui-fabric-react';
import getLocalizationDetails from '@ec-oem/ec.oem.oa3.ui.common/utility/getLocalizationData';
import { ErrorBoundary } from '@ec-oem/ec.oem.oa3.ui.common/components';
import { AzureAD } from '@ec-oem/ec.oem.oa3.ui.core/components/Auth';


initializeIcons();

const Localization = getLocalizationDetails(localizationData);


ApplicationConfig.config.then((config) => {
    const store = getStore();
    ReactDOM.render((
        <Provider store={store}>
            <Suspense fallback={<ProgressIndicator />}>
                <IntlProvider locale={Localization.locale} messages={Localization.messages}>
                    <ErrorBoundary>
                        <AzureAD msal_config={config.MSAL_info} componentToRender={App}>
                        </AzureAD>
                    </ErrorBoundary>
                </IntlProvider>
            </Suspense>
        </Provider>
    ), document.getElementById('root'));

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: http://bit.ly/CRA-PWA
    serviceWorker.register();
    return config;
});

