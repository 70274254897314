import { INITIALIZE_APP } from '../actions';
import { put, take, call, apply } from 'redux-saga/effects';
import history from '../utility/history';
import { initializeAppInsights, getClaims, getRolesMapping, getNavigationMenu, getContactEmail } from '@ec-oem/ec.oem.oa3.ui.common/actions';
import ApplicationConfig from '../utility/applicationConfig';
import { setDocLink } from '../actions';
import { InvokeUrl } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components';
import { RequestHeaderConstants } from '../constants/appConstants';

export function* appStartSaga() {

    while (true) {
        yield take(INITIALIZE_APP);
        const config = yield ApplicationConfig.config;
        yield put(initializeAppInsights(config.appinsights_InstrumentationKey, history));
        yield put(getClaims(config.common_api_path));
        yield put(getRolesMapping(config.PageRoleMapping, config.ComponentRoleMapping));
        yield put(getNavigationMenu(config.common_api_path,history,config.Platform));
        yield put(getContactEmail(config.contactEmail));
		const token = yield call(helpDocsTokenSaga, config.HelpDocsInfo.ModuleName);
		let docurl = `${config.HelpDocsInfo.ModuleDocsURL}/${config.HelpDocsInfo.ModuleDocs.PodHandler}?${token}`;
        yield put(setDocLink(docurl));
    }
}

function* helpDocsTokenSaga(module) {
	try {
		const commonConfig = yield ApplicationConfig.config;
		let oa_api = commonConfig.oa_api;
		const responseC = yield call(InvokeUrl, {
			Config: {
				url: `${oa_api.url}/docs/${module}/token`,
				method: "GET",
				config:
				{				
					[RequestHeaderConstants.SubscriptionKey]:`${oa_api.Subscriptions_Headers.subscription_id}`				
				}				
			},
			SagaHandleResponse: { HandleResponse: false },
			IsbackGroundTask: true
		});
		if (responseC.status === 200) {
			const token = yield apply(responseC, responseC.json);
			return token;
		}
		yield put(throwException(`unable to retrieve token for module ${module}`));
		return null;
	} catch (error) {
		console.log("help docs error:" + error);
		yield put(throwException("help docs error:" + error));
	}
}

