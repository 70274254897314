import React from 'react';
import { Accordion, AccordionBody, AccordionHeader } from '@ec-oem/ec.oem.oa3.ui.core/components';
const CommonAccordion = (props) => {


    return (
        <div className=''>
            <Accordion isOpen={props.isOpen}>
                <AccordionHeader>
                    {props.headertext}
                </AccordionHeader>
                <AccordionBody>
                    {props.children}
                </AccordionBody>
            </Accordion>
        </div>
    );

}

export default (CommonAccordion);