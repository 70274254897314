import React, { Component } from 'react';

import {
    WizardStepHeader, WizardStep, WizardStepBody, WizardStepFooter
} from "@ec-oem/ec.oem.oa3.ui.core/components";
import { DefaultButton } from 'office-ui-fabric-react';
import { BigCatDetailsFields, LaunchReadinessDetailsFields } from './';
import { injectIntl } from 'react-intl';
import ReactAI from 'react-appinsights';
import { connect } from 'react-redux';
import { productDetailsSelector, bigCatPDSelector, launchReadinessPDSelector,bigCatChildPDSelector } from '../CreateStoreProduct.selectors';
import {
    submitStoreProduct
} from '../CreateStoreProduct.actions';
import { bindActionCreators } from 'redux';
import { PageTitles } from '../../../../../constants';
import CommonAccordion from '../../../../../common/components/CommonAccordion';
import BigCatChildDetailsFields from './BigCatChildDetailsFields';
class ReviewAndSubmit extends Component {

    submitStoreProduct() {
        let { bigCatPD, launchReadinessPD, bigCatChildPD } = this.props;
        this.props.actions.submitStoreProduct(bigCatPD, launchReadinessPD, bigCatChildPD);
    }

    render() {
        return (
            <React.Fragment>
                <div className="order-review-container">
                    <WizardStep>
                        <WizardStepHeader title={this.props.intl.formatMessage({ id: 'createStoreProduct.ReviewAndSubmit.Title', defaultMessage: "Review & Submit" })} />
                        <WizardStepBody>
                        
                        <BigCatDetailsFields {...this.props.bigCatPD}  heading= {this.props.intl.formatMessage({ id: 'ViewSP.BigCat.Heading', defaultMessage: "Big Cat Details" })}/>
                        
                        <CommonAccordion headertext={this.props.intl.formatMessage({ id: 'ViewSP.LaunchReadiness.Heading', defaultMessage: "Launch Readiness Details" })}>
                        <LaunchReadinessDetailsFields {...this.props.launchReadinessPD} />
                        </CommonAccordion>
                        {this.props.bigCatChildPD && this.props.bigCatChildPD.length > 0 &&
                        <CommonAccordion headertext={this.props.intl.formatMessage({ id: 'ViewSP.ChildSKUsInformation.Heading', defaultMessage: "Child SKUs Information" })}>
                        {this.props.bigCatChildPD.map((item,index) => (
                        <BigCatChildDetailsFields {...item} index ={ index + 1 } heading={this.props.intl.formatMessage({ id: 'ViewSP.ChildSKUsInformation.Heading', defaultMessage: 'Child SKUs Information' })} />
                        ))}
                        </CommonAccordion>}   
                        </WizardStepBody>
                        
                        <WizardStepFooter>
                            <DefaultButton
                                primary={true}
                                data-automation-id="test"
                                disabled={false}
                                text={this.props.intl.formatMessage({ id: 'app.previous', defaultMessage: "Previous" })}
                                iconProps={{ iconName: 'ChevronLeftSmall' }}
                                onClick={() => { this.props.onPreviousClick(this.state) }}
                                allowDisabledFocus={true} />
                            <DefaultButton
                                className="iconRight"
                                primary={true}
                                data-automation-id="test"
                                disabled={false}
                                text={this.props.intl.formatMessage({ id: 'app.submit', defaultMessage: "Submit" })}
                                iconProps={{ iconName: 'Save' }}
                                onClick={() => { this.submitStoreProduct() }}
                                allowDisabledFocus={true} />
                        </WizardStepFooter>
                    </WizardStep>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        bigCatPD: bigCatPDSelector(state),
        bigCatChildPD: bigCatChildPDSelector(state),
        launchReadinessPD: launchReadinessPDSelector(state),
        productDetails: productDetailsSelector(state)
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ submitStoreProduct }, dispatch)
})

export default ReactAI.withTracking(injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ReviewAndSubmit)), PageTitles.CREATE_STORE_PRODUCT);;