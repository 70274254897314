
import pdfImg from "./../../../../Images/pdf.svg";
import excelImg from "./../../../../Images/excel.svg";
import pptImg from "./../../../../Images/PPTLogo.png";
import wordImg from "./../../../../Images/WordLogo.png";
import zipImg from "./../../../../Images/zip.png";
import mailImg from "./../../../../Images/MailIcon.png";
import txtImg from "./../../../../Images/txt.png";
import jpgImg from "./../../../../Images/jpg.png";

export const criteriaConstants = {
    SOLD_TO: "SoldTo",
    ORDERABILITY_END_DATE: "OrderabilityEndDate",
    ORDERABILITY_START_DATE: "OrderabilityStartDate",
    PRODUCT_INFO: "ProductInformation",
    AGREEMENT_NUMBER: "AgreementNumber"
};

export const FieldConstants = {
    ORDERABILITY_STARTDATE: "OrderabilityStartDate",
    ORDERABILITY_ENDDATE: "OrderabilityEndDate",
    SOLD_TO: "SoldTo",
    PRODUCT_INFO:"ProductInformation",
    AGREEMENT_NUMBER: "AgreementNumber",
    IS_SOLD_TO_REQUIRED:"IsSoldToRequired"
};

export const Statuses = {
    SUCCESS:"SUCCESS",
    FAILURE:"FAILURE",
    ERROR:"ERROR"
}

export const SearchResultStates = {
    PRODUCT_SEARCH:"PRODUCT_SEARCH",
    PRODUCT_DETAILS:"PRODUCT_DETAILS",
    STRATEGIC_LINK:"STRATEGIC_LINK",
    PRICING_ADJUSTMENTS:"PRICING_ADJUSTMENTS"
}

export const DealViewerType = {
    DSBDealViewer: "DSBDealViewer",
    OADealViewer: "oadealviewer"
}


export const ImageType = {
    "PDF": pdfImg,
    "TXT": txtImg,
    "XLSX": excelImg,
    "XLS": excelImg,
    "DOCX": wordImg,
    "DOC": wordImg,
    "PPT": pptImg,
    "PPTX": pptImg,
    "MSG": mailImg,
    "ZIP": zipImg,
    "RAR": zipImg,
    "JPG": jpgImg,
    "JPEG": jpgImg,
    "PNG": jpgImg
};
