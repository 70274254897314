import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { AutoComplete } from "@ec-oem/ec.oem.oa3.ui.core/components";
import { soldToDetailsSelector } from './SoldToDetails.selectors';
import { updateSelectedSoldTo } from './SoldToDetails.actions';
import { injectIntl } from 'react-intl';

class SoldToDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            filteredResults: this.props.soldToDetails,
            selectedText: this.props.selectedText
        }
    }

    filterData = (searchText) => {
        let filteredData = this.props.soldToDetails.filter((company) => company.CompanyNameAndId && searchText && company.CompanyNameAndId.toLowerCase().includes(searchText.toLowerCase()));
        this.setState({
            filteredResults: filteredData
        })
    }

    onSelect = (selectedSoldTo) => {
        if (selectedSoldTo) {
            this.setState({
                selectedText: selectedSoldTo.displayText
            });

            this.props.actions.updateSelectedSoldTo(selectedSoldTo);
        }

        this.props.onSelect(selectedSoldTo);
    }

    render() {
        const { soldToDetails } = this.props;
        if (!soldToDetails) return null;
        return (
            <AutoComplete
                name={this.props.name}
                label={this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCriteria.soldToLabel', defaultMessage: "Sold-To" })}
                isRequired={true}
                placeholder={this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCriteria.soldTo.placeholder', defaultMessage: "Sold-To" })}
                errorMessage={this.props.errorMessage}
                defaultDisplayText={this.state.selectedText}
                onSearch={(searchText) => { this.filterData(searchText) }}
                items={this.state.filteredResults}
                onSelect={(selectedSoldTo) => this.onSelect(selectedSoldTo)}
                displaySelectedItem={true}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        soldToDetails: soldToDetailsSelector(state)
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ updateSelectedSoldTo }, dispatch)

})

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SoldToDetails))