import React, { Component } from 'react';
import { DefaultButton, TextField } from 'office-ui-fabric-react';
import { Panel, PanelType } from 'office-ui-fabric-react/lib/Panel';
import { DatePickerWithBoundary, ButtonGroupWrapper, FeatureAlerts } from '@ec-oem/ec.oem.oa3.ui.core/components';
import { injectIntl } from 'react-intl';
import { SoldToDetails } from '../../SoldToDetails';
import { AgreementListControl } from '../../AgreementListControl';
import { bindActionCreators } from 'redux';
import { getSoldToDetails, getAgreementDetails ,updateSelectedSoldTo} from '../../../actions'
import { connect } from 'react-redux'
import { searchStateSelector, formStatusSelector, isSoldToControlRequired, soldToDetailsSelector,agreementDetailsSelector } from '../../../selectors';
import { FieldConstants, Statuses, SearchResultStates } from '../../../constants';
import {AgreementPanel} from './'
import { relative } from 'path';

class OACriteriaForm extends Component {

    componentWillMount() {
        this.props.actions.getSoldToDetails();
        
    }

    componentWillReceiveProps(newProps) {
        if (newProps.isSoldToSelectionRequired !== this.props.isSoldToSelectionRequired) {
            this.props.setFieldTouched(FieldConstants.IS_SOLD_TO_REQUIRED, true);
            this.props.setFieldValue(FieldConstants.IS_SOLD_TO_REQUIRED, newProps.isSoldToSelectionRequired);
        }
        if(newProps.isSoldToSelectionRequired === false && newProps.SoldToDetails.length===1 && !this.props.agreementDetails)
        {
            this.props.setFieldValue(FieldConstants.SOLD_TO,  newProps.SoldToDetails[1]);
            this.props.actions.updateSelectedSoldTo(newProps.SoldToDetails[1]);
            this.props.actions.getAgreementDetails(); 
            this.props.setFieldValue(FieldConstants.IS_SOLD_TO_REQUIRED, false);
        }
    }
    
    
    changeField = (name, value) => {
        this.props.setFieldTouched(name, true);
        this.props.setFieldValue(name, value);
        if (name === FieldConstants.SOLD_TO ) {
            if(value)
            this.props.actions.getAgreementDetails();  
            else
            this.props.setFieldValue(FieldConstants.AGREEMENT_NUMBER, null);

        }
       
    };
    showpanel =() =>{
        this.setState({showpanel:true})
    }
    hidepanel =() =>{
        this.setState({showpanel:false})
    }
    state={showpanel:false};

    render() {
        const {
            values,
            errors,
            touched,
            isSoldToSelectionRequired,
            handleChange,
            handleSubmit,
            searchState,
            formState
        } = this.props;

        const {
            ProductInformation,
            OrderabilityStartDate,
            OrderabilityEndDate,SoldTo
        } = values;
        return (
            <React.Fragment>
                {(!searchState || searchState === SearchResultStates.PRODUCT_SEARCH) && <React.Fragment>
                    {formState == Statuses.SUCCESS && <form autoComplete="off" className='search-criteria'>
                        <div className="ms-Grid ms-slideDownIn10 ms-slideUpIn10 ">
                            <div className="ms-Grid-row row-padding">
                                {isSoldToSelectionRequired ? <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <SoldToDetails name={FieldConstants.SOLD_TO}
                                        onSelect={(selectedSoldTo) => this.changeField(FieldConstants.SOLD_TO, selectedSoldTo)}
                                        errorMessage={touched[FieldConstants.SOLD_TO] ? errors[FieldConstants.SOLD_TO] : ""}
                                        selectedText={SoldTo && SoldTo.displayText} />
                                </div>:null}
                
                                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                                    <TextField name={FieldConstants.AGREEMENT_NUMBER} label="Selected Agreement" readOnly={true} defaultValue="" value={values.AgreementNumber}
                                onClick={this.showpanel} errorMessage={touched[FieldConstants.AGREEMENT_NUMBER] ? errors[FieldConstants.AGREEMENT_NUMBER] : ""} required
                                    />
                                    </div>
                                    {(this.state.showpanel && (isSoldToSelectionRequired? values.SoldTo:true) )&& (
                                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6" style={{position:relative}}>
                                        <AgreementPanel name="AgreementPanel"
                                            onSelect={(agrNumber) => this.changeField(FieldConstants.AGREEMENT_NUMBER, agrNumber)}
                                            
                                            isSoldtoSelected ={!values.SoldTo} hidepanel={() => this.hidepanel()}/>
                                            </div>
                                        )}
                            </div>
                            <div className="ms-Grid-row row-padding">
                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                                    <DatePickerWithBoundary name={FieldConstants.ORDERABILITY_STARTDATE}
                                        id={FieldConstants.ORDERABILITY_STARTDATE}
                                        label={this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCriteria.orderabilityStartDate', defaultMessage: "Orderability Start Date" })}
                                        isRequired={true}
                                        allowTextInput={true}
                                        onSelectDate={(selectedDate) => { this.changeField(FieldConstants.ORDERABILITY_STARTDATE, selectedDate) }}
                                        selectedDate={OrderabilityStartDate ? new Date(OrderabilityStartDate) : null}
                                        isOutOfBoundsErrorMessage={touched[FieldConstants.ORDERABILITY_STARTDATE] && errors[FieldConstants.ORDERABILITY_STARTDATE]}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                                    <DatePickerWithBoundary name={FieldConstants.ORDERABILITY_ENDDATE}
                                        id={FieldConstants.ORDERABILITY_ENDDATE}
                                        label={this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCriteria.orderabilityEndDate', defaultMessage: "Orderability End Date" })}
                                        isRequired={true}
                                        allowTextInput={true}
                                        onSelectDate={(selectedDate) => { this.changeField(FieldConstants.ORDERABILITY_ENDDATE, selectedDate) }}
                                        selectedDate={OrderabilityEndDate ? new Date(OrderabilityEndDate) : null}
                                        minDate={OrderabilityStartDate ? new Date(OrderabilityStartDate) : null}
                                        isOutOfBoundsErrorMessage={this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCriteria.orderabilityEndDate.greaterThanValidation', defaultMessage: "End date should be greater than Start Date" })}
                                    />
                                </div>
                            </div>
                        </div>
                        <ButtonGroupWrapper>
                            <DefaultButton
                                primary={true}
                                className="iconRight full-width"
                                text={this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCriteria.search', defaultMessage: "Search" })}
                                iconProps={{ iconName: 'Search' }}
                                onClick={handleSubmit}
                                allowDisabledFocus={true} />
                        </ButtonGroupWrapper>
                    </form>}
                </React.Fragment>}
            </React.Fragment>
        );
    }
}

OACriteriaForm.propTypes = {

};


const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ getSoldToDetails, getAgreementDetails,updateSelectedSoldTo }, dispatch)

})

const mapStateToProps = (state) => {
    return {
        isSoldToSelectionRequired: isSoldToControlRequired(state),
        searchState: searchStateSelector(state),
        formState: formStatusSelector(state),
        SoldToDetails: soldToDetailsSelector(state),
        agreementDetails:agreementDetailsSelector(state)
    }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OACriteriaForm));