import { takeLatest, put, call, apply, select } from 'redux-saga/effects';
import { ApplicationConfig, InvokeUrl } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components/Logger';
import { GET_USERS_PROFILES, setUserProfiles } from './UserProfiles.actions';
import { allSubscribersSelector } from '../ConfigureSubscriber.selectors';


export function* getProvUsersListener() {
    yield takeLatest(GET_USERS_PROFILES, getUserProfilesSaga);
}


function* getUserProfilesSaga(input) {
    try {
        const { searchString } = yield (input);
        const config = yield ApplicationConfig.config;
        const responseC = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/v1/da/subscriber/userprofiles?SearchValue=${searchString}`, method: 'GET' } });
        if (responseC.status === 200) {
            const data = yield apply(responseC, responseC.json);
            let allSubscribers = yield select(allSubscribersSelector);
            var userProfiles = data && data.map((d) => {
                return {
                    key: d.UserProfileId.toString(),
                    displayText: d.Name,
                    Email: d.ContactEmail,
                    Name: d.Name,
                    ID: d.UserProfileId
                };

            });
            allSubscribers.map((subscriber) => userProfiles = userProfiles.filter(userProfile => userProfile.Email != subscriber.Email));
            yield put(setUserProfiles(userProfiles));
        }
    } catch (error) {
        yield put(throwException("UserProfiles Saga error:" + error));
    }
}

