import { createSelector } from 'reselect'
export const agreementDetailsSelector = createSelector(
    state => state.app.get("agreementDetails"),
    agreementDetails => agreementDetails ? agreementDetails.toJS() : null
);

export const selectedAgreementSelector = createSelector(
    state => state.app.get("selectedAgreement"),
    selectedAgreement => selectedAgreement ? selectedAgreement.toJS() : null
);
