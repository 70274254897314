import { criteriaConstants } from '../constants/DigitalProductViewer.constants';

// export const getDealViewerQueryString = (searchRequest) => {
//     let querystring = "?";
//     if (searchRequest.SoldTo) querystring = querystring.concat(`CustomerID=${searchRequest.SoldTo}`);
//     if (searchRequest.DealViewerType) querystring = querystring.concat(`&DealViewerType=${searchRequest.DealViewerType}`);
//     if (searchRequest.StartDate) querystring = querystring.concat(`&OrderabilityStartDate=${getFormattedDate(searchRequest.StartDate)}`);
//     if (searchRequest.EndDate) querystring = querystring.concat(`&OrderabilityEndDate=${getFormattedDate(searchRequest.EndDate)}`);
//     if (searchRequest.SearchFilter) querystring = querystring.concat(`&SearchFilter=${searchRequest.SearchFilter}`);
//     if (searchRequest.AgreementNumber) querystring = querystring.concat(`&SearchFilter=${searchRequest.SearchFilter}`);
//     return querystring;
// };

 
export const getFormattedDate = (dt) => {
    dt=new Date(dt);
    return new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
}

