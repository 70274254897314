import { createSelector } from 'reselect'
export const soldToDetailsSelector = createSelector(
    state => state.app.get("soldToDetails"),
    soldToDetails => soldToDetails ? soldToDetails.toJS() : null
);

export const isSoldToControlRequired = createSelector(
    state => state.app.get("soldToDetails"),
    soldToDetails => soldToDetails && soldToDetails.toJS().length > 1 
);

export const selectedSoldToSelector = createSelector(
    state => state.app.get("selectedSoldTo"),
    selectedSoldTo => selectedSoldTo ? selectedSoldTo.toJS() : null
);

export const agreementDetailsSelector = createSelector(
    state => state.app.get("agreementDetails"),
    agreementDetails => agreementDetails ? agreementDetails.toJS():null
);