import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dialog,DialogFooter,PrimaryButton,DefaultButton, DialogType } from 'office-ui-fabric-react';
import { setModalData,takeModalAction,ModalActions } from './modal.actions';
import { modalDataSelector } from './modal.selectors';
class ConfirmationModal extends PureComponent {
    
    componentWillMount() {
        const {name, ...rest} = this.props;
        this.props.initializeModal(name,rest);
    }    

    render() {
        let { modalDetails } = this.props;
        if (!modalDetails) return null;
        const { isOpen, confirmationText,primaryButtonText,secondaryButtonText,title } = modalDetails;
        return (

            <Dialog
            hidden={!isOpen}
            onDismiss={() => this.props.closeButtonClick()}
            dialogContentProps={{
              type: DialogType.normal,
              title: title,
              subText:confirmationText
            }}
            modalProps={{
              titleAriaId: this._labelId,
              subtitleAriaId: this._subTextId,
              isBlocking: true,
              topOffsetFixed: true

            }}
          >
            <DialogFooter>
              <PrimaryButton onClick={() => this.props.proceedButtonClick()} text={primaryButtonText} />
              <DefaultButton onClick={() => this.props.closeButtonClick()} text={secondaryButtonText} />
            </DialogFooter>
          </Dialog>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        modalDetails: modalDataSelector(state, ownProps.name)
    }
}
const mapDispatchToProps = (dispatch,ownProps) => ({
    initializeModal(name, data) {
        dispatch(setModalData({name, ...data}));
    },
    closeButtonClick() {
        dispatch(takeModalAction(ownProps.name, ModalActions.CANCEL));
    },
    proceedButtonClick() {
        dispatch(takeModalAction(ownProps.name,ModalActions.PROCEED))
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal)