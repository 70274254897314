import { fromJS } from 'immutable';
import { createReducer } from '@ec-oem/ec.oem.oa3.ui.core/utility/createReducer';
import { SET_AGREEMENT_DETAILS, SET_SELECTED_AGREEMENT } from './AgreementListControl.actions';

export const agreementDetails = createReducer(null, {
    [SET_AGREEMENT_DETAILS](state, { agreements }) {
        return fromJS(agreements);
    },
});

export const selectedAgreement = createReducer(null, {
    [SET_SELECTED_AGREEMENT](state, { selectedAgreement }) {
        return fromJS(selectedAgreement);
    },
});