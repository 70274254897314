import React, { Component } from 'react';
import { Card, CardHeader, CardBody } from "@ec-oem/ec.oem.oa3.ui.core/components";
import { Link } from 'office-ui-fabric-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { changeSearchResultView } from '../../../actions';
import { SearchResultStates } from '../../../constants';

class DigitalAttachProductCard extends Component {

    handleClick = (event) => {
        event.preventDefault();

        this.props.setSelectedDigitalAttachProduct(this.props.ItemKey);
    }

    handleKeyPress = (e) => {
        if (e.keyCode === 13)
            this.handleClick(e);
    }
    render() {
        const { ProductDescription,ItemDescription, OrderabilityStartDate, OrderabilityEndDate, PartNumber, MinimumUserAge, ContentType } = this.props;
        return (
            <div className="ms-Grid-col ms-sm12 ms-md6 ms-xl4">
                <Card>
                    <CardHeader>
                        <span className="card-title" title={PartNumber}>
                            <Link onKeyDown={(event) => this.handleKeyPress(event)} onClick={(event) => this.handleClick(event)}>
                                {PartNumber}
                            </Link>
                        </span>
                        <div className="card-secondary-information">{ItemDescription}</div>
                        <div className="card-secondary-information">
                            <span>{ContentType}</span>
                           
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div className="card-details">
                            <table>
                                <thead>
                                    <tr>
                                        <td>
                                            {this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCard.orderabilityStartDate', defaultMessage: "Orderability Start Date" })}
                                        </td>
                                        <td>
                                            {this.props.intl.formatMessage({ id: 'digitalProductViewer.searchCard.orderabilityEndDate', defaultMessage: "Orderability End Date" })}
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {OrderabilityStartDate}
                                        </td>
                                        <td>
                                            {OrderabilityEndDate}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    setSelectedDigitalAttachProduct(product) {
        dispatch(changeSearchResultView(SearchResultStates.PRODUCT_DETAILS, product));
    }
})

export default injectIntl(connect(null, mapDispatchToProps)(DigitalAttachProductCard));