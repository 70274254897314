import { fromJS } from 'immutable';
import { createReducer } from '@ec-oem/ec.oem.oa3.ui.core/utility/createReducer';
import { SET_SOLD_TO_DETAILS, SET_SELECTED_SOLDTO } from './SoldToDetails.actions';

export const soldToDetails = createReducer(null, {
    [SET_SOLD_TO_DETAILS](state, { soldTo }) {
        return fromJS(soldTo);
    },
});

export const selectedSoldTo = createReducer(null, {
    [SET_SELECTED_SOLDTO](state, { selectedSoldTo }) {
        return fromJS(selectedSoldTo);
    },
});