import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility/makeActionCreator";


export const GET_ALL_SUBSCRIBERS = "SPM/ConfigureSubscriber/GET_ALL_SUBSCRIBERS";
export const getAllSubscriber = makeActionCreator(GET_ALL_SUBSCRIBERS, false);

export const SET_ALL_SUBSCRIBERS = "SPM/ConfigureSubscriber/SET_ALL_SUBSCRIBERS";
export const setAllSubscriber = makeActionCreator(SET_ALL_SUBSCRIBERS, false, "allSubscribers");

export const UPDATE_SUBSCRIBERS = "SPM/ConfigureSubscriber/UPDATE_SUBSCRIBERS";
export const updateSubscribers = makeActionCreator(UPDATE_SUBSCRIBERS, true, "addSubscriberList", "removeSubscriberList");

export const DESTROY_CONFIGURE_SUBSCRIBER = "SPM/ConfigureSubscriber/DESTROY_CONFIGURE_SUBSCRIBER";
export const destroyConfigureSubscriber = makeActionCreator(DESTROY_CONFIGURE_SUBSCRIBER, true);

export const GET_ADD_SUBSCRIBER_LIST = "SPM/ConfigureSubscriber/GET_ADD_SUBSCRIBER_LIST";
export const getAddSubscriberList = makeActionCreator(GET_ADD_SUBSCRIBER_LIST, false);

export const SET_ADD_SUBSCRIBER_LIST = "SPM/ConfigureSubscriber/SET_ADD_SUBSCRIBER_LIST";
export const setAddSubscriberList = makeActionCreator(SET_ADD_SUBSCRIBER_LIST, false, "addSubscriberList");


export const GET_REMOVE_SUBSCRIBER_LIST = "SPM/ConfigureSubscriber/GET_REMOVE_SUBSCRIBER_LIST";
export const getRemoveSubscriberList = makeActionCreator(GET_REMOVE_SUBSCRIBER_LIST, false);

export const SET_REMOVE_SUBSCRIBER_LIST = "SPM/ConfigureSubscriber/SET_REMOVE_SUBSCRIBER_LIST";
export const setRemoveSubscriberList = makeActionCreator(SET_REMOVE_SUBSCRIBER_LIST, false, "removeSubscriberList");


export const DISCARD_SUBSCRIBER_CHANGES = "SPM/ConfigureSubscriber/DISCARD_SUBSCRIBER_CHANGES";
export const discardSubscriberChanges = makeActionCreator(DISCARD_SUBSCRIBER_CHANGES, false);


export const SET_MASTER_SUBSCRIBERS_LIST = "SPM/ConfigureSubscriber/SET_MASTER_SUBSCRIBERS_LIST";
export const setMasterSubscriberList = makeActionCreator(SET_MASTER_SUBSCRIBERS_LIST, false, "masterSubscribersList");


