import { connect } from 'react-redux';

import React, { Component } from 'react';
import { selectedProductSelector, selectedPricingAdjustmentSelector, searchStateSelector,selectedAttachmentsSelector } from '../selectors';
import { SearchResultStates } from '../constants';
import { injectIntl } from 'react-intl';
import { Breadcrumb } from 'office-ui-fabric-react';
import { changeSearchResultView } from '../actions';

class BreadCrumbWrapper extends Component {
    constructor(props){
        super(props);
        this.BreadCrumbItems={
            [SearchResultStates.PRODUCT_SEARCH]: ()=>({
                key:SearchResultStates.PRODUCT_SEARCH,
                text: this.props.intl.formatMessage({ id: 'digitalProductViewer.breadcrumb.productsearch', defaultMessage: "Product Search" }),
                onClick:()=>this.props.changeResultView(SearchResultStates.PRODUCT_SEARCH),

            }),  
            [SearchResultStates.STRATEGIC_LINK]: ()=>({
                key:SearchResultStates.PRODUCT_SEARCH,
                text: this.props.intl.formatMessage({ id: 'digitalProductViewer.breadcrumb.strategicLink', defaultMessage: "Strategic Link" }),
                onClick:()=>this.props.changeResultView(SearchResultStates.STRATEGIC_LINK),

            }),            
            [SearchResultStates.PRODUCT_DETAILS]:(Partnumber)=>({
                key:SearchResultStates.PRODUCT_DETAILS,
                text: Partnumber ? Partnumber : '-',
                onClick:()=>this.props.changeResultView(SearchResultStates.PRODUCT_DETAILS)
             }),
            [SearchResultStates.PRICING_ADJUSTMENTS]:(NetPriceStartDate,NetPriceEndDate)=>({
                key:SearchResultStates.PRODUCT_SEARCH,
                text: `${this.props.intl.formatMessage({ id: 'digitalProductViewer.breadcrumb.pricingdetails', defaultMessage: "Net Price Details" })} ( ${NetPriceStartDate} - ${NetPriceEndDate} )`,
                onClick:()=>this.props.changeResultView(SearchResultStates.PRICING_ADJUSTMENTS)
            })
        
        }
    }
    render() {

        let BreadCrumbDetails = [this.BreadCrumbItems[SearchResultStates.PRODUCT_SEARCH]()];
        const {selectedProduct, selectedNetPrice,Breadscrumbkey,docAttachments} = this.props;
        if(!selectedProduct) return null;
        BreadCrumbDetails.push(this.BreadCrumbItems[SearchResultStates.PRODUCT_DETAILS](selectedProduct[Breadscrumbkey]));
        if(selectedNetPrice)
        BreadCrumbDetails.push(this.BreadCrumbItems[SearchResultStates.PRICING_ADJUSTMENTS](selectedNetPrice.AdjustmentStartDate,selectedNetPrice.AdjustmentEndDate));
        if(docAttachments){
        BreadCrumbDetails.push(this.BreadCrumbItems[SearchResultStates.STRATEGIC_LINK]("StrategicLink"));

        }
        BreadCrumbDetails.map((detail)=> detail.isCurrentItem= this.props.currentState && this.props.currentState === detail.key);
        return <Breadcrumb className="digital-attach-viewer-breadcrumb ms-slideDownIn10"  items={BreadCrumbDetails}/>
    }
}


const mapStateToProps = (state) => {
    return {
        selectedProduct: selectedProductSelector(state),
        selectedNetPrice : selectedPricingAdjustmentSelector(state),
        currentState : searchStateSelector(state),
    docAttachments: selectedAttachmentsSelector(state)
}
}
 const mapDispatchToProps = (dispatch) => ({
     changeResultView(type){
         dispatch(changeSearchResultView(type));
     }
 })

export default injectIntl(connect(mapStateToProps,mapDispatchToProps)(BreadCrumbWrapper))