export const getLaunchReadinessMetaDataForUI = (metaDatas) => {
    let uiMetaDatas = {};

    metaDatas && Object.keys(metaDatas).forEach((key) => {
        let uiMetaData = [];
        metaDatas[key].map((item) => {
            uiMetaData.push({
                "key": item.ID,
                "text": item.ID + " - " + item.Value,
                "value": item.Value
            });
        })
        uiMetaDatas[key] = uiMetaData;
    });

    return uiMetaDatas;
}