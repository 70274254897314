
import {SortType,sortValues, SearchResultMappers} from '@ec-oem/ec.oem.oa3.ui.common/components/Search';

const FilterAndSortFields = {
    PRODUCT_DESC: {
        Key: "LegalId",
        Title: "Product Description"
    }
}

const FilterCreationMapper = {
    [FilterAndSortFields.PRODUCT_DESC.Key]: (searchData) => {
        var contentTypeValues = {};
        searchData.map((product) => {
            if (!contentTypeValues[product.LegalId]) {
                contentTypeValues[product.LegalId] = {
                    value: product.LegalId,
                    text: product.LegalIdDescription,
                    count:1
                }
            }
            else{
                contentTypeValues[product.LegalId].count++;
            }
        });
        return {
            field: FilterAndSortFields.PRODUCT_DESC.Key,
            title: FilterAndSortFields.PRODUCT_DESC.Title,
            items: Object.values(contentTypeValues)
        };
    }    
}

const FilterationMapper = {
    [FilterAndSortFields.PRODUCT_DESC.Key]:(data,values)=> data.filter((item) => values.includes(item.LegalId))
}

export const digitalSearchMappers =  {
    [SearchResultMappers.FILTER_MAPPER]: FilterCreationMapper,
    [SearchResultMappers.RESULT_FILTER_MAPPER]:FilterationMapper
}


