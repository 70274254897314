import React, { Component } from 'react';
import { searchCriteriaSelector } from '../../../selectors';
import { connect } from 'react-redux';
import "./DigitalAttachProductSearchResult.scss"
import DigitalAttachProductSearchResult from './DigitalAttachProductSearchResult';

class DigitalAttachProductSearchResultContainer extends Component {
    render() {
        let{searchCriteria} = this.props;
        if(!searchCriteria) return null;
        searchCriteria = searchCriteria.reduce((result,criteria)=> { if(criteria.displayText)
                                                                        result.push({label:criteria.key,value:criteria.displayText});
                                                                     return result;
                                                                   },[]);
        return <DigitalAttachProductSearchResult searchCriteria={searchCriteria}/>
    }
}

const mapStateToProps = (state) => {
    return {
        searchCriteria: searchCriteriaSelector(state)
    }
}

export default connect(mapStateToProps)(DigitalAttachProductSearchResultContainer);