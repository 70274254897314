import { connect } from 'react-redux';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import BreadCrumbWrapper from '../../BreadCrumbWrapper';
import { InMemorySearchResult, SearchDefaultSelectionTypes } from '@ec-oem/ec.oem.oa3.ui.common/components/Search';
import OAProductCard from './OAProductCard';
import { PageTitles } from '../../../../../constants';
import { digitalSearchMappers } from './OAProductSearchMapper';
import { SearchResultStates, DealViewerType } from '../../../constants';
import { setSearchResultView, changeSearchResultView } from '../../../actions';

import { searchStateSelector } from '../../../selectors';
import { exportDigitalProduct } from '../../../actions';
import OAProductDetails from './ProductDetails/OAProductDetails';
import NetPriceDetails from '../../AdjustmentDetails/NetPriceDetails';

const defaultSections = {
    [SearchDefaultSelectionTypes.PAGING_OPTION]: {
        page: 1,
        pageSize: 12
    },
    [SearchDefaultSelectionTypes.SELECTED_FILTERS]: null,
    [SearchDefaultSelectionTypes.SELECTED_ITEMS]: null,
    [SearchDefaultSelectionTypes.SORT_OPTION]: null
};
class OAProductSearchResult extends Component {

    componentWillMount(){
        this.props.changeResultView(SearchResultStates.PRODUCT_SEARCH);
    }

    handleExport = () => {
        this.props.actions.exportDigitalProduct(DealViewerType.OADealViewer);
    }
    
    render() {
        const {searchCriteria, searchState} = this.props;
        return (
            <React.Fragment>
                <div className="oa-digital-attach-search-result">
                    <BreadCrumbWrapper Breadscrumbkey="ProductId" />
                    {(!searchState ||searchState === SearchResultStates.PRODUCT_SEARCH) && <InMemorySearchResult searchName={PageTitles.DIGITAL_PRODUCT_VIEWER}
                        searchCriteria={searchCriteria}
                        defaultSelections={defaultSections}
                        defaultFilterCollapse={true}
                        ResultIteratorComponent={OAProductCard}
                        mappers={digitalSearchMappers}
                        handleExport={this.handleExport}
                    />}
                    
                    {searchState === SearchResultStates.PRODUCT_DETAILS && <OAProductDetails/>}
                    {searchState === SearchResultStates.PRICING_ADJUSTMENTS && <NetPriceDetails/>}
                   
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        searchState : searchStateSelector(state)
    }
}

const mapDispatchToProps = (dispatch) => ({
    changeResultView(type){
        dispatch(changeSearchResultView(type));
    },
    actions: bindActionCreators({exportDigitalProduct},dispatch)
})



export default connect(mapStateToProps, mapDispatchToProps)(OAProductSearchResult)