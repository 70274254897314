import { call, put, all, take, select } from 'redux-saga/effects';
import { ApplicationConfig, InvokeUrl } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import {
    GET_VIEW_PRODUCT_DETAILS, setViewProductDetails, setViewBigCatPD,setViewBigCatChildPD,
    setViewLaunchReadinessPD, SET_EDIT_PRODUCT_DETAILS,
    setStoreProductMode, DESTROY_VIEW_STORE_PRODUCT, INITIALIZE_DATA_FOR_VIEW
} from './ViewStoreProduct.actions';
import { viewProductDetailsSelector, viewBigCatPDSelector, viewLaunchReadinessPDSelector, viewBigCatChildPDSelector } from './ViewStoreProduct.selectors';
import { getProductDetailsSaga } from '../../sagas/productDetailSaga';
import {
    setProductDetails, setBigCatProductDetails, setLaunchReadinessPD,
    getLaunchReadinessMetaData, setLaunchReadinessMetaData, setBigCatChildProductDetails
} from '../CreateStoreProduct/CreateStoreProduct.actions';
import { productDetailsToBigCatAndLaunchReadinessMapper } from '../../services/ProductDetailsToBigCatAndLaunchReadinessMapper';
import { SPModes, SPStatus } from '../../constants';
import { launchReadinessMetaDataSelector, productDetailsSelector } from '../CreateStoreProduct/CreateStoreProduct.selectors';
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components/Logger';
import { getMetaDataSaga } from '../../sagas/metaDataSaga';
import { getLaunchReadinessMetaDataForUI } from '../../services/metaDataMapper';

export function* editStoreProductSaga() {
    while (true) {
        try {
            var { productId } = yield take(SET_EDIT_PRODUCT_DETAILS);
            const viewProductDetails = yield select(viewProductDetailsSelector);
            const viewBigCatPD = yield select(viewBigCatPDSelector);
            const viewLaunchReadinessPD = yield select(viewLaunchReadinessPDSelector);
            const viewBigCatChildPD= yield select (viewBigCatChildPDSelector);
            yield put(setProductDetails(viewProductDetails));
            yield put(setBigCatProductDetails(viewBigCatPD));
            yield put(setLaunchReadinessPD(viewLaunchReadinessPD));
            yield put(setBigCatChildProductDetails(viewBigCatChildPD));

            if (viewProductDetails.Status === SPStatus.ACTIVE) {
                yield put(setStoreProductMode(SPModes.EDIT_ACTIVE));
            } else {
                yield put(setStoreProductMode(SPModes.EDIT_DRAFT));
            }

        } catch (error) {
            yield put(throwException("editStoreProductSaga error: " + error));
        }
    }
}



export function* initializeViewPageSaga() {
    while (true) {
        var { productId } = yield take(INITIALIZE_DATA_FOR_VIEW);
        const currentMetaDataDetails = yield select(launchReadinessMetaDataSelector);
        let productDetails = null;
        let metaData = currentMetaDataDetails || null;

        if (!currentMetaDataDetails) {

            const { productCallData, metaDataCallData } = yield all({
                productCallData: call(getProductDetailsSaga, productId),
                metaDataCallData: call(getMetaDataSaga)
            });
            productDetails = productCallData;
            metaData = getLaunchReadinessMetaDataForUI(metaDataCallData)
            yield put(setLaunchReadinessMetaData(metaData));
        }
        else {
            productDetails = yield call(getProductDetailsSaga, productId);
        }

        yield put(setViewProductDetails(productDetails));
        const { bigCatPD, launchReadinessPD, bigCatChildPD  } = productDetailsToBigCatAndLaunchReadinessMapper(metaData, productDetails);
        yield put(setViewBigCatPD(bigCatPD));
        yield put(setViewLaunchReadinessPD(launchReadinessPD));
        yield put(setViewBigCatChildPD(bigCatChildPD));


    }
}

export function* destroyViewStoreProductSaga() {
    while (true) {
        try {
            yield take(DESTROY_VIEW_STORE_PRODUCT);
            yield put(setViewProductDetails(null));
            yield put(setViewBigCatPD(null));
            yield put(setViewLaunchReadinessPD(null));
            yield put(setLaunchReadinessMetaData(null));
            yield put(setViewBigCatChildPD(null));

        } catch (error) {
            yield put(throwException("destroyViewStoreProductSaga error: " + error));
        }
    }
}
