import { createSelector } from 'reselect';

export const navigationMenuSelector = createSelector(
    state => state.app.get("navigationMenu"),
    navigationMenu => navigationMenu ? navigationMenu.toJS() : navigationMenu
);

export const docLinkSelector = createSelector(
	(state) => state.app.get("doclink"),
	(doclink) => (doclink ? doclink : null)
);
