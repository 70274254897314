import React, { Component } from 'react';
import './SearchStoreProductCriteria.scss'
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { TextField } from 'office-ui-fabric-react';
import { bindActionCreators } from 'redux';


export class SearchStoreProductCriteria extends Component {

    handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            this.props.handleSearch(e.target.value);
        }
    }

    render() {
        return (
            <div className="ms-Grid search-storeProduct-criteria">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6 ms-md8 ms-lg12 search-storeProduct-criteriaTxtBox">
                        <TextField
                            name="storeProductSearchTxt"
                            placeholder={this.props.intl.formatMessage({ id: 'searchStoreProduct.searchTxt.placeholder', defaultMessage: 'Enter Big Cat ID, OEM Part Number, Product Title or Description' })}
                            //value={this.state.inputData.storeProductSearchTxt.value}
                            onKeyDown={(event) => this.handleKeyPress(event)}
                            iconProps={{ iconName: 'Search' }}
                            onClick={(event) => this.handleKeyPress(event)}
                        //onChange={(event, value) => this.onChange(event, value)}
                        //errorMessage={this.state.inputData.storeProductSearchTxt.error}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default injectIntl(SearchStoreProductCriteria);
