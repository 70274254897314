import React, { Component } from 'react';
import { connect } from 'react-redux'
import { AutoComplete } from "@ec-oem/ec.oem.oa3.ui.core/components";
import { getUserProfiles } from './UserProfiles.actions';
import { userProfilesSelector } from './UserProfiles.selectors';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import AutoSuggestionCard from './AutoSuggestionCard';
import './UserProfiles.scss';

const propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    placeholder: PropTypes.string,
    selectedText: PropTypes.string,
    errorMessage: PropTypes.string,
    onSelect: PropTypes.func
}

class UserProfiles extends Component {

    fetchData = async (searchText) => {
        if (searchText && searchText.length >= 3) {
            this.props.getUserProfiles(searchText);
        }
    }

    render = () => {
        return (
            <AutoComplete
                name="UserProfiles"
                defaultDisplayText={this.props.selectedText}
                onSearch={(searchText) => { this.fetchData(searchText) }}
                items={this.props.UserProfiles ? this.props.UserProfiles : []}
                onSelect={this.props.onSelect}
                lazySearch={true}
                AutoCompleteItem={AutoSuggestionCard}
                autocompleteitemclassname="auto-complete-card-items"
                autocompleteclassname="auto-complete-card"
                placeholder={this.props.placeholder}
            />
        );
    }
}


const mapStateToProps = (state) => {
    return {
        UserProfiles: userProfilesSelector(state)
    };
}

const mapDispatchToProps = (dispatch) => ({
    getUserProfiles(searchText) {
        dispatch(getUserProfiles(searchText))
    }
});

UserProfiles.propTypes = propTypes;
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserProfiles));