import React, { Component } from "react";
import { TextField, DefaultButton, ComboBox } from "office-ui-fabric-react";
import {
  OptionsButton,
  DatePickerWithBoundary,
  show,
} from "@ec-oem/ec.oem.oa3.ui.core/components";
import { connect } from "react-redux";
import {
  launchReadinessPDSelector,
  launchReadinessMetaDataSelector,
  bigCatPDSelector, bigCatChildPDSelector,
  storeProductModeSelector,
} from "../CreateStoreProduct.selectors";
import { injectIntl } from "react-intl";
import {
  WizardStepHeader,
  WizardStep,
  WizardStepBody,
  WizardStepFooter,
} from "@ec-oem/ec.oem.oa3.ui.core/components";

import {
  showAlert,
  hideFeatureAlert,
} from "@ec-oem/ec.oem.oa3.ui.core/components";
import {
  saveStoreProductDraft,
    setLaunchReadinessPD,
    setBigCatChildProductDetails,
} from "../CreateStoreProduct.actions";
import { bindActionCreators } from "redux";
import { SPModes } from "../../../constants";
import {
  LaunchReadinessFieldsLabel,
  LAUNCH_READINESS_ALERTS,
} from "./CreateStoreProductConstants";
import { Options } from "../../../constants";
import { AlertsTypes } from "@ec-oem/ec.oem.oa3.ui.core/constants";
import BigCatChildDetailsFields from "./BigCatChildDetailsFields";
import { bigCatChildPD } from "../CreateStoreProduct.reducers";

const today = new Date(Date.now());
const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);

class LaunchReadinessDetails extends Component {
  /*changeComboBox = (name, e, data) => {
        this.props.setFieldTouched(name, true);
        this.props.setFieldValue(name, data);
    };

    changeOptionButton = (name, data) => {
        this.props.setFieldTouched(name, true);
        this.props.setFieldValue(name, data);
    };

    changeDate = (name, date) => {
        this.props.setFieldTouched(name, true);
        this.props.setFieldValue(name, date);
    };*/
  getMaxDate = (date) => {
    var d1 = new Date(date).valueOf();
    return new Date(Math.max(d1, today.valueOf()));
  };
  changeField = (name, date) => {
    this.props.setFieldTouched(name, true);
    this.props.setFieldValue(name, date);
  };

  changeCombo = (name, value) => {
    this.props.setFieldTouched(name, true);
    this.props.setFieldValue(name, value);
  };

  saveStoreProductDraft() {
    let { bigCatPD, values } = this.props;
    this.props.actions.saveStoreProductDraft(bigCatPD, values);
  }

  onPrevclick() {
    this.props.actions.setLaunchReadinessPD(this.props.values);
    this.props.onPreviousClick(this.state);
  }

  checkValidityAndSubmit = (e) => {
    const { values } = this.props;
    this.props.actions.hideFeatureAlert(LAUNCH_READINESS_ALERTS);
    if (
      !values.RedemptionAvailability ||
      !values.OEMOrderabilityStartDate ||
      !values.OEMOrderabilityEndDate ||
      !values.EndOfLife ||
      new Date(today).valueOf() >
        new Date(values.RedemptionAvailability).valueOf() ||
      (values.RedemptionAvailability &&
        values.EndOfLife &&
        values.OEMOrderabilityStartDate &&
        values.OEMOrderabilityEndDate &&
        (values.EndOfLife < values.OEMOrderabilityEndDate ||
          values.OEMOrderabilityEndDate < values.OEMOrderabilityStartDate ||
          values.OEMOrderabilityStartDate < values.RedemptionAvailability))
    )
      this.props.actions.showAlert({
        type: AlertsTypes.ERROR,
        feature: LAUNCH_READINESS_ALERTS,
        message: [
          this.props.intl.formatMessage({ id: "createStoreProduct.dateError" }),
        ],
      });
    this.props.handleSubmit(e);
  };

  isDateEditable = (selectedDate) =>{
    if(this.props.controlsDisbled && selectedDate){
        let currentDate = new Date(new Date().setHours(0, 0, 0, 0));
        return new Date(selectedDate) < currentDate;
      }
    }

  render() {
    const {
      ContentTypes,
      ContentClassifications,
      OnlineContents,
    } = this.props.launchReadinessMetaData;
    const { launchreadinessdetails } = this.props;
    const { bigCatChildPD } = this.props;
    const {
      values,
      errors,
      touched,
      handleSubmit,
      handleBlur,
      handleChange,
      isValid,
      setFieldTouched,
      setFieldValue,
      controlsDisbled,
    } = this.props;
    const {
      ItemDescription,
      ProgramDifferentiator,
      MaxcimDescription,
      ProductFamily,
      RedemptionExpiryDaysPostWindowsActivation,
      ExpirationDaysPostPBR,
      OEMOrderabilityStartDate,
      OEMOrderabilityEndDate,
      EndOfLife,
      ContentType,
      ContentClassification,
      OnlineContent,
      RedemptionAvailability,
    } = values;
    const errormsgstyle = { fontSize: "12px" };
    return (
      <React.Fragment>
        <WizardStep>
          <WizardStepHeader
            title={this.props.intl.formatMessage({
              id: "createStoreProduct.LaunchReadiness.Title",
              defaultMessage: "Launch Readiness Details",
            })}
          />
          <form>
            <WizardStepBody>
              <div className="ms-Grid">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                    <TextField
                      name={LaunchReadinessFieldsLabel.ITEMDESCRIPTION}
                      id={LaunchReadinessFieldsLabel.ITEMDESCRIPTION}
                      required={true}
                      label={this.props.intl.formatMessage({
                        id:
                          "createStoreProduct.LaunchReadinessDetails.ItemDescription",
                        defaultMessage: "Item Description",
                      })}
                      value={ItemDescription ? ItemDescription : ""}
                      placeholder=""
                      maxLength={72}
                      errorMessage={
                        touched.ItemDescription ? errors.ItemDescription : ""
                      }
                      onChange={handleChange(
                        LaunchReadinessFieldsLabel.ITEMDESCRIPTION
                      )}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg2">
                    <TextField
                      name={LaunchReadinessFieldsLabel.PRODUCTFAMILY}
                      id={LaunchReadinessFieldsLabel.PRODUCTFAMILY}
                      required={true}
                      label={this.props.intl.formatMessage({
                        id:
                          "createStoreProduct.LaunchReadinessDetails.ProductFamily",
                        defaultMessage: "PFAM",
                      })}
                      value={ProductFamily ? ProductFamily : ""}
                      placeholder=""
                      errorMessage={
                        touched.ProductFamily ? errors.ProductFamily : ""
                      }
                      onChange={handleChange(
                        LaunchReadinessFieldsLabel.PRODUCTFAMILY
                      )}
                      disabled={controlsDisbled}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md8 ms-lg4">
                    <OptionsButton
                      options={Options}
                      label={this.props.intl.formatMessage({
                        id:
                          "createStoreProduct.LaunchReadinessDetails.ProgramDifferentiator",
                        defaultMessage: "Test Store Product",
                      })}
                      name={LaunchReadinessFieldsLabel.ProgramDifferentiator}
                      id={LaunchReadinessFieldsLabel.ProgramDifferentiator}
                      isRequired={false}
                      maxLength={35}
                      defaultSelectedKey={
                        ProgramDifferentiator ? ProgramDifferentiator.key : "1"
                      }
                      errorMsg={
                        touched.ProgramDifferentiator
                          ? errors.ProgramDifferentiator
                          : ""
                      }
                      onSelect={(selectedOption) => {
                        this.changeField(
                          LaunchReadinessFieldsLabel.ProgramDifferentiator,
                          selectedOption
                        );
                      }}
                      isDisabled={controlsDisbled}
                    />
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg8">
                    <TextField
                      name={LaunchReadinessFieldsLabel.MAXCIMDESCRIPTION}
                      id={LaunchReadinessFieldsLabel.MAXCIMDESCRIPTION}
                      required={true}
                      label={this.props.intl.formatMessage({
                        id:
                          "createStoreProduct.LaunchReadinessDetails.MaxcimDescription",
                        defaultMessage: "Maxcim Description",
                      })}
                      value={MaxcimDescription ? MaxcimDescription : ""}
                      placeholder=""
                      maxLength={35}
                      errorMessage={
                        touched.MaxcimDescription
                          ? errors.MaxcimDescription
                          : ""
                      }
                      onChange={handleChange(
                        LaunchReadinessFieldsLabel.MAXCIMDESCRIPTION
                      )}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                    <TextField
                      name={LaunchReadinessFieldsLabel.EXPIRATIONDAYSPOSTPBR}
                      id={LaunchReadinessFieldsLabel.EXPIRATIONDAYSPOSTPBR}
                      required={false}
                      label={this.props.intl.formatMessage({
                        id:
                          "createStoreProduct.LaunchReadinessDetails.ExpirationDaysPostPBR",
                        defaultMessage: "ExpirationDaysPostPBR",
                      })}
                      value={ExpirationDaysPostPBR ? ExpirationDaysPostPBR : ""}
                      placeholder=""
                      errorMessage={
                        touched.ExpirationDaysPostPBR
                          ? errors.ExpirationDaysPostPBR
                          : ""
                      }
                      onChange={handleChange(
                        LaunchReadinessFieldsLabel.EXPIRATIONDAYSPOSTPBR
                      )}
                      //disabled={controlsDisbled}
                      type={"number"}
                    />
                  </div>

                  {/* <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                    <TextField
                      name={LaunchReadinessFieldsLabel.REDEMPTIONEXPIRYDAYS}
                      id={LaunchReadinessFieldsLabel.REDEMPTIONEXPIRYDAYS}
                      required={false}
                      label={this.props.intl.formatMessage({
                        id:
                          "createStoreProduct.LaunchReadinessDetails.RedemptionExpiryDays",
                        defaultMessage:
                          "RedemptionExpiryDaysPostWindowsActivation",
                      })}
                      value={
                        RedemptionExpiryDaysPostWindowsActivation
                          ? RedemptionExpiryDaysPostWindowsActivation
                          : ""
                      }
                      placeholder=""
                      errorMessage={
                        touched.RedemptionExpiryDaysPostWindowsActivation
                          ? errors.RedemptionExpiryDaysPostWindowsActivation
                          : ""
                      }
                      onChange={handleChange(
                        LaunchReadinessFieldsLabel.REDEMPTIONEXPIRYDAYS
                      )}
                      //disabled={controlsDisbled}
                      type={"number"}
                    />
                  </div> */}
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                    <DatePickerWithBoundary
                      name={LaunchReadinessFieldsLabel.REDEMPTIONAVAILABILITY}
                      id={LaunchReadinessFieldsLabel.REDEMPTIONAVAILABILITY}
                      label={this.props.intl.formatMessage({
                        id:
                          "createStoreProduct.LaunchReadinessDetails.RedemptionAvailability",
                        defaultMessage:
                          "RTM Date (OEM Partner Orederability Start Date)",
                      })}
                      isRequired={true}
                      allowTextInput={true}
                      minDate={this.isDateEditable(RedemptionAvailability) ? null : today}
                      isOutOfBoundsErrorMessage={
                        "Redemption Availabilty Date must be future date"
                      }
                      onSelectDate={(selectedDate) => {
                        this.changeField(
                          LaunchReadinessFieldsLabel.REDEMPTIONAVAILABILITY,
                          selectedDate
                        );
                      }}
                      selectedDate={
                        RedemptionAvailability != null
                          ? new Date(RedemptionAvailability)
                          : new Date(today)
                      }
                      isRequiredErrorMessage={
                        touched.RedemptionAvailability &&
                        errors.RedemptionAvailability
                      }
                      disabled={this.isDateEditable(RedemptionAvailability)}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                    <DatePickerWithBoundary
                      name={LaunchReadinessFieldsLabel.OEMORDERABILITYSTARTDATE}
                      id={LaunchReadinessFieldsLabel.OEMORDERABILITYSTARTDATE}
                      label={this.props.intl.formatMessage({
                        id:
                          "createStoreProduct.LaunchReadinessDetails.OEMOrderabilityStartDate",
                        defaultMessage:
                          "RTM Date (OEM Partner Orederability Start Date)",
                      })}
                      isRequired={true}
                      allowTextInput={true}
                      minDate={this.isDateEditable(RedemptionAvailability) ? today : new Date(RedemptionAvailability) }
                      isOutOfBoundsErrorMessage={
                        "RTM Date must be between Redemption Availabilty Date and Discontinue Date"
                      }
                      onSelectDate={(selectedDate) => {
                        this.changeField(
                          LaunchReadinessFieldsLabel.OEMORDERABILITYSTARTDATE,
                          selectedDate
                        );
                      }}
                      selectedDate={new Date(OEMOrderabilityStartDate)}
                      isRequiredErrorMessage={
                        touched.OEMOrderabilityStartDate &&
                        errors.OEMOrderabilityStartDate
                      }
                      disabled={this.isDateEditable(OEMOrderabilityStartDate)}
                    />
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                    <DatePickerWithBoundary
                      name={LaunchReadinessFieldsLabel.OEMORDERABILITYENDDATE}
                      id={LaunchReadinessFieldsLabel.OEMORDERABILITYENDDATE}
                      label={this.props.intl.formatMessage({
                        id:
                          "createStoreProduct.LaunchReadinessDetails.OEMOrderabilityEndDate",
                        defaultMessage:
                          "Discontinue Date (OEM Partner Orderability End Date)",
                      })}
                      isRequired={true}
                      allowTextInput={true}
                      minDate={
                        OEMOrderabilityStartDate
                          ? this.getMaxDate(OEMOrderabilityStartDate)
                          : null
                      }
                      isOutOfBoundsErrorMessage={
                        "Discontinue Date must be between RTM Date and End of Life Date"
                      }
                      onSelectDate={(selectedDate) => {
                        this.changeField(
                          LaunchReadinessFieldsLabel.OEMORDERABILITYENDDATE,
                          selectedDate
                        );
                      }}
                      selectedDate={
                        OEMOrderabilityEndDate &&
                        new Date(OEMOrderabilityEndDate)
                      }
                      isRequiredErrorMessage={
                        touched.OEMOrderabilityEndDate &&
                        errors.OEMOrderabilityEndDate
                      }
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                    <DatePickerWithBoundary
                      name={LaunchReadinessFieldsLabel.ENDOFLIFE}
                      id={LaunchReadinessFieldsLabel.ENDOFLIFE}
                      label={this.props.intl.formatMessage({
                        id:
                          "createStoreProduct.LaunchReadinessDetails.EndOfLife",
                        defaultMessage: "End Of Life",
                      })}
                      isRequired={true}
                      allowTextInput={true}
                      minDate={
                        OEMOrderabilityEndDate
                          ? new Date(OEMOrderabilityEndDate)
                          : null
                      }
                      isOutOfBoundsErrorMessage={
                        "Date must be greater than or equal to Discontinue Date"
                      }
                      onSelectDate={(selectedDate) => {
                        this.changeField(
                          LaunchReadinessFieldsLabel.ENDOFLIFE,
                          selectedDate
                        );
                      }}
                      selectedDate={EndOfLife && new Date(EndOfLife)}
                      isRequiredErrorMessage={
                        touched.EndOfLife && errors.EndOfLife
                      }
                    />
                  </div>
                </div>
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                    <ComboBox
                      name={LaunchReadinessFieldsLabel.CONTENTTYPE}
                      label={this.props.intl.formatMessage({
                        id:
                          "createStoreProduct.LaunchReadinessDetails.ContentType",
                        defaultMessage: "Content Type",
                      })}
                      id={LaunchReadinessFieldsLabel.CONTENTTYPE}
                      required={true}
                      selectedKey={ContentType && ContentType.key}
                      options={ContentTypes}
                      errorMessage={
                        touched.ContentType ? errors.ContentType : ""
                      }
                      onChange={(event, value, index) =>
                        this.changeCombo(
                          LaunchReadinessFieldsLabel.CONTENTTYPE,
                          value
                        )
                      }
                      disabled={controlsDisbled}
                      style={errormsgstyle}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                    <ComboBox
                      name={LaunchReadinessFieldsLabel.CONTENTCLASSIFICATION}
                      id={LaunchReadinessFieldsLabel.CONTENTCLASSIFICATION}
                      label={this.props.intl.formatMessage({
                        id:
                          "createStoreProduct.LaunchReadinessDetails.ContentClassification",
                        defaultMessage: "Content Classification",
                      })}
                      required={true}
                      selectedKey={
                        ContentClassification && ContentClassification.key
                      }
                      options={ContentClassifications}
                      errorMessage={
                        touched.ContentClassification
                          ? errors.ContentClassification
                          : ""
                      }
                      onChange={(event, value, index) =>
                        this.changeCombo(
                          LaunchReadinessFieldsLabel.CONTENTCLASSIFICATION,
                          value
                        )
                      }
                      disabled={controlsDisbled}
                      style={errormsgstyle}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                    <ComboBox
                      name={LaunchReadinessFieldsLabel.ONLINECONTENT}
                      id={LaunchReadinessFieldsLabel.ONLINECONTENT}
                      label={this.props.intl.formatMessage({
                        id:
                          "createStoreProduct.LaunchReadinessDetails.OnlineContent",
                        defaultMessage: "Online Content",
                      })}
                      required={true}
                      selectedKey={OnlineContent && OnlineContent.key}
                      options={OnlineContents}
                      errorMessage={
                        touched.OnlineContent ? errors.OnlineContent : ""
                      }
                      onChange={(event, value, index) =>
                        this.changeCombo(
                          LaunchReadinessFieldsLabel.ONLINECONTENT,
                          value
                        )
                      }
                      disabled={controlsDisbled}
                      style={errormsgstyle}
                    />
                  </div>
                </div>
              </div>
              {bigCatChildPD && bigCatChildPD.length > 0 && <div>
            <p className='header-text'> {'Child SKUs Information'} </p>        
            {bigCatChildPD.map((item,index) => (
            <BigCatChildDetailsFields {...item} index ={ index + 1 } heading={this.props.intl.formatMessage({ id: 'ViewSP.ChildSKUsInformation.Heading', defaultMessage: 'Child SKUs Information' })} />
            ))}</div>}
            </WizardStepBody>
            <WizardStepFooter>
              <DefaultButton
                className="iconRight full-width"
                primary={true}
                data-automation-id="test"
                disabled={controlsDisbled}
                text={this.props.intl.formatMessage({
                  id: "app.saveAsDraft",
                  defaultMessage: "Save As Draft",
                })}
                iconProps={{ iconName: "Save" }}
                onClick={() => {
                  this.saveStoreProductDraft();
                }}
                allowDisabledFocus={true}
              />

              <DefaultButton
                primary={true}
                data-automation-id="test"
                disabled={false}
                text={this.props.intl.formatMessage({
                  id: "app.previous",
                  defaultMessage: "Previous",
                })}
                iconProps={{ iconName: "ChevronLeftSmall" }}
                onClick={() => {
                  this.onPrevclick();
                }}
                allowDisabledFocus={true}
              />

              <DefaultButton
                className="iconRight"
                primary={true}
                data-automation-id="test"
                disabled={false}
                text={this.props.intl.formatMessage({
                  id: "app.next",
                  defaultMessage: "Next",
                })}
                iconProps={{ iconName: "ChevronRightSmall" }}
                onClick={(e) => this.checkValidityAndSubmit(e)}
                allowDisabledFocus={true}
              />
            </WizardStepFooter>
          </form>
        </WizardStep>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  let mode = storeProductModeSelector(state);
  return {
    launchreadinessdetails: launchReadinessPDSelector(state),
    launchReadinessMetaData: launchReadinessMetaDataSelector(state),
    bigCatPD: bigCatPDSelector(state),
    bigCatChildPD : bigCatChildPDSelector(state),
    controlsDisbled: mode === SPModes.EDIT_ACTIVE,
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      saveStoreProductDraft,
      setLaunchReadinessPD,
      showAlert,
      hideFeatureAlert,
    },
    dispatch
  ),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(LaunchReadinessDetails)
);
