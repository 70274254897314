import React from 'react';
import { CommonAccordion, PageHeader } from '../../../../../../common/components';
import { injectIntl } from 'react-intl';
import { Label } from 'office-ui-fabric-react';
import SectionHeader from '../../../../../../common/components/SectionHeader/SectionHeader';

const OAProductAttributes = (props) => {
    const {
        DiscoDate,
        LegalId,
        LegalIdDescription,
        ProductId,
        ProductName,
        RTMStartDate,
        OrderabilityStartDate,
        OrderabilityEndDate } = props.attributes;

    return (
        <React.Fragment>
            <div className="ms-Grid product-attributes ms-slideRightIn10">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                        <Label name="PartNumberLbl" id="PartNumberLbl" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.PartNumber', defaultMessage: 'Part Number' })}</Label>
                        <Label name="PartNumberVal" id="PartNumberVal" title={props.ProductId} className="labelValue-long-text">{ProductId || '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                        <Label name="PartNameLbl" id="PartNameLbl" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.PartName', defaultMessage: 'Part Name' })}</Label>
                        <Label name="PartNameVal" id="PartNameVal" title={ProductName} className="labelValue-long-text">{ProductName || '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                        <Label name="ProductIdLbl" id="ProductIdLbl" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.ProductId', defaultMessage: 'Product ID' })}</Label>
                        <Label name="ProductIdVal" id="ProductIdVal" title={LegalId} className="labelValue">{LegalId || '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                        <Label name="ProductDescLbl" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.ProductDescription', defaultMessage: 'Product Description' })}</Label>
                        <Label name="ProductDescVal" title={LegalIdDescription} className="labelValue-long-text">{LegalIdDescription || '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                        <Label name="ProductValidFromLbl" id="ProductValidFromLbl" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.ProductValidFrom', defaultMessage: 'Product Valid From' })}</Label>
                        <Label name="ProductValidFromVal" id="ProductValidFromVal" className="labelValue">{RTMStartDate ? props.intl.formatDate(new Date(RTMStartDate)) : '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                        <Label name="ProductValidToLabel" id="ProductValidToLabel" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.ProductValidTo', defaultMessage: 'Product Valid To' })}</Label>
                        <Label name="ProductValidToVal" id="ProductValidToVal" className="labelValue">{DiscoDate ? props.intl.formatDate(new Date(DiscoDate)) : '-'}</Label>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default injectIntl(OAProductAttributes);