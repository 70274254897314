import { getFilterDataFirstOrDefault } from "../utility";
import { Options, PD } from "../constants";

export const productDetailsToBigCatAndLaunchReadinessMapper = (
  metadata,
  data
) => {
  let bigCatPD = {};
  let launchReadinessPD = {};
  let bigCatChildPD ={};

  if (null == data) {
    return { bigCatPD: bigCatPD, launchReadinessPD: launchReadinessPD, bigCatChildPD: bigCatChildPD };
  }
  bigCatPD = {
    ProductSKUID: data.ProductSKUID,
    ProductDetailedDescription: data.ProductDetailedDescription,
    ProductDescription: data.ProductDescription,
    GeoAvailability: data.GeoAvailability,
    Language: data.Language,
    MinimumUserAge: data.MinimumUserAge,
    IsDAPrimary: data.IsDAPrimary,
  };

  launchReadinessPD = {
    ItemDescription: data.ItemDescription,
    ProgramDifferentiator: getFilterDataFirstOrDefault(
      Options,
      data.ProgramDifferentiator === "PTK" || false
    ),
    MaxcimDescription: data.MaxcimDescription,
    ProductFamily: data.ProductFamily,
    // RedemptionExpiryDaysPostWindowsActivation:
    //   data.RedemptionExpiryDaysPostWindowsActivation,
    ExpirationDaysPostPBR: data.ExpirationDaysPostPBR,
    RedemptionAvailability: data.RedemptionAvailability,
    OEMOrderabilityStartDate: data.OEMOrderabilityStartDate,
    OEMOrderabilityEndDate: data.OEMOrderabilityEndDate,
    EndOfLife: data.EndOfLife,
    ContentType: getFilterDataFirstOrDefault(
      metadata.ContentTypes,
      data.ContentType
    ),
    ContentClassification: getFilterDataFirstOrDefault(
      metadata.ContentClassifications,
      data.ContentClassification
    ),
    OnlineContent: getFilterDataFirstOrDefault(
      metadata.OnlineContents,
      data.OnlineContent
    ),
  };

  

  return {
    bigCatPD: bigCatPD,
    launchReadinessPD: launchReadinessPD,
    PartNumber: data.PartNumber,
    bigCatChildPD: data.ChildProducts,
  };
};
