import React from 'react';
import { injectIntl } from 'react-intl';
import { Label } from 'office-ui-fabric-react';
import { TestProduct } from '../../Criteria/FieldConstants';

const ProductAttributes = (props) => {
    const { ContentClassification,
        ContentType,
        EndOfLife,
        GeoAvailability,
        ProgramDifferentiator,
        ItemDescription,
        Language,
        MaxcimDescription,
        MinimumUserAge,
        OEMOrderabilityEndDate,
        OEMOrderabilityStartDate,
        OnlineContent,
    PartNumber,
        ProductDescription,
        ProductDetailedDescription,
        ProductFamily,
        ProductSKUID,
        OrderabilityStartDate, OrderabilityEndDate,
        RedemptionAvailability,
        ExpiryDaysPostPBR } = props.attributes;
    return (
        <React.Fragment>
            {/* <SectionHeader title={PartNumber}>
                {ProductDescription && <div>{ProductDescription}</div>}
            </SectionHeader> */}

            <div className="ms-Grid product-attributes ms-slideRightIn10">
                <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                        <Label name="ProductDescriptionLbl" id="ProductDescriptionLbl" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.ProductDescription', defaultMessage: 'Product Description' })}</Label>
                        <Label name="ProductDescriptionVal" id="ProductDescriptionVal" title={ItemDescription} className="labelValue-long-text">{ItemDescription || '-'}</Label>
                    </div>
                   
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                        <Label name="RedemptionAvailabilityLbl" id="RedemptionAvailabilityLbl" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.RedemptionAvailabilityStartDate', defaultMessage: 'Redemption Availability - Start Date' })}</Label>
                        <Label name="RedemptionAvailabilityVal" id="RedemptionAvailabilityVal" className="labelValue">{RedemptionAvailability ? props.intl.formatDate(new Date(RedemptionAvailability)) : '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                        <Label name="GeoAvailabilityLbl" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.GeoAvailability', defaultMessage: 'Geo Availability' })}</Label>
                        <Label name="GeoAvailabilityVal" title={GeoAvailability} className="labelValue-long-text">{GeoAvailability || '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                        <Label name="LanguageLbl" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.Language', defaultMessage: 'Language' })}</Label>
                        <Label name="LanguageVal" title={Language} className="labelValue-long-text">{Language || '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                        <Label name="MinimumUserAgeLbl" id="MinimumUserAgeLbl" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.MinUserAge', defaultMessage: 'Min User age' })}</Label>
                        <Label name="MinimumUserAgeVal" id="MinimumUserAgeVal" className="labelValue">{MinimumUserAge || '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                        <Label name="OrderabilityStartDateLbl" id="OrderabilityStartDateLbl" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.orderabilityStartDate', defaultMessage: 'Min User age' })}</Label>
                        <Label name="OrderabilityStartDateVal" id="OrderabilityStartDateVal" className="labelValue">{OrderabilityStartDate || '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                        <Label name="OrderabilityEndDateLabel" id="OrderabilityEndDateLabel" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.orderabilityEndDate', defaultMessage: 'Min User age' })}</Label>
                        <Label name="OrderabilityEndDate" id="OrderabilityEndDate" className="labelValue">{OrderabilityEndDate || '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                        <Label name="EndOfLifeLabel" id="EndOfLifeLabel" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.endoflife', defaultMessage: 'Min User age' })}</Label>
                        <Label name="EndOfLife" id="EndOfLife" className="labelValue">{EndOfLife || '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                        <Label name="ContentTypeLabel" id="ContentTypeLabel" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.contenttype', defaultMessage: 'Min User age' })}</Label>
                        <Label name="ContentType" id="ContentType" className="labelValue">{ContentType || '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                        <Label name="ContentClassificationLabel" id="ContentClassificationLabel" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.contentclassification', defaultMessage: 'Min User age' })}</Label>
                        <Label name="ContentClassification" id="ContentClassification" className="labelValue">{ContentClassification || '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                        <Label name="OnlineContentlabel" id="OnlineContentlabel" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.onlinecontent', defaultMessage: 'Min User age' })}</Label>
                        <Label name="OnlineContent" id="OnlineContent" className="labelValue">{OnlineContent || '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                        <Label name="TestProductLabelId" id="TestProductLabelId" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.TestProduct', defaultMessage: 'Test Product' })}</Label>
                        <Label name="TestProductLabelVal" id="TestProductLabelVal" title={ProductDetailedDescription} className="labelValue-long-text">{ ProgramDifferentiator === TestProduct ? 'Yes':'No'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg3">
                        <Label name="ProductDetailedDescriptionLbl" id="ProductDetailedDescriptionLbl" className="labelHeader">{props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.ProductDetailDescription', defaultMessage: 'Product Detailed Description' })}</Label>
                        <Label name="ProductDetailedDescriptionVal" id="ProductDetailedDescriptionVal" title={ProductDetailedDescription} className="labelValue-long-text">{ProductDetailedDescription || '-'}</Label>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default injectIntl(ProductAttributes);