import React, { Fragment } from 'react';
import { Switch, Route } from "react-router-dom";
import { DigitalProductViewerHome } from './Home';
import { OAProductViewer } from './OAProductViewer';
import { DigitalAttachProductViewer } from './DigitalAttachProductViewer';


const DigitalProductViewer = props => {
  const { match } = props;
  return (

    <Fragment>

      <Switch>
        <Route exact path={`${match.url}`} component={DigitalProductViewerHome} />
        <Route exact path={`${match.url}/DigitalAttachProductViewer`} component={DigitalAttachProductViewer} />
        <Route exact path={`${match.url}/OAProductViewer`} component={OAProductViewer} />
      </Switch>
    </Fragment>
  );
};

DigitalProductViewer.propTypes = {

};

export default DigitalProductViewer;