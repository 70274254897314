import React from 'react';
import { CommonAccordion, DetailedListWrapper } from '../../../common/components';
import { injectIntl } from 'react-intl';
import { changeSearchResultView, downloadDealAttachmentFile } from '../actions';
import { SearchResultStates } from '../constants';
import { connect } from 'react-redux';
import { digitalProductViewerTitleSelector } from '../selectors';
import { Titles,Strategic } from '../../../constants';

class PricingInformation extends React.Component {
    getColumns = () => {
        
        return [
            {
                name: this.props.intl.formatMessage({ id: 'digitalproductviewer.pricingdetail.basepricestartdate', defaultMessage: 'Base Price Start Date' }),
                fieldName: 'BasePriceStartDate',
                minWidth: 180,
                maxWidth: 180,
            },
            {
                name: this.props.intl.formatMessage({ id: 'digitalproductviewer.pricingdetail.basepriceenddate', defaultMessage: 'Base Price End Date' }),
                fieldName: 'BasePriceEndDate',
                minWidth: 150,
                maxWidth: 150
            },
            {
                name: this.props.intl.formatMessage({ id: 'digitalproductviewer.pricingdetail.baseprice', defaultMessage: 'Base Price' }),
                fieldName: 'BasePrice',
                minWidth: 90,
                maxWidth: 90
            },
            {
                name: this.props.intl.formatMessage({ id: 'digitalproductviewer.pricingdetail.netpricestartdate', defaultMessage: 'Net Price Start Date' }),
                fieldName: 'AdjustmentStartDate',
                minWidth: 150,
                maxWidth: 150
            },
            {
                name: this.props.intl.formatMessage({ id: 'digitalproductviewer.pricingdetail.netpriceenddate', defaultMessage: 'Net Price End Date' }),
                fieldName: 'AdjustmentEndDate',
                minWidth: 150,
                maxWidth: 150
            },
            {
                name: this.props.intl.formatMessage({ id: 'digitalproductviewer.pricingdetail.adjustment', defaultMessage: 'Pricing Adjustment' }),
                fieldName: 'PriceAdjustment',
                minWidth: 150,
                maxWidth: 150
            },
            {
                name: this.props.intl.formatMessage({ id: 'digitalproductviewer.pricingdetail.netprice', defaultMessage: 'Net Price' }),
                render: { type: 'link' },
                ariaLabel: this.props.intl.formatMessage({ id: 'digitalproductviewer.pricingdetail.netpricedetails', defaultMessage: 'click here to view the net price details' }),
                fieldName: 'NetPrice',
                minWidth: 90,
                maxWidth: 90
            }             
        ];
    }

    getStrategicColumns = () => {
        console.log(this.props);
        return [
            {
                name: this.props.intl.formatMessage({ id: 'digitalproductviewer.pricingdetail.basepricestartdate', defaultMessage: 'Base Price Start Date' }),
                fieldName: 'BasePriceStartDate',
                minWidth: 180,
                maxWidth: 180,
            },
            {
                name: this.props.intl.formatMessage({ id: 'digitalproductviewer.pricingdetail.basepriceenddate', defaultMessage: 'Base Price End Date' }),
                fieldName: 'BasePriceEndDate',
                minWidth: 150,
                maxWidth: 150
            },
            {
                name: this.props.intl.formatMessage({ id: 'digitalproductviewer.pricingdetail.baseprice', defaultMessage: 'Base Price' }),
                fieldName: 'BasePrice',
                minWidth: 90,
                maxWidth: 90
            },
            {
                name: this.props.intl.formatMessage({ id: 'digitalproductviewer.pricingdetail.netpricestartdate', defaultMessage: 'Net Price Start Date' }),
                fieldName: 'AdjustmentStartDate',
                minWidth: 150,
                maxWidth: 150
            },
            {
                name: this.props.intl.formatMessage({ id: 'digitalproductviewer.pricingdetail.netpriceenddate', defaultMessage: 'Net Price End Date' }),
                fieldName: 'AdjustmentEndDate',
                minWidth: 150,
                maxWidth: 150
            },
            {
                name: this.props.intl.formatMessage({ id: 'digitalproductviewer.pricingdetail.strategiclink', defaultMessage: 'Strategic Link' }),
                render: { type: 'link' },
                ariaLabel: this.props.intl.formatMessage({ id: 'digitalproductviewer.pricingdetail.strategiclinkdetails', defaultMessage: 'click here to download the net price details' }),
                fieldName: 'StrategicLink',
                minWidth: 90,
                maxWidth: 90
            } 
        ];
    }

    onNetPriceClick = (itemField, item) => {
        this.props.changeSearchResultView(SearchResultStates.PRICING_ADJUSTMENTS, item);
    }

    downloadStrategicLink = (itemField, item) => {
        // let promotionId =[];
        // item && item.DiscountsApplied.map(discount=>{
        //     promotionId.push(discount.PartNumber)
        // })
        // this.props.downloadFile(promotionId);
        // console.log(this.props.)
        this.props.changeSearchResultView(SearchResultStates.STRATEGIC_LINK, item);
    }

    getPriceString = (amount) => { return parseFloat(amount) >= 0 ? `$${amount}` : `$(${Math.abs(parseFloat(amount))})` };

    render() {
        var isStrategic = false;
        let items = JSON.parse(JSON.stringify(this.props.slabs));
        items.map((slab) => {
            slab.BasePriceStartDate = (new Date(slab.BasePriceStartDate)).toLocaleDateString();
            slab.BasePriceEndDate = (new Date(slab.BasePriceEndDate)).toLocaleDateString();
            slab.AdjustmentStartDate = (new Date(slab.AdjustmentStartDate)).toLocaleDateString();
            slab.AdjustmentEndDate = (new Date(slab.AdjustmentEndDate)).toLocaleDateString();
            slab.PriceAdjustment = this.getPriceString(slab.PriceAdjustment);
            slab.BasePrice = this.getPriceString(slab.BasePrice);
            slab.NetPrice = this.getPriceString(slab.NetPrice);
            slab.DiscountsApplied.map(discount => {  let value=""; 
            if(discount.StrategicLinksDocumentDTO && discount.StrategicLinksDocumentDTO.FileDTO && discount.StrategicLinksDocumentDTO.FileDTO.FileName) 
            {   isStrategic = true;
                return;
            }
            else if(discount.SubCategoryCode === Strategic.NON_HARDWARE || discount.SubCategoryCode === Strategic.HARDWARE 
                || discount.SubCategoryCode === Strategic.MANUAL || discount.SubCategoryCode === Strategic.NON_HARDWARE_UPLIFT
                || discount.SubCategoryCode === Strategic.HARDWARE_UPLIFT || discount.SubCategoryCode === Strategic.MANUAL_UPLIFT )
            {
                isStrategic = true;
            }
            if(isStrategic === true)
            {
                slab.NetPrice = "";
                slab.PriceAdjustment = "";
            }
            
        } );
             if(isStrategic === true)
              {
                  slab.StrategicLink = "StrategicLink";
               }
        });

        return (
            <CommonAccordion isOpen={false} headertext={this.props.intl.formatMessage({ id: 'digitalproductviewer.productdetail.pricinginformation', defaultMessage: "Pricing Information" })}>
                <DetailedListWrapper
                    onRowItemClick={isStrategic === true ? this.downloadStrategicLink : this.onNetPriceClick}
                    items={items}
                    columns={isStrategic === true ? this.getStrategicColumns() : this.getColumns()}
                    totalCount={this.props.slabs ? this.props.slabs.length : 0}
                />
            </CommonAccordion>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        title: digitalProductViewerTitleSelector(state)
    }
}

const mapDispatchToProps = (dispatch) => ({
    changeSearchResultView(state, value) {
        dispatch(changeSearchResultView(state, value));
    }
})


export default injectIntl(connect(null, mapDispatchToProps)(PricingInformation))