import React from 'react';
import { Label } from 'office-ui-fabric-react';
import { injectIntl } from 'react-intl';



const BigCatDetailsFields = (props) => {


    return (
        <React.Fragment>
              {props.heading && <p className='header-text'>{props.heading}</p>}
            <div className="ms-Grid">
                <div className="ms-Grid-row paddingatbottom">
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                        <Label name="ProductDescriptionLbl" id="ProductDescriptionLbl" className="labelHeader">{props.intl.formatMessage({ id: 'createStoreProduct.BigCatDetails.ProductDescription', defaultMessage: 'Product Description' })}</Label>
                        <Label name="ProductDescriptionVal" id="ProductDescriptionVal" title={props.ProductDescription} className="labelValue-long-text">{props.ProductDescription || '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                        <Label name="ProductDetailedDescriptionLbl" id="ProductDetailedDescriptionLbl" className="labelHeader">{props.intl.formatMessage({ id: 'createStoreProduct.BigCatDetails.ProductDetailDescription', defaultMessage: 'Product Detailed Description' })}</Label>
                        <Label name="ProductDetailedDescriptionVal" id="ProductDetailedDescriptionVal" title={props.ProductDetailedDescription} className="labelValue-long-text">{props.ProductDetailedDescription || '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                        <Label name="GeoAvailabilityLbl" className="labelHeader">{props.intl.formatMessage({ id: 'createStoreProduct.BigCatDetails.GeoAvailability', defaultMessage: 'Geo Availability' })}</Label>
                        <Label name="GeoAvailabilityVal" title={props.GeoAvailability} className="labelValue-long-text">{props.GeoAvailability || '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                        <Label name="LanguageLbl" className="labelHeader">{props.intl.formatMessage({ id: 'createStoreProduct.BigCatDetails.Language', defaultMessage: 'Language' })}</Label>
                        <Label name="LanguageVal" title={props.Language} className="labelValue-long-text">{(props.Language!=null || props.Language!=undefined)? props.Language : '-'}</Label>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg6">
                        <Label name="MinimumUserAgeLbl" id="MinimumUserAgeLbl" className="labelHeader">{props.intl.formatMessage({ id: 'createStoreProduct.BigCatDetails.MinUserAge', defaultMessage: 'Min User age' })}</Label>
                        <Label name="MinimumUserAgeVal" id="MinimumUserAgeVal" className="labelValue">{(props.MinimumUserAge!=null || props.MinimumUserAge!=undefined)?props.MinimumUserAge : '-'}</Label>
                    </div>
                </div>
            </div>
        </React.Fragment>);

}

export default injectIntl(BigCatDetailsFields);













