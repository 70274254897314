
import { fromJS } from "immutable";
import { createReducer } from "@ec-oem/ec.oem.oa3.ui.core/utility/createReducer";
import { SET_NAVIGATION_MENU, SET_DOC_LINK } from "../actions";

export const navigationMenu=createReducer(null,{
  [SET_NAVIGATION_MENU](state,{navigationMenu}){
    return fromJS(navigationMenu)
  } 
})

export const doclink = createReducer(null, {
	[SET_DOC_LINK](state, { doclink }) {
		return  doclink ? fromJS(doclink) : null;
	}
});