import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Accordion, AccordionHeader, AccordionBody, Iterator, ButtonGroupWrapper } from "@ec-oem/ec.oem.oa3.ui.core/components";
import './SubscriberResult.scss';
import { Label, DefaultButton } from 'office-ui-fabric-react';
import SubscriberCard from './SubscriberCard';
import {
    setAllSubscriber, updateSubscribers, setAddSubscriberList, setRemoveSubscriberList,discardSubscriberChanges
} from './ConfigureSubscriber.actions';
import { allSubscribersSelector, addSubscriberListSelector, removeSubscriberListSelector } from './ConfigureSubscriber.selectors';
import { bindActionCreators } from 'redux';
import ReactAI from 'react-appinsights';
import { connect } from 'react-redux';
import { PageTitles } from '../../../../constants';

class SubscriberResult extends Component {

    onDiscardClick = () => {
        this.props.actions.discardSubscriberChanges();
    }

    onSaveClick = () => {
        const { addSubscriberList, removeSubscriberList } = this.props;
        this.props.actions.updateSubscribers(addSubscriberList, removeSubscriberList);
    }


    render() {
        const { addSubscriberList, removeSubscriberList } = this.props;
        const disableDiscard = !(addSubscriberList.length > 0 || removeSubscriberList.length > 0);
        const disableSave = !(addSubscriberList.length > 0 || removeSubscriberList.length > 0);
        return (
            <div className="subscriber-result">
                <div className="ms-Grid-row subscriber-cards">
                    <Iterator Component={SubscriberCard} items={this.props.allSubscribers} {...this.props}></Iterator>
                </div>
                <div className="ms-Grid-row subscriber-actions">
                    <div className="ms-Grid-col ms-md12 ms-sm12">
                        <div className="float-right-content action-buttons">
                            <ButtonGroupWrapper>
                                <DefaultButton
                                    id="discardButton"
                                    className="iconRight full-width"
                                    primary={true}
                                    disabled={disableDiscard}
                                    text={this.props.intl.formatMessage({ id: 'app.Discard Changes', defaultMessage: "Discard Changes" })}
                                    iconProps={{ iconName: 'Cancel' }}
                                    onClick={() => this.onDiscardClick()} />
                                <DefaultButton
                                    id="saveButton"
                                    className="iconRight full-width"
                                    primary={true}
                                    disabled={disableSave}
                                    text={this.props.intl.formatMessage({ id: 'app.saveChanges', defaultMessage: "Save Changes" })}
                                    iconProps={{ iconName: 'Save' }}
                                    onClick={() => this.onSaveClick()} />
                            </ButtonGroupWrapper>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        allSubscribers: allSubscribersSelector(state),
        addSubscriberList: addSubscriberListSelector(state),
        removeSubscriberList: removeSubscriberListSelector(state),
        updateSubscribers:updateSubscribers(state)
    };
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        setAllSubscriber, updateSubscribers, setAddSubscriberList, setRemoveSubscriberList,discardSubscriberChanges
    }, dispatch)
})


export default ReactAI.withTracking(injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscriberResult)), PageTitles.CONFIGURE_SUBSCRIBER);