
export const FieldConstants = {
    ORDERABILITY_STARTDATE: "OrderabilityStartDate",
    ORDERABILITY_ENDDATE: "OrderabilityEndDate",
    SOLD_TO: "SoldTo",
    PRODUCT_INFO:"ProductInformation",
    IS_SOLD_TO_REQUIRED:"IsSoldToRequired"
};


export const Statuses = {
    SUCCESS:"SUCCESS",
    FAILURE:"FAILURE",
    ERROR:"ERROR"
}

export const TestProduct = "PTK";