import { call, put, all, apply, take, select } from 'redux-saga/effects';
import { ApplicationConfig, InvokeUrl } from "@ec-oem/ec.oem.oa3.ui.core/utility";
import { throwException } from '@ec-oem/ec.oem.oa3.ui.common/components/Logger';
import { showAlert } from "@ec-oem/ec.oem.oa3.ui.core/components/Alert";
import { AlertsTypes, AlertsFeature } from "@ec-oem/ec.oem.oa3.ui.core/constants";
import {
    DESTROY_CONFIGURE_SUBSCRIBER, GET_ALL_SUBSCRIBERS, setAllSubscriber
    , UPDATE_SUBSCRIBERS,
    getAllSubscriber,
    setAddSubscriberList,
    setRemoveSubscriberList,
    DISCARD_SUBSCRIBER_CHANGES,
    setMasterSubscriberList
} from './ConfigureSubscriber.actions';
import { allSubscribersSelector,masterSubscriberSelector } from './ConfigureSubscriber.selectors';


export function* getAllSubscribersSaga(input) {
    while (true) {
        try {
            yield take(GET_ALL_SUBSCRIBERS);
            const config = yield ApplicationConfig.config;
            const responseC = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/v1/da/subscriber/allsubscribers`, method: 'GET' } });
            if (responseC.status === 200) {
                const data = yield apply(responseC, responseC.json);
                yield put(setAllSubscriber(data));
                yield put(setMasterSubscriberList(data));
            }
        } catch (error) {
            yield put(throwException("getAllSubscribersSaga Saga error:" + error));
        }
    }
}

const prepareUpdateSubscribersObj = (addSubscribers, removeSubscribers) => {
    let updateSubscriberList = [];
    addSubscribers && addSubscribers.length > 0 && addSubscribers.map(( addSubscriber) => updateSubscriberList.push({ SubscriberAction: "Add", ...addSubscriber }));
    removeSubscribers && removeSubscribers.length > 0  && removeSubscribers.map(( removeSubscriber) => updateSubscriberList.push({ SubscriberAction: "Remove", ...removeSubscriber }));
    return updateSubscriberList;
}

export function* updateSubscribersSaga() {
    while (true) {
        try {
            const { addSubscriberList, removeSubscriberList } = yield take(UPDATE_SUBSCRIBERS);
            const config = yield ApplicationConfig.config;

            let updateSubscriberList = prepareUpdateSubscribersObj(addSubscriberList, removeSubscriberList);
            updateSubscriberList = JSON.stringify(updateSubscriberList);

            const response = yield call(InvokeUrl, { Config: { url: `${config.web_api_path}/api/v1/da/subscriber/updateSubscriberList`, method: 'PUT', data: updateSubscriberList } });
            if (response.status === 200) {
                yield put(getAllSubscriber());
                yield put(setAddSubscriberList([]));
                yield put(setRemoveSubscriberList([]));
                yield put(showAlert({ type: AlertsTypes.SUCCESS, feature: AlertsFeature.STORE_PRODUCT_ALERTS, message: ["Successfully Updated Subscriber(List)s."] }));
            }
        } catch (error) {
            yield put(throwException("updateSubscribersSaga error: " + error));
        }
    }
}




export function* discardSubscriberChangesSaga() {
    while (true) {
        try {
            yield take(DISCARD_SUBSCRIBER_CHANGES);
            const subscribers = yield select(masterSubscriberSelector);
            yield put(setAllSubscriber(subscribers));
            yield put(setAddSubscriberList([]));
            yield put(setRemoveSubscriberList([]));

        } catch (error) {
            yield put(throwException("discardSubscriberChangesSaga error: " + error));
        }
    }
}

export function* destroyConfigureSubscriberSaga() {
    while (true) {
        try {
            yield take(DESTROY_CONFIGURE_SUBSCRIBER);
            yield put(setAllSubscriber(null));
            yield put(setAddSubscriberList(null));
            yield put(setRemoveSubscriberList(null));

        } catch (error) {
            yield put(throwException("destroyConfigureSubscriberSaga error: " + error));
        }
    }
}




