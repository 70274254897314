import { makeActionCreator } from "@ec-oem/ec.oem.oa3.ui.core/utility/makeActionCreator";

export const SET_EDIT_PRODUCT_DETAILS = "SPM/ViewSP/SET_EDIT_PRODUCT_DETAILS";
export const setEditProductDetails = makeActionCreator(SET_EDIT_PRODUCT_DETAILS, false, "productId");

export const GET_VIEW_PRODUCT_DETAILS = "SPM/ViewSP/GET_VIEW_PRODUCT_DETAILS";
export const getViewProductDetails = makeActionCreator(GET_VIEW_PRODUCT_DETAILS, false, "productId");

export const SET_VIEW_PRODUCT_DETAILS = "SPM/ViewSP/SET_VIEW_PRODUCT_DETAILS";
export const setViewProductDetails = makeActionCreator(SET_VIEW_PRODUCT_DETAILS, false, "viewProductDetails");

export const SET_VIEW_BIG_CAT_PD = "SPM/ViewSP/SET_VIEW_BIG_CAT_PD";
export const setViewBigCatPD = makeActionCreator(SET_VIEW_BIG_CAT_PD, false, "viewBigCatPD");

export const SET_VIEW_BIG_CAT_CHILD_PD= "SPM/ViewSP/SET_VIEW_BIG_CAT_CHILD_PD";
export const setViewBigCatChildPD = makeActionCreator(SET_VIEW_BIG_CAT_CHILD_PD, false, "viewBigCatChildPD");

export const SET_VIEW_LAUNCH_READINESS_PD = "SPM/ViewSP/SET_VIEW_LAUNCH_READINESS_PD";
export const setViewLaunchReadinessPD = makeActionCreator(SET_VIEW_LAUNCH_READINESS_PD, false, "viewLaunchReadinessPD");

export const SET_STORE_PRODUCT_MODE = "SPM/CreateSP/SET_STORE_PRODUCT_MODE";
export const setStoreProductMode = makeActionCreator(SET_STORE_PRODUCT_MODE, false, "storeProductMode");

export const DESTROY_VIEW_STORE_PRODUCT = "SPM/CreateSP/DESTROY_VIEW_STORE_PRODUCT";
export const destroyViewStoreProduct = makeActionCreator(DESTROY_VIEW_STORE_PRODUCT, true);


export const INITIALIZE_DATA_FOR_VIEW = "SPM/CreateSP/INITIALIZE_DATA_FOR_VIEW";
export const initializeDataForView = makeActionCreator(INITIALIZE_DATA_FOR_VIEW, true,"productId");