import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { Formik, FormikProps, Form, Field, ErrorMessage } from "formik";
import { LaunchReadinessDetails } from "./";
import * as Yup from "yup";
import { setLaunchReadinessPD } from "../CreateStoreProduct.actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactAI from "react-appinsights";
import {
  launchReadinessPDSelector,
  launchReadinessMetaDataSelector,
  storeProductModeSelector,
} from "../CreateStoreProduct.selectors";
import { PageTitles } from "../../../../../constants";
import { Options, SPModes } from "../../../constants";
import { LAUNCH_READINESS_ALERTS } from "./CreateStoreProductConstants";
import {
  FeatureAlerts,
  showAlert,
  hideFeatureAlert,
} from "@ec-oem/ec.oem.oa3.ui.core/components";

const today = new Date(Date.now());
const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);

function getvalidationSchema(isActive) {
  const validationSchema = Yup.object({
    ItemDescription: Yup.string("Enter a Item description")
      .trim()
      .required("Item description is required")
      .nullable(),
    ProgramDifferentiator: Yup.string("Select Test Store Product")
      .required("Test Store Product is required")
      .nullable(),
    MaxcimDescription: Yup.string("Enter a Maxcim Description")
      .trim()
      .required("Maxcim Description is required")
      .nullable(),
    ProductFamily: Yup.string("Enter a PFAM")
      .required("PFAM is required")
      .nullable(),
    // RedemptionExpiryDaysPostWindowsActivation: Yup.string()
    //   // .required("RedemptionExpiryDaysPostWindowsActivation is required")
    //   .test(
    //     "RedemptionExpiryDaysPostWindowsActivation Validation",
    //     "Enter only integer value",
    //     function (RedemptionExpiryDaysPostWindowsActivation) {
    //       const re = /^[0-9\b]+$/;

    //       // if (RedemptionExpiryDaysPostWindowsActivation) {
    //       //   if (!re.test(RedemptionExpiryDaysPostWindowsActivation)) {
    //       //     return false;
    //       //   }
    //       // } else {
    //       //   return true;
    //       // }
    //       if (
    //         !RedemptionExpiryDaysPostWindowsActivation ||
    //         RedemptionExpiryDaysPostWindowsActivation === " " ||
    //         RedemptionExpiryDaysPostWindowsActivation === null ||
    //         re.test(RedemptionExpiryDaysPostWindowsActivation)
    //       ) {
    //         return true;
    //       } else return false;
    //     }
    //   )
    //   .nullable(),
    ExpirationDaysPostPBR: Yup.string()
      .test(
        "ExpirationDaysPostPBR Validation",
        "Enter only integer value",
        function (ExpirationDaysPostPBR) {
          const re = /^[0-9\b]+$/;
          if (
            !ExpirationDaysPostPBR ||
            ExpirationDaysPostPBR === " " ||
            ExpirationDaysPostPBR === null ||
            re.test(ExpirationDaysPostPBR)
          ) {
            return true;
          } else return false;
        }
      )
      .nullable(),
    RedemptionAvailability: Yup.string("Select Redemption Availability Date")
      .required("RTM Date is required")
      .test(
        "RTM Date Validation",
        "Redemption Date must be future date",
        function (OEMOrderabilityStartDate) {
          if (isActive) return true;
          let d1 = new Date(today).valueOf();
          let d2 = new Date(today).valueOf();
          return true;
        }
      )
      .nullable(),
    OEMOrderabilityStartDate: Yup.string("Select RTM Date")
      .required("RTM Date is required")
      .test(
        "RTM Date Validation",
        "RTM Date must be between Redemption date and Discontinue date",
        function (OEMOrderabilityStartDate) {
          if (isActive) return true;
          let d1 = new Date(this.parent.RedemptionAvailability).valueOf();
          let d2 = new Date(OEMOrderabilityStartDate).valueOf();
          return d1 <= d2;
        }
      )
      .nullable(),
    OEMOrderabilityEndDate: Yup.string("Select Discontinue Date")
      .required("Discontinue Date is required")
      .test(
        "Discontinue Date Validation",
        "Discontinue Date must be between RTM Date and End of Life Date",
        function (OEMOrderabilityEndDate) {
          let d1 = new Date(this.parent.OEMOrderabilityStartDate).valueOf();
          let d2 = new Date(OEMOrderabilityEndDate).valueOf();
          let d3 = new Date(today.toDateString()).valueOf();
          return Math.max(d1, d3) <= d2;
        }
      )
      .nullable(),
    EndOfLife: Yup.string("Select End Of Life Date")
      .required("End Of Life Date is required")
      .test(
        "End of Life Date Validation",
        "Date must be greater than or equal to Discontinue Date",
        function (EndOfLife) {
          let d1 = new Date(this.parent.OEMOrderabilityEndDate).valueOf();
          let d2 = new Date(EndOfLife).valueOf();
          return d1 <= d2;
        }
      )
      .nullable(),
    ContentType: Yup.string("Select Content Type")
      .required("Content Type is required")
      .nullable(),
    ContentClassification: Yup.string("Select Content Classification")
      .required("Content Classification is required")
      .nullable(),
    OnlineContent: Yup.string("Enter Online Content")
      .required("Online Content is required")
      .nullable(),
  });
  return validationSchema;
}

class LaunchReadinessFormikForm extends Component {
  submitValues = (values, actions, props) => {
    let launchReadinessPD = {
      ItemDescription: values.ItemDescription,
      ProgramDifferentiator: values.ProgramDifferentiator,
      MaxcimDescription: values.MaxcimDescription,
      ProductFamily: values.ProductFamily,
      // RedemptionExpiryDaysPostWindowsActivation:
      //   values.RedemptionExpiryDaysPostWindowsActivation,
      ExpirationDaysPostPBR: values.ExpirationDaysPostPBR,
      RedemptionAvailability: values.RedemptionAvailability,
      OEMOrderabilityStartDate: values.OEMOrderabilityStartDate,
      OEMOrderabilityEndDate: values.OEMOrderabilityEndDate,
      EndOfLife: values.EndOfLife,
      ContentType: values.ContentType,
      ContentClassification: values.ContentClassification,
      OnlineContent: values.OnlineContent,
    };
    props.actions.setLaunchReadinessPD(launchReadinessPD);
    props.onSubmitClick();
  };

  getOptionData = (boolData) => {
    let [matchedObj] = Options.filter((item) => item.value === boolData);
    return matchedObj;
  };

  initialFormData() {
    let { launchReadinessPD, launchReadinessMetaData } = this.props;
    var {
      ContentTypes,
      ContentClassifications,
      OnlineContents,
    } = launchReadinessMetaData;
    let values = {};
    const today = new Date(Date.now());
    const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    if (launchReadinessPD) {
      values = {
        ItemDescription: launchReadinessPD.ItemDescription,
        ProgramDifferentiator: launchReadinessPD.ProgramDifferentiator,
        MaxcimDescription: launchReadinessPD.MaxcimDescription,
        ProductFamily: launchReadinessPD.ProductFamily,
        // RedemptionExpiryDaysPostWindowsActivation:
        //   launchReadinessPD.RedemptionExpiryDaysPostWindowsActivation,
        ExpirationDaysPostPBR: launchReadinessPD.ExpirationDaysPostPBR,
        RedemptionAvailability: launchReadinessPD.RedemptionAvailability,
        OEMOrderabilityStartDate: launchReadinessPD.OEMOrderabilityStartDate
          ? launchReadinessPD.OEMOrderabilityStartDate
          : tomorrow,
        OEMOrderabilityEndDate: launchReadinessPD.OEMOrderabilityEndDate,
        EndOfLife: launchReadinessPD.EndOfLife,
        ContentType: launchReadinessPD.ContentType,
        ContentClassification: launchReadinessPD.ContentClassification,
        OnlineContent: launchReadinessPD.OnlineContent,
      };
    } else {
      values = {
        ItemDescription: "",
        ProgramDifferentiator: false,
        MaxcimDescription: "",
        ProductFamily: "",
        //RedemptionExpiryDaysPostWindowsActivation: " ",
        ExpirationDaysPostPBR: " ",
        RedemptionAvailability: "",
        OEMOrderabilityStartDate: tomorrow,
        OEMOrderabilityEndDate: "",
        EndOfLife: "",
        ContentType: {},
        ContentClassification: {},
        OnlineContent: {},
      };
    }
    return values;
  }

  render() {
    let values = this.initialFormData();
    return (
      <React.Fragment>
        <FeatureAlerts timeOut={5000} feature={LAUNCH_READINESS_ALERTS} />
        <Formik
          initialValues={values}
          validationSchema={getvalidationSchema(this.props.isActive)}
          render={(props) => (
            <LaunchReadinessDetails {...props} {...this.props} />
          )}
          onSubmit={(values, actions) =>
            this.submitValues(values, actions, this.props)
          }
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  let mode = storeProductModeSelector(state);
  return {
    launchReadinessPD: launchReadinessPDSelector(state),
    launchReadinessMetaData: launchReadinessMetaDataSelector(state),
    isActive: mode === SPModes.EDIT_ACTIVE,
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { setLaunchReadinessPD, showAlert, hideFeatureAlert },
    dispatch
  ),
});

export default ReactAI.withTracking(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(LaunchReadinessFormikForm)
  ),
  PageTitles.CREATE_STORE_PRODUCT
);
