import { createSelector } from 'reselect';


export const wizardDataSelector = createSelector(
    state => state.app.get("wizardData"),
    wizardData => wizardData ? wizardData.toJS() : null
);

export const partNumberSelector = createSelector(
    state => state.app.get("partNumber"),
    partNumber => partNumber ? partNumber : null
);

export const currentWizardStepSelector = createSelector(
    state => state.app.getIn(["wizardData", "currentStep"]),
    currentStep => currentStep ? currentStep.toJS() : null
);

export const stepsWizardStepSelector = createSelector(
    state => state.app.getIn(["wizardData", "steps"]),
    steps => steps ? steps.toJS() : null
);

export const productDetailsSelector = createSelector(
    state => state.app.get("productDetails"),
    productDetails => productDetails ? productDetails.toJS() : null
);

export const bigCatPDSelector = createSelector(
    state => state.app.get("bigCatPD"),
    bigCatPD => bigCatPD ? bigCatPD.toJS() : null
);

export const bigCatChildPDSelector = createSelector(
    state => state.app.get("bigCatChildPD"),
    bigCatChildPD => bigCatChildPD ? bigCatChildPD.toJS() : null
);

export const launchReadinessPDSelector = createSelector(
    state => state.app.get("launchReadinessPD"),
    launchReadinessPD => launchReadinessPD ? launchReadinessPD.toJS() : null
);

export const launchReadinessMetaDataSelector = createSelector(
    state => state.app.get("launchReadinessMetaData"),
    launchReadinessMetaData => launchReadinessMetaData ? launchReadinessMetaData.toJS() : null
);

export const storeProductModeSelector = createSelector(
    state => state.app.get("storeProductMode"),
    StoreProductMode => StoreProductMode ? StoreProductMode : null
);

export const spWizardCompletionSelector = createSelector(
    state => state.app.get("spWizardCompletion"),
    spWizardCompletion => spWizardCompletion
)

export const PFAMValiditySelector =(state,pfam) =>  createSelector(
    state => state.app.getIn(["PFAMValidations",pfam]),
    validity => validity
)(state);

export const ignorePFAMSelector =(state,pfam) =>  createSelector(
    state => state.app.getIn(["ignorePFAM",pfam]),
    ignore => ignore
)(state);
