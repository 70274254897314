import * as CommonSagas from '../sagas';
import * as StoreProductSagas from '../components/StoreProductManagement/sagas';
import * as DigitalProductViewerSagas from '../components/DigitalProductViewer/sagas';
import * as UICommonSagas from '@ec-oem/ec.oem.oa3.ui.common/sagas';
import * as CoreSagas from '@ec-oem/ec.oem.oa3.ui.core/sagas';



const sagas = { ...CoreSagas,...UICommonSagas,...CommonSagas, ...StoreProductSagas,...DigitalProductViewerSagas };

export const initSagas = (sagaMiddleware) => {
    Object.values(sagas).forEach(sagaMiddleware.run.bind(sagaMiddleware));
}